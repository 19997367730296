import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    Outlet,
    Navigate,
} from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { MainDashboard } from "./pages/Private/MainDashboard/MainDashboard";
import { WithoutRegistration } from "./pages/WithoutRegistration/WithoutRegistration";
import { WithRegistration } from "./pages/WithRegistration/WithRegistration";
import { DirectorDashboard } from "./pages/Private/DirectorDashboard/DirectorDashboard";
import { RegistrationDashboard } from "./pages/Private/RegistrationDashboard/RegistrationDashboard";
import { GrievanceStatus } from "./pages/Private/RegistrationDashboard/GrievanceStatus/GrievanceStatus";
import { DesignationMaster } from "./pages/Private/MasterScreen/DesignationMaster/DesignationMaster";
import { EmployeeMaster } from "./pages/Private/MasterScreen/EmployeeMaster/EmployeeMaster";
import { UserCreationMaster } from "./pages/Private/MasterScreen/UserCreation/UserCreationMaster";
import { useEffect, useState } from "react";
import { NotFound } from "./pages/NotFound/NotFound";
import { ErrorBoundary } from "react-error-boundary";
import { TotalReceivedGrienavces } from "./TotalReceivedGrievance/TotalReceivedGrienavces";
import { ActionReportUpload } from "./pages/Private/MainDashboard/ActionReportUpload/ActionReportUpload";
import { EscalatedGrievances } from "./pages/Private/MainDashboard/EscalatedGrievances/EscalatedGrievances";
import { FeedbackGivenbyCitizen } from "./pages/Private/MainDashboard/FeedbackGivenbyCitizen/FeedbackGivenbyCitizen";
import { SiteVerification } from "./pages/Private/MainDashboard/SiteVerification/SiteVerification";
import { ReceivedGrievance } from "./pages/Private/DirectorDashboard/DirectorDashboardContent/ReceivedGrievance/ReceivedGrievance";
import { EscalatedGrievanceActionReportUpload } from "./pages/Private/MainDashboard/EscalatedGrievanceActionReportUpload/EscalatedGrievanceActionReportUploadPage";
import { DirectorResolvedGrievanceGrid } from "./pages/Private/DirectorDashboard/DirectorResolvedGrievanceGrid/DirectorResolvedGrievanceGrid";
import { DirectorRuralAreaGrid } from "./pages/Private/DirectorDashboard/DirectorResolvedGrievanceGrid/DirectorRuralAreaGrid";
import { DirectorEscalatedMunicipalCorporationgrid } from "./pages/Private/DirectorDashboard/DirectorEscalatedGrievanceGrid/DirectorEscalatedMunicipalCorporationgrid";
import { DirectorEscalatedRuralAreagrid } from "./pages/Private/DirectorDashboard/DirectorEscalatedGrievanceGrid/DirectorEscalatedRuralAreagrid";
import { DirectorActionMunicipalCorporationGrid } from "./pages/Private/DirectorDashboard/DirectorDashboardContent/DirectorActionReportGrid/DirectorActionMunicipalCorporationGrid";
import { DirectorActionRuralAreaGrid } from "./pages/Private/DirectorDashboard/DirectorDashboardContent/DirectorActionReportGrid/DirectorActionRuralAreaGrid";
import { DirectorCitizenFeedbackGrid } from "./pages/Private/DirectorDashboard/DirectorDashboardContent/DirectorCitizenFeedbackGrid/DirectorCitizenFeedbackGrid";
import { DirectorReceivedGrievanceGrid } from "./pages/Private/DirectorDashboard/DirectorDashboardContent/DirectorReceivedGrievance/DirectorReceivedGrievanceGrid";
import { Otp } from "./pages/ForgotPassword/Otp";
import { VerifyOtp } from "./pages/ForgotPassword/VerifyOtp";
import { CreatePassword } from "./pages/ForgotPassword/CreatePassword";
import LaunchingVideo from "./pages/LaunchingVideo/LaunchingVideo";


export const AllRoutes = () => {

    const [RoleID, setRoleID] = useState(sessionStorage.getItem("A454A"))

    const RequiredAuth = () => {
        let Auth = sessionStorage.getItem("A454A")
        setRoleID(sessionStorage.getItem("A454A"))
        const location = useLocation()

        if (!Auth) {
            return <Navigate to="/" state={{ from: location }} />
        }
        return <Outlet />

    }

    useEffect(() => {
        setRoleID(sessionStorage.getItem("A454A"))
    }, [RoleID])

    return (
        // <ErrorBoundary FallbackComponent={NotFound}>
        <Router>
            <Routes>
                {/* <Route exact path="/" element={<LaunchingVideo />} /> */}
                <Route exact path="/" element={<Login />} />

                <Route exact path="/Otp" element={<Otp />} />
                <Route exact path="/VerifyOtp" element={<VerifyOtp />} />
                <Route exact path="/CreatePassword" element={<CreatePassword />} />
                <Route exact path="/WithoutRegistration" element={<WithoutRegistration />} />
                <Route exact path="/WithRegistration" element={<WithRegistration />} />

                <Route exact element={<RequiredAuth />}>

                    <Route exact path="/MainDashboard" element={<MainDashboard />} />

                    <Route exact path="/DirectorDashboard" element={<DirectorDashboard />} />
                    <Route exact path="/DirectorReceivedGrievanceGrid" element={<DirectorReceivedGrievanceGrid />} />
                    <Route exact path="/DirectorResolvedGrievanceGrid" element={<DirectorResolvedGrievanceGrid />} />
                    <Route exact path="/DirectorRuralAreaGrid" element={<DirectorRuralAreaGrid />} />
                    <Route exact path="/DirectorEscalatedMunicipalCorporationgrid" element={<DirectorEscalatedMunicipalCorporationgrid />} />
                    <Route exact path="/DirectorEscalatedRuralAreagrid" element={<DirectorEscalatedRuralAreagrid />} />
                    <Route exact path="/DirectorActionMunicipalCorporationGrid" element={<DirectorActionMunicipalCorporationGrid />} />
                    <Route exact path="/DirectorActionRuralAreaGrid" element={<DirectorActionRuralAreaGrid />} />
                    <Route exact path="/DirectorCitizenFeedbackGrid" element={<DirectorCitizenFeedbackGrid />} />

                    <Route exact path="/RegistrationDashboard" element={<RegistrationDashboard />} />
                    <Route exact path="/GrievanceStatus" element={<GrievanceStatus />} />
                    {/* Master Screen */}
                    <Route exact path="/DesignationMaster" element={<DesignationMaster />} />
                    <Route exact path="/EmployeeMaster" element={<EmployeeMaster />} />
                    <Route exact path="/UserCreationMaster" element={<UserCreationMaster />} />
                    <Route exact path="/TotalReceivedGrienavces" element={<TotalReceivedGrienavces />} />
                    <Route exact path="/SiteVerification" element={<SiteVerification />} />
                    <Route exact path="/ActionReportUpload" element={<ActionReportUpload />} />
                    <Route exact path="/FeedbackGivenbyCitizen" element={<FeedbackGivenbyCitizen />} />
                    <Route exact path="/EscalatedGrievances" element={<EscalatedGrievances />} />
                    <Route exact path="/ReceivedGrievance" element={<ReceivedGrievance />} />
                    <Route exact path="/EscalatedGrievanceActionReportUpload" element={<EscalatedGrievanceActionReportUpload />} />
                </Route>

            </Routes>
        </Router>
        // </ErrorBoundary>
    )

}