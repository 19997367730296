import { useSearchParams } from "react-router-dom"
import { Header } from "../../Header/Header"
import { SideBar } from "../../SideBar/SideBar"
import { Footer } from "../../../Footer/Footer"
import React, { useState } from "react"
import TrackPopup from "./TrackPopup/TrackPopup"
import { t } from "i18next"
import { Resolved } from "./Resolved"
import { Pending } from "./Pending"

export const GrievanceStatus = () => {

    const [searchparams] = useSearchParams()
    const UserName = searchparams.get('UserName')
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    // const navigate = useNavigate();
    return (
        <>

            {/* <!-- Page Wrapper --> */}
            <Header
                Language={Language}
                setLanguage={setLanguage}
            />
            <div id="wrapper" className="d-flex justify-content-center">
                {/* <!-- Content Wrapper --> */}
                <SideBar active="GrievanceStatus" UserName={UserName} />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-3" id='content' >
                    {/* <!-- Main Content --> */}
                    {/* <!-- Begin Page Content --> */}
                    <div className="container-fluid px-lg-3 ">
                        <div className="row ">
                            <div className="col-12 col-lg-6 pt-4 px-3 px-lg-1">
                                <h5 className="text-black fw-bold px-lg-2">
                                    {t('GrievanceStatus.GrievanceStatus')}
                                </h5>
                            </div>
                        </div>
                        <div className="row py-2 my-2 bg-white rounded">

                            <Resolved
                                Language={Language}
                                setLanguage={setLanguage} />
                        </div>
                        {/* <div className="row py-2 my-2 bg-white rounded">
                            <div className="col-12">
                                <h6 className="fw-bold text-danger">
                                    Pending Grievance
                                </h6>
                            </div>

                            <Pending />
                        </div> */}


                        {/* <Footer /> */}
                    </div>
                    {/* <!-- End of Content Wrapper --> */}
                    <Footer />
                </div>
            </div>
        </>

    )
}