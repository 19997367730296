

import React, { useState, useEffect, useMemo } from "react";

import ReactPaginate from "react-paginate";
import './Pagination.css'

export const Pegination = (props) => {
    const { PerPageCount, TotalCount, setTo, setFrom, setCurrentPage, CurrentPage, IsClear } = props
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        let paglink = document.querySelectorAll('.page-item');
        paglink[1].firstChild.click();
    }, [IsClear])


    useMemo(() => {
        const endOffset = CurrentPage + PerPageCount;
        setPageCount(Math.ceil(TotalCount / PerPageCount));
        setTo(endOffset)
        setFrom(CurrentPage + 1)
    }, [CurrentPage, TotalCount]);

    const handlePageClick = (event) => {
        var newOffset = event.selected * PerPageCount % TotalCount;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setCurrentPage(newOffset)
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="mt-1 d-flex justify-content-end ">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={4}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active2"
                        renderOnZeroPageCount={null}
                        forcePage={itemOffset}
                    />
                </div>
            </div>
        </div>
    )
}