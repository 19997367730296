import moment from "moment"



export const Footer = () => {
    return (
        <>
            <div className='row'>

                <div class="col-12 pt-3">
                    <p class=" pt-2 footer_text">© {moment().format('YYYY')} Copyright CDAT Pune. All Rights Reserved </p>
                </div>

            </div>
        </>
    )
}