
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { DesignationDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const DesignationDDL = (props) => {
    const { DesignationDDLID, setDesignationDDLID, Flag, data, Language, M_IndicatorGrievanceAreaID, UserID } = props

    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            // Language: Language.Label,
            M_UserID: UserID ? UserID : '0',
        }
        dispatch(DesignationDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.DesignationDDL)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setDesignationDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.m_DesignationID : 0,
                Label: Flag == 'Update' ? data?.designationName : "Select...",
            })
        }
        else {
            setDesignationDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <Select
                id='DesignationID'
                isClearable={true}
                isSearchable
                maxMenuHeight={130}
                value={{ value: DesignationDDLID.ID, label: DesignationDDLID.Label }}
                onChange={(e) => {
                    e ?
                        setDesignationDDLID({ ...DesignationDDLID, ID: e.value, Label: e.label })
                        :
                        setDesignationDDLID({ ...DesignationDDLID, ID: 0, Label: "Select..." })

                }}
                options={DesignationDDLID.DDL}
            />
        </div>
    )
}