
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess } from "../../AlertMessage/AlertMessage";


// citizen Register
export const CitizenRegistrationPostAPI = createAsyncThunk("CitizenRegistrationPost", async ({ data }) => {
    const {
        CitizenField,
        M_IndicatorGenderID,
        M_UserID,
        handleIsPost,
    } = data


    const formdata = new FormData();
    formdata.append("CitizenName", CitizenField.CitizenName);
    formdata.append("DateOfBirth", CitizenField.DateOfBirth);
    formdata.append("EmailAddress", CitizenField.EmailAddress);
    formdata.append("MobileNo", CitizenField.MobileNo);
    formdata.append("M_IndicatorGenderID", M_IndicatorGenderID);
    formdata.append("Address", CitizenField.Address);
    formdata.append("UserName", CitizenField.UserName);
    formdata.append("Password", CitizenField.Password);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Post_M_CitizenRegistration_Insert`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if ((result.code >= 200 && result.code <= 300) && result.status) {
                toastSuccess(result.message)
                handleIsPost()
            } else {
                toastError(result.message)
            }
            return result
        })
})

const CitizenRegistrationPostSlice = createSlice({
    name: "CitizenRegistrationPost",
    initialState: {
        isPostLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CitizenRegistrationPostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(CitizenRegistrationPostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(CitizenRegistrationPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.CropNametableData = null;

        });
    },
})

export const CitizenRegistrationPostReducer = CitizenRegistrationPostSlice.reducer

// Grievience Register

export const GrievanceRegistrationPostAPI = createAsyncThunk("GrievanceRegistrationPost", async ({ data }) => {
    const {
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_TalukaID,
        M_VillageID,
        M_IndicatorGrievanceTypeID,
        M_UserID,
        GrievanceField,
        handleIsPost,
        handleClear
    } = data

    const formdata = new FormData();

    formdata.append("M_IndicatorGrievanceAreaID", M_IndicatorGrievanceAreaID);
    formdata.append("CenterName", GrievanceField.CenterName);
    formdata.append("M_DistrictID", M_DistrictID);
    formdata.append("M_TalukaID", M_TalukaID);
    formdata.append("M_VillageID", M_VillageID);
    formdata.append("AreaName", GrievanceField.AreaName);
    formdata.append("AreaAdress", GrievanceField.AreaAdress);
    formdata.append("HospitalName", GrievanceField.HospitalName);
    formdata.append("HospitalAddress", GrievanceField.HospitalAddress);
    formdata.append("DoctorName", GrievanceField.DoctorName);
    formdata.append("DoctorAddress", GrievanceField.DoctorAddress);
    formdata.append("ReferredDoctorName", GrievanceField.ReferredDoctorName);
    formdata.append("PatientName", GrievanceField.PatientName);
    formdata.append("PatientAddress", GrievanceField.PatientAddress);
    formdata.append("M_IndicatorGrievanceTypeID", M_IndicatorGrievanceTypeID);
    formdata.append("GrievanceDetails", GrievanceField.GrievanceDetails);
    formdata.append("VanNumber", GrievanceField.VanNumber);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Transaction/Post_T_GrievanceRegistration_Insert`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastError('401')
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if ((result.code >= 200 && result.code <= 300) && result.status) {
                toastSuccess(result.message)
                handleClear()
                // handleIsPost()
            } else {
                toastError(result.message)
            }
            return result
        })
})

const GrievanceRegistrationPostSlice = createSlice({
    name: "GrievanceRegistrationPost",
    initialState: {
        isPostLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GrievanceRegistrationPostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(GrievanceRegistrationPostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(GrievanceRegistrationPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.CropNametableData = null;

        });
    },
})

export const GrievanceRegistrationPostReducer = GrievanceRegistrationPostSlice.reducer