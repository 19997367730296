
import { useNavigate } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { DirectorDashboardContent } from "./DirectorDashboardContent/DirectorDashboardContent"
import { SideBar } from "../SideBar/SideBar"
import { Header } from "../Header/Header"
import { useEffect, useState } from "react"
import { t } from "i18next"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../Helper/Context/context"
import { DirectorDashboardMainCountApi } from "../../../Helper/Redux/DirectorDashboardSlice/DirectorDashboardMainCountSlice"
import { Loading } from "../../../Helper/Loader/LogoLoader"

export const DirectorDashboard = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const navigate = useNavigate();
    const [DDLFinantialYearID, setDDLFinantialYearID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Year'
    })
    const [DDLMonthID, setDDLMonthID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Month'
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })

    const [Countdata, setCountdata] = useState({})
    const [LableName, setLableName] = useState([])
    const [CheckCountData1, setCheckCountData1] = useState([])

    const handleGetCount = (data) => {
        let tempData = {}
        let lableData = []
        let CheckCountData = []
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
            lableData[item.paramName] = item.lableName
            CheckCountData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
        setLableName(lableData)
        setCheckCountData1(CheckCountData)
        // console.log("iji",CheckCountData)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID.ID,
            M_MonthID: DDLMonthID.ID,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            handleGetCount: handleGetCount
        }

        if (DDLFinantialYearID.ID != '0') {
            dispatch(DirectorDashboardMainCountApi({ data }))
        }
    }, [DDLFinantialYearID.ID, DDLMonthID.ID])


    const { Data, isLoading } = useSelector(state => state.DashMainCountGetData)


    return (
        <>
            {isLoading && <Loading />}
            <Header
                Language={Language}
                setLanguage={setLanguage}
                DDLFinantialYearID={DDLFinantialYearID}
                setDDLFinantialYearID={setDDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                setDDLMonthID={setDDLMonthID}
                name='Dashboard'
            />
            <div id="wrapper" className="d-flex justify-content-center">
                {/* <!-- Content Wrapper --> */}
                <SideBar active="DirectorDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-md-3" id='content' >
                    {/* <!-- Main Content --> */}
                    <div className="row">
                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="text-black fw-bold ps-2 pt-1">
                                {t('DirectorDashboard.Dashboard')}
                            </span>
                        </div>
                    </div>
                    <div className="container-fluid px-2 shadow" >
                        <DirectorDashboardContent
                            DDLFinantialYearID={DDLFinantialYearID}
                            DDLMonthID={DDLMonthID}
                            Countdata={Countdata}
                            LableName={LableName}
                            CheckCountData1={CheckCountData1}
                        />
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )
}