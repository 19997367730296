import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess, toastUnauthorized } from "../../AlertMessage/AlertMessage";

export const EmployeeMasterGetAPI = createAsyncThunk("EmployeeMasterGet", async ({ data }) => {
    const {
        M_DesignationID,
        EmployeeName,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Get_M_Employee_Select?M_DesignationID=${M_DesignationID}&EmployeeName=${EmployeeName}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeMasterGetSlice = createSlice({
    name: "EmployeeMasterGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeMasterGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeMasterGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(EmployeeMasterGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const EmployeeMasterGetReducer = EmployeeMasterGetSlice.reducer

//Insert API

export const EmployeeMasterInsertAPI = createAsyncThunk("EmployeeMasterInsert", async ({ data }) => {
    const {
        M_DesignationID,
        EmployeeName,
        M_UserID,
        token,
        handleShowModal,
        handleIsPost,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("EmployeeName", EmployeeName);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Post_M_Employee_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowModal()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeMasterInsertSlice = createSlice({
    name: "EmployeeMasterInsert",
    initialState: {
        isLoading: false,
        PostData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeMasterInsertAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeMasterInsertAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.PostData = action.payload;
        });
        builder.addCase(EmployeeMasterInsertAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.PostData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const EmployeeMasterInsertReducer = EmployeeMasterInsertSlice.reducer


//Update API

export const EmployeeMasterUpdateAPI = createAsyncThunk("EmployeeMasterUpdate", async ({ data }) => {
    const {
        M_DesignationID,
        M_EmployeeID,
        EmployeeName,
        M_UserID,
        token,
        handleShowModal,
        handleIsPost
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("EmployeeName", EmployeeName);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Patch_M_Employee_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowModal()
                handleIsPost()
                toastSuccess(result.message)
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeMasterUpdateSlice = createSlice({
    name: "EmployeeMasterUpdate",
    initialState: {
        isLoading: false,
        PostData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeMasterUpdateAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeMasterUpdateAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.PostData = action.payload;
        });
        builder.addCase(EmployeeMasterUpdateAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.PostData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const EmployeeMasterUpdateReducer = EmployeeMasterUpdateSlice.reducer



//Delete API

export const EmployeeMasterDeleteAPI = createAsyncThunk("EmployeeMasterDelete", async ({ data }) => {
    const {
        M_EmployeeID,
        M_UserID,
        token,
        handleShowDelete,
        handleIsPost
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/M_Employee_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowDelete()
                handleIsPost()
                toastSuccess(result.message)
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeMasterDeleteSlice = createSlice({
    name: "EmployeeMasterDelete",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeMasterDeleteAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeMasterDeleteAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(EmployeeMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const EmployeeMasterDeleteReducer = EmployeeMasterDeleteSlice.reducer