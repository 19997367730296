
import { Chart } from "react-google-charts";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../../../../../Helper/Context/context";
import { useState } from "react";
import ViewPopup from "../../../../CommonPopUp/ViewPopup";
import { CommonBarChart } from "./CommonBarChart/CommonBarChart";
// import { data, options } from "../../../../Helper/ChartDeta";

export const DirectorReceivedGrievancesPieChart = (props) => {
    const { DDLFinantialYearID, DDLMonthID, Countdata, LableName } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails


    const [CheckTrue, setCheckTrue] = useState(false)

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [ShowViewPopup, setShowViewPopup] = useState(false)
    const handleShowView = (option) => {

        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: option ? option : '',
            RowData: '',
        })
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleChartClick = (chartWrapper) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();

        if (selection && selection.length > 0) {
            const selectedItem = selection[0];
            if (selectedItem) {
                const rowIndex = selectedItem.row;
                const option = chartWrapper.getDataTable().getValue(rowIndex, 0); // Assuming the option is in the first column
                setSelectedOption(option);
                // setShowViewPopup(true)
                handleShowView(option)

                // Here you can open your popup or modal with the selected option
                // Example: set a state variable to control the visibility of the popup or modal
            }


        }

    };
    // console.log("setSelectedOption",selectedOption)

    const data = [
        ["Task", "Hours per Day"],
        // [LableName && LableName.TotalReceivedGrievanceTotalCount, Countdata && Countdata.TotalReceivedGrievanceTotalCount],
        [LableName && LableName.TotalReceived_MCTotalCount, Countdata && Countdata.TotalReceived_MCTotalCount],
        [LableName && LableName.TotalReceived_RuralTotalCount, Countdata && Countdata.TotalReceived_RuralTotalCount],

    ];

    const options = {
        // title: "My Daily Activities",
        is3D: true,
        colors: ['rgb(252, 135, 32)', 'rgb(80, 138, 255)',],
        legend: { position: 'bottom' },
        chartArea: {
            left: 0, // Customize the left margin
            top: 0, // Customize the top margin
            width: "90%", // Customize chart width
            height: "90%", // Customize chart height
        },
    };

    return (
        <>
            <div className="bg-white px-3 rounded">
                <div className="row py-2 my-2  rounded">
                    <div className="col-12">

                        <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"200px"}
                            chartEvents={[
                                {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => handleChartClick(chartWrapper)
                                }
                            ]}

                        />
                    </div>
                </div>
            </div>
            {
                ShowViewPopup &&
                <CommonBarChart
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}
                    DDLFinantialYearID={DDLFinantialYearID}
                    DDLMonthID={DDLMonthID}
                    selectedOption={selectedOption}
                    Flag={selectedOption == "Total Received Grievance" ? "TotalReceived" : selectedOption == "Municipal Corporation" ? "GrievanceType_MC" : "GrievanceType_Rural"}


                />
            }
        </>
    )
}