
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context/context'
import { MonthDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const MonthDDL = (props) => {
    const { DDLMonthID, setDDLMonthID, Flag, apiFlag, data, isClearable } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails


    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token
        }
        dispatch(MonthDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.MonthDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const today = new Date();
    let month = today.getMonth();

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            }))
            setDDLMonthID({
                DDL: list,
                ID: Flag == 'Edit' ? data.m_MonthID : ( sessionStorage.getItem('Month') ? sessionStorage.getItem('Month') :0),
                Label: Flag == 'Edit' ? data.monthName : ( sessionStorage.getItem('MonthLabel') ? sessionStorage.getItem('MonthLabel'):"Select..."),
            })
        }
        else {
            setDDLMonthID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    const handleChangeMonth=(e)=>{
        setDDLMonthID({ ...DDLMonthID, ID: e.value, Label: e.label })
        sessionStorage.setItem('Month', e.value)
        sessionStorage.setItem('MonthLabel', e.label)
    }

    const handleClearMonth=(e)=>{
        setDDLMonthID({ ...DDLMonthID, ID: 0, Label: "Select..." })
        sessionStorage.setItem('Month', '0')
        sessionStorage.setItem('MonthLabel', "Select...")
    }
    return (
        <div className="" style={{ width: '180px' }}>
            {/* <label className="d-block" htmlFor="NameofDepartment">Area</label>  */}
            <Select
                isClearable={true}
                // isRtl={isRtl}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'transparent' : 'transparent',
                        borderBottomColor: 'black',
                        boxShadow: 'none',
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    })
                }}
                components={{
                    IndicatorSeparator: () => null
                }}
                isSearchable
                value={{ value: DDLMonthID.ID, label: DDLMonthID.Label }}
                onChange={(e) => {
                    e ?
                       handleChangeMonth(e)
                        :
                        handleClearMonth(e)

                }}
                options={DDLMonthID.DDL}
            />
        </div>
    )
}