

// import { ThreeDots } from 'react-loader-spinner'
import { Loader } from 'react-overlay-loader';
import './LogoLoader.css'
import 'react-overlay-loader/styles.css';

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Loading = () => {
  return (
    <Loader fullPage loading />
  )
}

