import CryptoJS from "crypto-js";

if (sessionStorage.getItem("AmchiMulgi")) {
	const storedData = sessionStorage.getItem("AmchiMulgi");
	var bytes = CryptoJS.AES.decrypt(storedData, 'RangaFulan69');
	var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

let token = decryptedData ? decryptedData.access_token : '';
let UserID = decryptedData ? decryptedData.m_UserID : '';
let RoleID = decryptedData ? decryptedData.m_RoleID : '';
let IndicatorGrievanceAreaID = decryptedData ? decryptedData.m_IndicatorGrievanceAreaID : '';
let DistrictID = decryptedData ? decryptedData.m_DistrictID : '';
let DistrictName = decryptedData ? decryptedData.distrcitName : '';
let DesignationID = decryptedData ? decryptedData.m_DesignationID : "";
let EmployeeID = decryptedData ? decryptedData.m_EmployeeID : '';
let EmployeeName = decryptedData ? decryptedData.employeeName : '';

export const initialState = {
	token: '' || token,
	UserID: '' || UserID,
	RoleID: '' || RoleID,
	IndicatorGrievanceAreaID: '' || IndicatorGrievanceAreaID,
	DistrictID: '' || DistrictID,
	DesignationID: '' || DesignationID,
	DistrictName : '' || DistrictName,
	EmployeeID: '' || EmployeeID,
	EmployeeName: '' || EmployeeName,
	loading: false,
	errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				token: action.payload.access_token,
				UserID: action.payload.m_UserID,
				RoleID: action.payload.m_RoleID,
				IndicatorGrievanceAreaID: action.payload.m_IndicatorGrievanceAreaID,
				DistrictID: action.payload.m_DistrictID,
				DistrictName : action.payload.distrcitName,
				DesignationID: action.payload.m_DesignationID,
				EmployeeID: action.payload.m_EmployeeID,
				EmployeeName: action.payload.employeeName,
				loading: false,
			};
		case 'LOGOUT':
			return {
				...initialState,
				token: '',
				UserID: '',
				RoleID: '',
				IndicatorGrievanceAreaID: '',
				DistrictID: '',
				DistrictName :'',
				DesignationID: '',
				EmployeeID: '',
				EmployeeName: '',
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
