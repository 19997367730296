import { useNavigate } from "react-router-dom"



export const Otp = () => {

    const naviagte = useNavigate()
    return (
        <>
            <div className="container-fluid">

                <div className="row">
                    <div className='col-12 border mx-auto  col-lg-6 py-4 mt-5 rounded '>
                        <div className='col-11 col-lg-12 text-center  rounded py-2  px-lg-5'>
                            <img src="assets/img/Logo/nhm-logo.png" className="" style={{ width: '80px' }} alt="satymev jayate" />
                            <img src="assets/img/Logo/LoginLogo.png" className="" style={{ width: '50px' }} alt="satymev jayate" />
                            <img src="assets/img/Logo/nhm-logo-nation.png" className="" style={{ width: '80px' }} alt="satymev jayate" />

                            <h5 className=' my-2 pb-3 pt-2 text-center  fw-bold text-black'>Forgot Password</h5>
                            <div className='row'>
                                <div className='col-12 col-lg-9 mx-auto'>


                                    <form>
                                        <div className="input-group mb-4">
                                            <span className="input-group-text" id="addon-wrapping"><i class="fa-solid fa-mobile"></i></span>
                                            <input
                                                id='Username'
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Registered Mobile Number"
                                                aria-label="Username"
                                                aria-describedby="addon-wrapping"


                                            />
                                        </div>

                                        {/* <div className="input-group mb-2">
                                            <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-lock"></i></span>
                                            <input
                                                id='Password'
                                                type="text"
                                                className="form-control"
                                                placeholder="Password"
                                                aria-label="password"
                                                aria-describedby="addon-wrapping"


                                            />

                                          
                                        </div> */}

                                    </form>


                                    <button
                                        type="button"
                                        className="btn mb-4 col-12 logn_button text-white bg-submit"
                                        onClick={() => naviagte('/VerifyOtp')}
                                    >Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}