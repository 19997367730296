import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Header } from "../../Header/Header"
import { SideBar } from "../../SideBar/SideBar"
import { Footer } from "../../../Footer/Footer"
import { useAuthState } from "../../../../Helper/Context/context"
import ViewPopup from "../../CommonPopUp/ViewPopup"
import FeedbackPopup from "../../RegistrationDashboard/GrievanceStatus/FeedbackPopup/FeedbackPopup"
import { useDispatch, useSelector } from "react-redux"
import { ReceivedGrievanceGetAPI } from "../../../../Helper/Redux/DashboardSlice/ReceivedGrievanceSlice"
import { DistrictDDL } from "../../../../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../../../../Components/CommonDDL/GenderDDL"
import ViewURL from "../../../../Components/ViewURL/ViewURL"
import UploadReport from "../ActionReportUpload/UploadReport"
import { Pegination } from "../../../../Components/Pagination/Pagination"
import { t } from "i18next"

export const FeedbackGivenbyCitizen = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [ShowUploadFilePopup, setShowUploadFilePopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)
    const [FeedBackShowPopup, setFeedBackShowPopup] = React.useState(false)

    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    const DDLFinantialYearID = searchparams.get('DDLFinantialYearID')
    const DDLMonthID = searchparams.get('DDLMonthID')

    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ShowModal, setShowModal] = useState(false)
    const [ReceivedDate, setReceivedDate] = useState('')
    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    // Pagination
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);
    const [Feedback, setFeedback] = useState(Flag == 'ViewFeedBackPopUp' ? ModalData.RowData.feedback : '')

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleShowView = (rowData, t_GrievanceRegistrationID) => {
        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID
        })
    }

    const handleUploadFilePhoto = (rowData, t_GrievanceRegistrationID) => {
        setShowUploadFilePopup(!ShowUploadFilePopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID
        })
    }
    const handleFeedbackConfirmation = (rowData) => {
        setFeedBackShowPopup(!FeedBackShowPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            // heading: heading

        })
    }

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setIsClear(!IsClear)

        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: '',
            M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_DistrictID: 0,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedGrievanceGetAPI({ data })) }
    }, [Language.Label, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID])

    const { TableData, isLoading } = useSelector(state => state.ReceivedGrievanceGetData)

    return (
        <>
            <Header Language={Language}
                setLanguage={setLanguage} />
            {/* <SideBar active="MainDashboard" /> */}
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="MainDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >
                    <div className="row ">

                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="text-black fw-bold ps-2 pt-1">
                                {/* {t('DirectorDashboard.Dashboard')} */}
                            </span>

                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                        {
                                            screenname && screenname == "Resolved Grievance" ?
                                                t('DirectorDashboard.ResolvedGrievance') :
                                                screenname && screenname == "Feedback Given by Citizen" ?
                                                    t('DirectorDashboard.FeedbackGivenbyCitizen') :
                                                    t('DirectorDashboard.FeedbackPending')
                                        }
                                    </h6>
                                </div>
                            </div>

                            <div className="row py-3 py-lg-4">
                                {/* <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.DistrictName')}
                                    </span>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={IndicatorGrievanceAreaID}
                                        UserID={UserID}
                                    />
                                </div> */}

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.GrievanceType')}
                                    </span>
                                    <GenderDDL
                                        GenderDDLID={GrievanceTypeID}
                                        setGenderDDLID={setGrievanceTypeID}
                                        Language={Language}
                                        GroupID='3'
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-4 pt-2 mt-3  ">
                                    <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                        onClick={handleSearch}
                                    >
                                        Search</button>

                                    <button type="button" className="btn btn-danger border-2 button-size"
                                        onClick={handleClear}
                                    >Clear</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">{t('GridHeading.Sr_No')}</th>
                                                    <th className="">{t('GridHeading.ComplaintID')}</th>

                                                    {/* {
                                                        RoleID == 6 ? */}
                                                    <>
                                                        <th className=" ps-2">{t('DirectorDashboard.DistrictName')} / {t('DirectorDashboard.MunicipalCorporation')}</th>
                                                        <th className=" ps-2">{t('DirectorDashboard.TalukaName')}</th>
                                                        {/* <th className=" ps-2">Village Name</th> */}
                                                    </>
                                                    {/* :
                                                            <>
                                                                <th className=" ps-2">{t('DirectorDashboard.MunicipalCorporation')}</th>
                                                                <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                            </>
                                                    } */}
                                                    <th className=" ps-2">{t('DirectorDashboard.GrievanceType')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.GrievanceDetails')}</th>
                                                    <th className=" text-center ">{t('DirectorDashboard.Photo')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.Report')}</th>
                                                    {
                                                        screenname == 'Feedback Given by Citizen' &&
                                                        <th className=" ps-2">{t('GrievanceStatus.Feedback')} </th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length > 0 ?
                                                        TableData.map((i, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td className="w-7">{i?.rowNum}</td>
                                                                    <td className="w-7">{i.t_GrievanceRegistrationID ? i.t_GrievanceRegistrationID : '-'}</td>
                                                                    {/* {
                                                                        RoleID == 6 ? */}
                                                                    <>
                                                                        <td>{i.districtName ? i.districtName : '-'}</td>
                                                                        <td>{i.talukaName ? i.talukaName : '-'}</td>
                                                                        {/* <td>{i.villageName ? i.villageName : '-'}</td> */}

                                                                    </>
                                                                    {/* :
                                                                            <>
                                                                                <td>{i.districtName ? i.districtName : '-'}</td>
                                                                                <td>-</td>
                                                                            </>

                                                                    } */}
                                                                    <td>{i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</td>

                                                                    <td className="text-center">
                                                                        <span className="view_button"
                                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                            title="View Grievance Details"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}
                                                                        ><i class="fa-solid fa-eye"></i></span>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <span className="open_button"
                                                                            onClick={() => (
                                                                                handleShowImage(i.visitPhoto)
                                                                            )}
                                                                        >
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <span className="see_button"
                                                                            onClick={() => window.open(i.actionReport)}
                                                                        >
                                                                            <i class="fa-solid fa-file-pdf"></i>
                                                                        </span>
                                                                    </td>
                                                                    {
                                                                        screenname == 'Feedback Given by Citizen' &&
                                                                        <td className="text-center">
                                                                            <span className="feedback_button"
                                                                                onClick={() => handleFeedbackConfirmation(i)}
                                                                            >
                                                                                feedback
                                                                                {/* {t('GrievanceStatus.Feedback')} */}
                                                                            </span>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                        : <>Record Not Found</>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {TableData && TableData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TableData[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>

            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}
                />
            }
            {
                ShowUploadFilePopup &&
                <UploadReport
                    handleUploadFilePhoto={handleUploadFilePhoto}
                    Title='Upload Report'
                    ModalData={ModalData}
                    TableData={TableData}
                    handleIsPost={handleIsPost}
                />
            }
            {
                showImagePopup ?
                    <ViewURL
                        // Heading={ImageHeading}
                        setshowImagePopup={setshowImagePopup}
                        ImagePopUpData={ImagePopUpData}

                    />
                    : ''
            }

            {
                FeedBackShowPopup ?
                    <FeedbackPopup
                        handleFeedbackConfirmation={handleFeedbackConfirmation}
                        setFeedBackShowPopup={setFeedBackShowPopup}
                        // handleFeedback={handleFeedback}
                        Feedback={Feedback}
                        setFeedback={setFeedback}
                        Flag='ViewFeedBackPopUp'
                        ModalData={ModalData}
                    />
                    : ''
            }
            {/* <Footer /> */}
        </>
    )
}