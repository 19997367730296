
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../Footer/Footer';
import { useAuthDispatch, useAuthState } from '../../Helper/Context/context';
import { useState } from 'react';
import { loginUser } from '../../Helper/Context/action';
import moment from 'moment';
import ReactPlayer from 'react-player';


export const Login = () => {

    const navigate = useNavigate()

    const dispatch = useAuthDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    const [loading, setloading] = useState(false)
    const [Validationerror, setValidationerror] = useState(false)
    const [Loginmessage, setLoginmessage] = useState('')

    const handleSubmit = async (e) => {
        setLoginmessage('')
        e.preventDefault()
        if (userName !== '' && password !== '') {
            try {
                let response = await loginUser(dispatch, { userName, password, setloading, handleRollIdWiseDashboard, handleErrorIdWiseDashboard, setLoginmessage });
                if (!response) return;
                // navigate('/dashboard');

            } catch (error) {
                console.log(error);
            }
            setValidationerror(false)
        } else {
            setValidationerror(true)
            if (userName == '') {
                document.getElementById('Username').classList.add('validation-error')
            } else {
                document.getElementById('Username').classList.remove('validation-error')
            }

            if (password == '') {
                document.getElementById('Password').classList.add('validation-error')
            } else {
                document.getElementById('Password').classList.remove('validation-error')
            }
        }

    }
    const handleRollIdWiseDashboard = (data) => {
        // console.log(data.m_RoleID)
        if (data.m_RoleID == 2 || data.m_RoleID == 3 || data.m_RoleID == 4) {
            navigate(`/DirectorDashboard`)
        } else if (data.m_RoleID == 5 || data.m_RoleID == 6) {
            navigate(`/MainDashboard`)
        }
        else if (data.m_RoleID == 7) {
            navigate(`/RegistrationDashboard?UserName=NewUser`)
            // navigate(`/DirectorDashboard`)
        } else if (data.m_RoleID == 1) {
            navigate(`/DesignationMaster`)
        }
    }
    const handleErrorIdWiseDashboard = (data) => {
        setLoginmessage(data)
    }

    const showPass = () => {
        var x = document.getElementById("Password");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
            y.className = "fa fa-eye-slash";
        } else {
            x.type = "password";
            y.className = "fa fa-eye";
        }
    }

    const handleDownloadAPk = () => {
        window.open('https://files.catbox.moe/0imud3.apk')
    }
    return (
        <>
            {/* <div className=" poppins container_bg py-3 py-lg-0" style={{ height: "100vh" }}> */}

            {/* started mobile header */}

            {/* <div className='row pc_none'>
                    <div className='col-8 mx-auto text-center pb-1'>
                        <img src="assets/img/Logo/user_update-01.png" className="" style={{ width: '50px' }} alt="satymev jayate" />
                        <img src="assets/img/Logo/LoginLogo.png" className="bg-white px-1 mx-2 py-1 rounded shadow" style={{ width: '30px' }} alt="satymev jayate" />
                        <img src="assets/img/Logo/aamchi_mulgi_right_logo-01-01.png" className="" style={{ width: '50px' }} alt="satymev jayate" />

                    </div>

                    <div className='col-12 text-center mx-auto'>
                        <span className=''>
                            <h6 className='text-white heading_text_login'>Government of Maharashtra</h6>
                            <h6 className='text-white heading_text_login'>State Family Welfare Bureau</h6>
                        </span>
                    </div>


                </div> */}
            {/* end mobile header */}

            {/* <div className='row  heading_bg display text-center d-flex  justify-content-center align-item-center' style={{ height: "15vh" }}>

                    <div className='col-2 pt-4 mx-auto'>
                        <img src="assets/img/Logo/user_update-01.png" className="" style={{ width: '80px' }} alt="satymev jayate" />
                    </div>

                    <div className='col-8 pt-4 mx-auto d-flex justify-content-center align-item-center'>
                        <span className=' pt-2'>
                            <img src="assets/img/Logo/LoginLogo.png" className="bg-white px-1 py-1 rounded shadow" style={{ width: '40px' }} alt="satymev jayate" />
                        </span>

                        <span className='ps-3 pt-2'>
                            <h5 className='text-white heading_text_login'>Government of Maharashtra</h5>
                            <h5 className='text-white heading_text_login'>State Family Welfare Bureau</h5>
                        </span>
                    </div>

                    <div className='col-2 pt-4 mx-auto'>
                        <img src="assets/img/Logo/aamchi_mulgi_right_logo-01-01.png" className="" style={{ width: '80px' }} alt="satymev jayate" />

                    </div>
                </div> */}

            {/* <div className="row align " style={{ height: "85vh" }} >
                    <div className='col-10 py-1 px-3 main_content bg-white shadow border rounded'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 bg-Namepara pb-3 pb-lg-0'>
                                <div className='row col-12 col-lg-12 pt-3'>
                                
                                    <div className='col-12 text-center col-lg-12 pt-lg-3 mx-auto satyamev ps-4 ps-md-0'>
                                        <text className='slogan'>Public Grievance Software <span className='text-danger'>"AAMCHI MULGI"</span></text><br />
                                        <text className='slogan'>Pre-Conception and Pre Natal Diagnostic Techniques</text>
                                    </div>
                                </div>

                                <div className='col-12 col-lg-12  text-center pt-3 pt-lg-5'>
                                    <img src="assets/img/Logo/betiii png.png" className="img-fluid login_logo_size" alt="Girl in a jacket" />
                                </div>
                                <div className='col-12 col-lg-12  text-center pt-3 pt-lg-5 '>
                                    <Footer />
                                </div>
                            </div>

                            <div className='col-12 col-lg-6 py-4  rounded text-center align_form px-0 px-lg-2'>
                                <div className='col-11 col-lg-12  rounded py-2  px-lg-5'>
                                    <h3 className=' my-2 pb-3 pt-2 text-center login_text '>LOGIN</h3>
                                    <div className='row'>
                                        <div className='col-12 col-lg-9 mx-auto'>


                                            <form>
                                                <div className="input-group mb-4">
                                                    <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-user"></i></span>
                                                    <input
                                                        id='Username'
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Username"
                                                        aria-label="Username"
                                                        aria-describedby="addon-wrapping"
                                                        value={userName}
                                                        onChange={(e) => setuserName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-lock"></i></span>
                                                    <input
                                                        id='Password'
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Password"
                                                        aria-label="password"
                                                        aria-describedby="addon-wrapping"
                                                        value={password}
                                                        onChange={(e) => setpassword(e.target.value)}
                                                    />
                                                    <span
                                                        className="input-group-password cursor-pointer"
                                                        id="addon-wrapping"
                                                        onClick={showPass}
                                                    >
                                                        <i id='eye' className="fa-solid fa-eye"></i>
                                                    </span>
                                                </div>

                                            </form>
                                            <span className="forgot_link mb-3 mt-3" 
                                            onClick={() => navigate('/Otp')}
                                            >Forgot Password ? </span>

                                            <button
                                                type="button"
                                                className="btn mb-4 col-12 logn_button text-white bg-submit"
                                                // disabled={loading}
                                                onClick={handleSubmit}
                                            >Login
                                            </button>
                                        </div>
                                    </div>



                                    <div className="fs-7 ">
                                        <a className="text-danger text-center fs-6"> {Loginmessage}</a>
                                    </div>

                                    <div className='row pt-3 '>
                                        <div className='col-12 col-lg-12'>
                                            <button type="button "
                                                className="btn mb-2  border-0 logn_button reg_link"
                                                onClick={() => navigate('/WithRegistration')}
                                            > <span className='px-2 px-md-0'>Citizen</span> Registration
                                            </button>
                                        </div>

                                        <div className='col-12 col-lg-12'>

                                            <button type="button "
                                                className="btn  border-0 logn_button reg_link"
                                                onClick={() => navigate('/WithoutRegistration')}
                                            >Grievance Submission</button>
                                            <br />
                                            <span className='note_text'>(Without Citizen Registration)</span>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
            {/* </div> */}

            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <div className="row">
                            <div className="col-12">
                                <div className="header">
                                    <div className="loginLogo">
                                        <img src="assets/img/Login/nhm-logo.png" alt="IMG" />
                                    </div>
                                    <div className="loginmiddal text-black fw-bold ">
                                        {/* Public Grievance Application */}
                                        PUBLIC GRIEVANCE APPLICATION
                                        <span className='fw-bold' style={{ color: "rgb(4 124 46)" }}>आमची मुलगी - सार्वजनिक आरोग्य विभाग महाराष्ट्र शासन</span>
                                        <span className='fw-bold text-black text-primary-color' style={{ fontSize: "18px" }}>राज्य कुटुंब कल्याण कार्यालय</span>
                                    </div>
                                    <div className="rightLogo">
                                        <img src="assets/img/Login/nhm-logo-nation.png" alt="IMG" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login-hero">
                            <picture>
                                {/* <source media="(min-width:600px)" srcset="assets/img/Login/hero-banner-1x.jpg" /> */}
                                {/* <source media="(min-width:990px)" srcset="assets/img/Login/hero-banner.jpg" /> */}
                                {/* <img src="assets/img/Login/hero-banner-0x.jpg" alt="Nursing Home Registration" style={{ width: 'auto' }} /> */}
                                <img src="assets/img/Login/with_mulgi.jpg" alt="IMG" />

                            </picture>
                        </div>
                        <div className="login-body">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="login-box">
                                        <div className="content">

                                            <div className='col-12 text-center col-lg-12 pt-lg-3 mx-auto satyamev ps-4 ps-md-0'>
                                                <text className=' fw-bold' style={{ color: 'rgb(20 62 134)' }}>Pre-Conception and Pre Natal Diagnostic Techniques
                                                    <br />(PCPNDT)</text>
                                            </div>
                                        </div>

                                        <div className='col-12 col-lg-12  text-center pt-3 pt-lg-4'>
                                            <img src="assets/img/Logo/betiii png.png" className="img-fluid login_logo_size" alt="Girl in a jacket" />

                                            <p className='pt-5 mb-2 mt-1 fw-bold text-black'>तक्रार नोंदणी टोल फ्री क्रमांक </p>
                                            <p className='mb-1'> <a className=' fw-bold text-danger tel_fontsize' href="tel:18002334475"> <i class="fa-solid fa-phone px-2"></i> 18002334475</a> </p>
                                            <p className=''>  <a className='fw-bold text-danger tel_fontsize' href="tel:104"> <i class="fa-solid fa-phone px-2"></i> 104</a></p>

                                            {/* <p class=" pt-1 fw-700" style={{ color: 'rgb(20 62 134)' }}>© {moment().format('YYYY')} Copyright. All Rights Reserved </p> */}
                                            <div className="text-center" >
                                                <label className="cursor-pointer fw-bold text-black" onClick={() => handleDownloadAPk()}>
                                                    <i className="fa-solid fa-mobile px-2" style={{ fontSize: "20px", textDecoration: 'underline',color: "#173fae" }} ></i>
                                                    <span style={{color: "#173fae"}}>Download Android App</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="login-box">
                                        {/* <div className='row'>
                                            <div className='col-12'>
                                                <span className='text-danger fw-bold'>वेबसाईट -</span> <span className='text-primary fw-bold reg_link cursor-pointer' onClick={() => window.open('http://amchimulgimaha.in')}>http://amchimulgimaha.in</span>
                                            </div>
                                        </div> */}
                                        <div className='row'>
                                            <h3 className='text-center login_text fw-bold'>LOGIN</h3>
                                            <div className='col-12 col-lg-12 mx-auto'>
                                                <form>
                                                    <div className="input-group mb-4">
                                                        <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-user"></i></span>
                                                        <input
                                                            id='Username'
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=" वापरकर्ता नाव"
                                                            aria-label="Username"
                                                            aria-describedby="addon-wrapping"
                                                            value={userName}
                                                            onChange={(e) => setuserName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="input-group mb-2">
                                                        <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-lock"></i></span>
                                                        <input
                                                            id='Password'
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="पासवर्ड"
                                                            aria-label="password"
                                                            aria-describedby="addon-wrapping"
                                                            value={password}
                                                            onChange={(e) => setpassword(e.target.value)}
                                                        />
                                                        <span
                                                            className="input-group-password cursor-pointer"
                                                            id="addon-wrapping"
                                                            onClick={showPass}
                                                        >
                                                            <i id='eye' className="fa-solid fa-eye"></i>
                                                        </span>
                                                    </div>

                                                </form>
                                                {/* <span className="forgot_link mb-3 mt-3"
                                                    onClick={() => navigate('/Otp')}
                                                >Forgot Password ? </span> */}

                                                <button
                                                    type="button"
                                                    className="btn col-12 mt-3 logn_button fw-bold text-white bg-submit"
                                                    // disabled={loading}
                                                    onClick={handleSubmit}
                                                >Login
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row d-flex justify-content-center'>
                                            <div className='col-12 text-center'>
                                                <span className='fw-bold ' style={{ color: "#173fae" }}>
                                                    {/* Grievance Submission */}
                                                    तक्रार नोंदणी
                                                </span>
                                            </div>

                                            <div className='col-12 col-lg-5 text-center'>

                                                <button type="button "
                                                    className="btn mb-2 border-0  reg_link bg_btns_login"
                                                    onClick={() => navigate('/WithRegistration')}
                                                >
                                                    {/* With Citizen Registration */}
                                                    नागरिक नोंदणीसह

                                                    {/* <br className='d-none d-lg-block' />  */}

                                                </button>
                                            </div>

                                            <div className='col-12 col-lg-5 text-center '>

                                                <button type="button "
                                                    className="btn mb-2  border-0  reg_link bg_btns_login"
                                                    onClick={() => navigate('/WithoutRegistration')}
                                                >
                                                    {/* Without Citizen Registration */}
                                                    नागरिक नोंदणीशिवाय
                                                </button>
                                            </div>
                                            <div className='col-12 text-center'>
                                                <span className='fw-bold cursor-pointer' onClick={() => window.open('/assets/docs/Usermanual Grievance Submit.pdf')} style={{ color: "#173fae" }}>
                                                    {/* User Mannual */}
                                                    उपयोगकर्ता पुस्तिका
                                                </span>
                                            </div>
                                        </div>

                                        {/* <div className='row'>
                                            <div className='col-12'>
                                                <span className=' fw-bold text-black  '><i class="fa-solid fa-phone px-2"></i> Customer care  </span>
                                                <a className=' fw-bold text-danger  text-center  ' href="tel:8862082200"> :  8862082200</a>

                                            </div>
                                        </div> */}

                                        {/* <div className="text-center" >
                                            <label className="cursor-pointer fw-bold text-black" onClick={() => handleDownloadAPk()}>
                                                <i className="fa-solid fa-mobile px-2" style={{ fontSize: "20px", textDecoration: 'underline' }} ></i>
                                                Download Android App</label>
                                        </div> */}

                                        <div className="" style={{fontSize:'14px'}}>
                                            <hr style={{ border: '1px solid black' }} />
                                            <div className="row px-2">
                                                <div className="col-2 px-2">
                                                    <span style={{ whiteSpace: 'nowrap' }}>सूचना क्र. १)</span>
                                                </div>
                                                <div className="col-10" style={{ textAlign: "justify" }}>
                                                    <span> प्रणाली मध्ये काही टेक्निकल अडचण असल्यास  <a style={{ color: "#173fae", fontWeight: "bold", whiteSpace: 'nowrap' }} href="tel:+918862082200">+91 88620 82200</a>  ह्या नंबर ला call करा किंवा <a style={{ color: "#173fae", fontWeight: "bold" }} href="mailto:info@cdat.co.in"> info@cdat.co.in </a> ह्या E-mail ID ला मेल करा. </span>
                                                </div>
                                            </div>

                                            <div className="row pt-2 px-2">
                                                <div className="col-2 px-2">
                                                    <span style={{ whiteSpace: 'nowrap' }}>सूचना क्र. २)</span>
                                                </div>
                                                <div className="col-10" style={{ textAlign: "justify" }}>
                                                    <span> नोंदणी केलेल्या तक्रार संदर्भात काही प्रश्न असल्यास <a style={{ color: "#173fae", fontWeight: "bold", whiteSpace: 'nowrap' }} href="tel:02026058476">020 2605 8476 </a> ह्या नंबर ला call करा किंवा  ह्या E-mail <a style={{ color: "#173fae", fontWeight: "bold" }} href="mailto:pndt07@gmail.com"> pndt07@gmail.com </a> ID ला मेल करा .</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}