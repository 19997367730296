import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess, toastUnauthorized } from "../../AlertMessage/AlertMessage";

export const DesignationMasterGetAPI = createAsyncThunk("DesignationMasterGet", async ({ data }) => {
    const {
        DesignationName,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Get_M_Designation_Select?DesignationName=${DesignationName}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DesignationMasterGetSlice = createSlice({
    name: "DesignationMasterGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationMasterGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationMasterGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(DesignationMasterGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const DesignationMasterGetReducer = DesignationMasterGetSlice.reducer

//Insert API

export const DesignationMasterInsertAPI = createAsyncThunk("DesignationMasterInsert", async ({ data }) => {
    const {
        DesignationName,
        M_UserID,
        token,
        handleShowModal,
        handleIsPost,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("DesignationName", DesignationName);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Post_M_Designation_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowModal()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const DesignationMasterInsertSlice = createSlice({
    name: "DesignationMasterInsert",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationMasterInsertAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationMasterInsertAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(DesignationMasterInsertAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const DesignationMasterInsertReducer = DesignationMasterInsertSlice.reducer


//Update API

export const DesignationMasterUpdateAPI = createAsyncThunk("DesignationMasterUpdate", async ({ data }) => {
    const {
        M_DesignationID,
        DesignationName,
        M_UserID,
        token,
        handleShowModal,
        handleIsPost
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("DesignationName", DesignationName);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Patch_M_Designation_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowModal()
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const DesignationMasterUpdateSlice = createSlice({
    name: "DesignationMasterUpdate",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationMasterUpdateAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationMasterUpdateAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(DesignationMasterUpdateAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const DesignationMasterUpdateReducer = DesignationMasterUpdateSlice.reducer



//Delete API

export const DesignationMasterDeleteAPI = createAsyncThunk("DesignationMasterDelete", async ({ data }) => {
    const {
        M_DesignationID,
        M_UserID,
        token,
        handleShowDelete,
        handleIsPost
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/M_Designation_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowDelete()
                handleIsPost()
                toastSuccess(result.message)
                return result.data
            } else {
                return result
            }
        })
})

const DesignationMasterDeleteSlice = createSlice({
    name: "DesignationMasterDelete",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationMasterDeleteAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationMasterDeleteAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(DesignationMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const DesignationMasterDeleteReducer = DesignationMasterDeleteSlice.reducer