import { DesignationDDLReducer, DistrictDDLReducer, EmployeeDDLReducer, FinantialYearDDLReducer, GenderDDLReducer, MonthDDLAPIReducer, RoleDDLReducer, TalukaDDLReducer, UserForwardSelectDDLReducer, VillageDDLReducer } from "./DDLSlice/DDLSlice";
import { DesignationMasterDeleteReducer, DesignationMasterGetReducer, DesignationMasterInsertReducer, DesignationMasterUpdateReducer } from "./MasterSlice/DesignationMasterSlice";
import { UserCreationMasterDeleteReducer, UserCreationMasterGetReducer, UserCreationMasterInsertReducer, UserCreationMasterUpdateReducer } from "./MasterSlice/UserCreationMasterSlice";
import { EmployeeMasterGetReducer, EmployeeMasterInsertReducer } from "./MasterSlice/EmployeeMasterSlice";
import { CitizenRegistrationPostReducer, GrievanceRegistrationPostReducer } from "./Registration/RegistrationSlice";
import { ForwardPostReducer, GrievanceRegistrationWiseReportActionPatchReducer, ReceivedGrievanceAcknowledgePostReducer, ReceivedGrievanceExportGetReducer, ReceivedGrievanceGetReducer, SiteVerificationUploadImagePatchReducer } from "./DashboardSlice/ReceivedGrievanceSlice";
import { GrievanceStatusFeedbackPatchReducer, GrievanceStatusGetReducer, TrackDetailsGetReducer } from "./CitizenRegistrationSlice/GrievanceStatusSlice";
import { DashMainCountGetDataReducer } from "./DashboardSlice/DashboardMainCountSlice";
import { AcknowledgeGrievanceDocumentUploadActionPatchReducer, MCRuralReceivedGrievanceAcknowledgePostReducer, ReceivedResolvedGrievanceExportDataGetReducer, ReceivedResolvedGrievanceGetReducer } from "./DirectorDashboardSlice/ReceivedResolvedGrievanceSlice";
import { ChartCountCountGetDataReducer, DirectorDashboardMainCountGetDataReducer } from "./DirectorDashboardSlice/DirectorDashboardMainCountSlice";


export const rootreducer = {
    GenderDDLData: GenderDDLReducer,
    CitizenRegistrationPostData: CitizenRegistrationPostReducer,
    GrievanceRegistrationPost: GrievanceRegistrationPostReducer,
    DistrictDDLData: DistrictDDLReducer,
    TalukaDDLData: TalukaDDLReducer,
    VillageDDLData: VillageDDLReducer,
    DesignationDDL: DesignationDDLReducer,
    RoleDDLData: RoleDDLReducer,
    EmployeeDDLData: EmployeeDDLReducer,
    DesignationDDL: DesignationDDLReducer,
    RoleDDLData: RoleDDLReducer,
    DesignationMasterGetData: DesignationMasterGetReducer,
    DesignationMasterInsert: DesignationMasterInsertReducer,
    DesignationMasterUpdate: DesignationMasterUpdateReducer,
    DesignationMasterDelete: DesignationMasterDeleteReducer,

    UserCreationMasterGetData: UserCreationMasterGetReducer,
    UserCreationMasterInsertData: UserCreationMasterInsertReducer,
    UserCreationMasterUpdateData: UserCreationMasterUpdateReducer,
    EmployeeMasterGetData: EmployeeMasterGetReducer,
    EmployeeMasterInsert: EmployeeMasterInsertReducer,
    UserCreationMasterDeleteData: UserCreationMasterDeleteReducer,


    ReceivedGrievanceGetData: ReceivedGrievanceGetReducer,
    ReceivedGrievanceAcknowledgePostData: ReceivedGrievanceAcknowledgePostReducer,
    GrievanceStatusGetData: GrievanceStatusGetReducer,
    FinantialYearDDLData: FinantialYearDDLReducer,
    MonthDDLData: MonthDDLAPIReducer,
    GrievanceRegistrationWiseReportActionPatchData: GrievanceRegistrationWiseReportActionPatchReducer,
    DashMainCountGetData: DashMainCountGetDataReducer,
    GrievanceStatusFeedbackPatchData: GrievanceStatusFeedbackPatchReducer,

    // ---------------------Director Dashboard----------------

    ReceivedResolvedGrievanceGetData: ReceivedResolvedGrievanceGetReducer,
    MCRuralReceivedGrievanceAcknowledgePostData: MCRuralReceivedGrievanceAcknowledgePostReducer,
    DirectorDashboardMainCountGetData: DirectorDashboardMainCountGetDataReducer,
    AcknowledgeGrievanceDocumentUploadActionPatchData: AcknowledgeGrievanceDocumentUploadActionPatchReducer,
    SiteVerificationUploadImagePatchData: SiteVerificationUploadImagePatchReducer,
    TrackDetailsGetData: TrackDetailsGetReducer,
    ChartCountCountGetData: ChartCountCountGetDataReducer,
    UserForwardSelectDDLData:UserForwardSelectDDLReducer,
    ReceivedResolvedGrievanceExportDataGet: ReceivedResolvedGrievanceExportDataGetReducer,
    ForwardPostData : ForwardPostReducer,

    ReceivedGrievanceExportGetData:ReceivedGrievanceExportGetReducer
}