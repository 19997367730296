import { t } from "i18next"
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../Helper/Context/context";
import { useState } from "react";
import getBase64 from "../../../../Helper/FileToBase64";
import { AcknowledgeGrievanceDocumentUploadActionPatchAPI } from "../../../../Helper/Redux/DirectorDashboardSlice/ReceivedResolvedGrievanceSlice";



const DocumentUploadPopup = (props) => {

    const { handleDocumentUploadView, Title, ModalData, TableData, handleIsPost } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [DocumentUrl, setDocumentUrl] = useState('')
    const [UploadFileError, setUploadFileError] = useState(false)
    const [IsUploadFile, setIsUploadFile] = useState(false)
    const [UploadFileBase64, setUploadFileBase64] = useState(TableData ? TableData.UploadFileProof : '')
    const [NoteErrorMsg, setNoteErrorMsg] = useState(false)
    const [ActionRemark, setActionRemark] = useState('')

    // const getUrltoBase64 = (data) => {
    //     getBase64FromUrlUploadFileProof(data.UploadFileProof)

    // }
    const handleShowDocument = (url) => {
        var extension = url.split('.').pop();
        // console.log(url)
        if (extension == 'pdf') {
            window.open(url)
        } else {
            setDocumentUrl(url)
            // setDocumentShow(true)
        }

    }
    const RequiredSize = 3145728
    const UploadFile = (target) => {
        // console.log(target.files[i].url)
        if (target) {
            if (target.files[0].size > RequiredSize) {
                setUploadFileError(true)
            }
            else {
                setUploadFileError(false)
                setIsUploadFile(true)
                getBase64(target.files[0])
                    .then(result => {
                        setUploadFileBase64(result)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
        else {
            setUploadFileError(false)
        }
    }
    const handleDocumentAction = () => {
        const data = {

            T_GrievanceRegistrationID: ModalData.RowData.t_GrievanceRegistrationID,
            ActionReportStateNodal: UploadFileBase64,
            ActionRemark: ActionRemark,
            token: token,
            M_UserID: UserID,
            handleShowConfirmation: handleDocumentUploadView,
            handleIsPost: handleIsPost

        }
        dispatch(AcknowledgeGrievanceDocumentUploadActionPatchAPI({ data }))
    }

    const { isPostLoading } = useSelector(state => state.AcknowledgeGrievanceDocumentUploadActionPatchData)

    // console.log("ModalData", ModalData)

    return (
        <>
            <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
                <div className="modal-dialog modal-xl view_modal_size" role="document" style={{ width: '50vw' }} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">{Title}</h5>
                            <button onClick={handleDocumentUploadView} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mx-lg-4">

                            <div className="col-12 col-lg-10 mx-auto py-4">
                            <span className="all_lable_text ">Upload File</span>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    id="UploadFile"
                                    name="UploadFile"
                                    className="form-control mt-2"
                                    onChange={(e) => UploadFile(e.target)}
                                />
                            </div>
                            {
                                UploadFileError && <text className="RegxValidation">Please upload less than 3 MB file</text>
                            }

                            <div className="row">
                                <div className="col-12 col-lg-10 mx-auto">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder='Type Here'
                                        name='Address'
                                        value={ActionRemark}
                                        onChange={(e) => setActionRemark(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <div className="row">
                                <div className="col-12  d-flex justify-content-center">
                                    <div className="form-group w-80 d-flex justify-content-center">
                                        <button
                                            type='button'
                                            disabled={UploadFileBase64 == ''}
                                            onClick={() => handleDocumentAction()}
                                            className="btn bg-success text-white border-2 mx-2 px-4" data-ktwizard-type="action-next">
                                            Submit
                                        </button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default DocumentUploadPopup;