import { t } from "i18next"
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../../Helper/Context/context";
import { TrackDetailsGetAPI } from "../../../../../Helper/Redux/CitizenRegistrationSlice/GrievanceStatusSlice";
import { useEffect } from "react";
import moment from "moment";

export default function TrackPopup(props) {

    const { handleTrackDetails, Title, handleFeedback, Flag, ModalData, ScreenName1 } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    console.log(ModalData.RowData.t_GrievanceRegistrationID)


    useEffect(() => {
        const data = {
            T_GrievanceRegistrationID: ModalData.RowData.t_GrievanceRegistrationID,
            M_UserID: UserID,
            token: token,

        }
        dispatch(TrackDetailsGetAPI({ data }))
    }, [])

    const { TableData, isLoading } = useSelector(state => state.TrackDetailsGetData)

    return (
        <>
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className="mt-2 ml-4">
                                <h5 className='  text-center login_text '>
                                    {t('GrievanceStatus.Track')}
                                </h5>
                            </div>
                            <div className='mt-1'>
                                {/* <ExportOfficeLetterSecondPopUpCOMM /> */}
                            </div>

                            <button type="button" className="btn-close mr-3 mt-2 " data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    handleTrackDetails(false)
                                }}
                            >

                            </button>
                        </div>
                        <div class="row py-2" >
                            {
                                TableData && TableData.length > 0 ?
                                    TableData.map((i, index) => {
                                        return (


                                            <div class="col-md-12 col-lg-12" >

                                                <div class="tracking-list border-0" >

                                                    <div class="tracking-item">
                                                        <div class="tracking-icon status-intransit">
                                                            <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle"
                                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                        <div class="tracking-date">

                                                            <i class="fa-solid fa-circle-check fs-4 text-success"></i>
                                                            <span className="text text-success fs-6">{i?.lableName}</span>
                                                        </div>
                                                        <div class="tracking-content">
                                                            <span>{i.designationName ? i.designationName : '-'}</span>
                                                            <span>{i.date ? moment(i.date).format('DD_MM-YYYY') : '-'}</span></div>
                                                    </div>
                                                    {/* <div class="tracking-item">
                                                        <div class="tracking-icon status-current blinker">
                                                            <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle"
                                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                        <div class="tracking-date">

                                                            <i class="fa-solid fa-user fs-4 text-success"></i>

                                                        </div>
                                                        <div class="tracking-content">
                                                            <span className="text text-success">{t('GrievanceStatus.AssignedGrievance')}</span>
                                                            <span className="text text-danger">Officer Name</span>
                                                            <span>Designation District</span>
                                                            <span>10 Aug 2025, 03:00pm</span>
                                                        </div>
                                                    </div> */}

                                                    {/* <div class="tracking-item-pending">
                                                        <div class="tracking-icon status-intransit">
                                                            <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle"
                                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                        <div class="tracking-date">

                                                            <i class="fa-solid fa-user fs-4"></i>
                                                        </div>
                                                        <div class="tracking-content">
                                                            <span className="text text-success">{t('GrievanceStatus.IfEscalatedThen')}</span>
                                                            <span className="text text-danger">State Nodal Officer</span>
                                                            <span>12 Aug 2025, 05:00pm</span>
                                                        </div>
                                                    </div> */}
                                                    {/* <div class="tracking-item-pending">
                                                        <div class="tracking-icon status-intransit">
                                                            <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle"
                                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                        <div class="tracking-date">

                                                            <i class="fa-solid fa-circle-check fs-4"></i>
                                                        </div>
                                                        <div class="tracking-content">
                                                            <span className="text text-success">{t('GrievanceStatus.GrievanceResolved')}</span>
                                                            <span>12 Aug 2025, 09:00pm</span>
                                                        </div>
                                                    </div> */}
                                                </div>

                                            </div>


                                        )


                                    })
                                    : <>No data found</>
                            }

                        </div>






                        <div className="modal-footer">
                            <button type="button"
                                // onClick={() => {
                                //     setTrackShowPopup(false)
                                // }} 
                                className="btn bg-success text-white" data-bs-dismiss="modal">
                                {t('GrievanceStatus.Submit')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}