import CryptoJS from "crypto-js";
import { BaseUrl } from "../BaseURL";
import { toastError } from "../AlertMessage/AlertMessage";
import { data } from "../ChartDeta";



export async function loginUser(dispatch, loginPayload) {

    const { userName, password, setloading, handleRollIdWiseDashboard, handleErrorIdWiseDashboard, setLoginmessage } = loginPayload

    let myHeaders = new Headers();
    myHeaders.append("accept", "/");
    myHeaders.append("Content-Type", "application/json");

    // var raw = `grant_type=password&Username=${userName}&Password=${password}&ApplicationType=web`;
    const raw = JSON.stringify({
        "username": userName,
        "password": password,
        "applicationType": "web"
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        setloading(true)
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${BaseUrl}/token`, requestOptions)
        let jsondata = await response.json();
        let data = jsondata[0]
        sessionStorage.setItem("A454A", data.m_RoleID);

        if (data && data.loginmessage != "Invalid Username or Password..") {
            setloading(false)
            dispatch({ type: 'LOGIN_SUCCESS', payload: data });
            handleRollIdWiseDashboard(data && data)

            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'RangaFulan69').toString();
            try {
                sessionStorage.setItem("AmchiMulgi", ciphertext);
                sessionStorage.setItem("A454A", data.m_RoleID);
                // toastSuccesss()
            } catch (error) {
                console.error("Error storing data in sessionStorage", error);
            }

            return data && data[0];
        } else {
            setloading(false)
            setLoginmessage(data && data.loginmessage)
            toastError(data.loginmessage)
            handleErrorIdWiseDashboard(data && data.loginmessage)
            dispatch({ type: 'LOGIN_ERROR', error: data.loginmessage });
            return;
        }
    }
    catch (error) {
        setloading(false)

        handleErrorIdWiseDashboard('Internal Server Error')
        dispatch({ type: 'LOGIN_ERROR', error: error });
        console.log('error');
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    sessionStorage.removeItem('AmchiMulgi');
    sessionStorage.clear()
}
