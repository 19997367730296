

export const RegExOnlyText = (text) => {
    let regex = new RegExp(/^[a-zA-Z ]*$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}

export const RegExAlphabetsandSpecialCharacters = (text) => {
    let regex = new RegExp(/^[ A-Za-z_@./#&+-]*$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}

export const RegExNumbersOnly = (text) => {
    let regex = new RegExp(/^[0-9]*$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}

export const RegExMobile = (text) => {
    let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}

export const RegExEmail = (text) => {
    let regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}

export const RegExPassword = (text) => {
    let regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?^&_+)()])[A-Za-z\d@$!%*?&]{6,}$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}


export const IsPasswordMatch = (text) => {
    let regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?^&_+)()])[A-Za-z\d@$!%*?&]{6,}$/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}


export const RegExIsURLValid = (text) => {
    let regex = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/)
    if (regex.test(text) || text === "") {
        return false
    } else {
        return true
    }
}


