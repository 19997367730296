import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";

export const GenderDDLAPI = createAsyncThunk("GenderDDL", async ({ data }) => {
    const {
        GroupID,
        Language,
        M_UserID,
        setGroupIDData
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Indicator_Select?GroupID=${GroupID}&Language=${Language}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setGroupIDData(result.data)
                return result.data
            } else {
                return result
            }
        })
})

const GenderDDLSlice = createSlice({
    name: "GenderDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GenderDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GenderDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(GenderDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const GenderDDLReducer = GenderDDLSlice.reducer

//District DDL

export const DistrictDDLAPI = createAsyncThunk("DistrictDDL", async ({ data }) => {
    const {
        M_IndicatorGrievanceAreaID,
        Language,
        M_UserID,
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_District_Select?M_IndicatorGrievanceAreaID=${M_IndicatorGrievanceAreaID ? M_IndicatorGrievanceAreaID : '0'}&Language=${Language}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DistrictDDLSlice = createSlice({
    name: "DistrictDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DistrictDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DistrictDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(DistrictDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const DistrictDDLReducer = DistrictDDLSlice.reducer



//Taluka DDL

export const TalukaDDLAPI = createAsyncThunk("TalukaDDL", async ({ data }) => {
    const {
        M_StateID,
        M_DistrictID,
        Language,
        M_UserID,
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Taluka_Select?M_StateID=${M_StateID}&M_DistrictID=${M_DistrictID}&Language=${Language}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const TalukaDDLSlice = createSlice({
    name: "TalukaDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TalukaDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TalukaDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(TalukaDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const TalukaDDLReducer = TalukaDDLSlice.reducer


//Village DDL

export const VillageDDLAPI = createAsyncThunk("VillageDDL", async ({ data }) => {
    const {
        M_StateID,
        M_DistrictID,
        M_TalukaID,
        Language,
        M_UserID,
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Village_Select?M_StateID=${M_StateID}&M_DistrictID=${M_DistrictID}&M_TalukaID=${M_TalukaID}&Language=${Language}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const VillageDDLSlice = createSlice({
    name: "VillageDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(VillageDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(VillageDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(VillageDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const VillageDDLReducer = VillageDDLSlice.reducer

// Designation DDL

export const DesignationDDLAPI = createAsyncThunk("DesignationDDL", async ({ data }) => {
    const {
        M_UserID,
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Designation_Select?M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DesignationDDLSlice = createSlice({
    name: "DesignationDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(DesignationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const DesignationDDLReducer = DesignationDDLSlice.reducer

// Role DDl

export const RoleDDLAPI = createAsyncThunk("RoleDDL", async ({ data }) => {
    const {
        M_UserID,
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Role_Select?M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const RoleDDLSlice = createSlice({
    name: "RoleDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(RoleDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(RoleDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(RoleDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const RoleDDLReducer = RoleDDLSlice.reducer

// Employee DDl

export const EmployeeDDLAPI = createAsyncThunk("EmployeeDDL", async ({ data }) => {
    const {
        M_DesignationID,
        Language,
        M_UserID,
    } = data

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Employee_Select?M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&Language=${Language}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeDDLSlice = createSlice({
    name: "EmployeeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(EmployeeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const EmployeeDDLReducer = EmployeeDDLSlice.reducer

// Finantial Year DDL

export const FinantialYearDDLAPI = createAsyncThunk("FinantialYearDDLData", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_FinancialYear_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const FinantialYearDDLSlice = createSlice({
    name: "FinantialYearDDLData",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(FinantialYearDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(FinantialYearDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(FinantialYearDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const FinantialYearDDLReducer = FinantialYearDDLSlice.reducer

// Month DDL

export const MonthDDLAPI = createAsyncThunk("MonthDDLData", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Month_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const MonthDDLSlice = createSlice({
    name: "MonthDDLData",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MonthDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MonthDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(MonthDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const MonthDDLAPIReducer = MonthDDLSlice.reducer

export const UserForwardSelectDDLAPI = createAsyncThunk("UserForwardSelectDDL", async ({ data }) => {
    const {
        token,
        M_UserID,
        setgridData
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_User_Forward_Select?M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                let tempData = result.data.map((item) => ({ ...item, ischecked: false }))
                setgridData(tempData)
                return result.data
            } else {
                return result
            }
        })
})

const UserForwardSelectDDLSlice = createSlice({
    name: "UserForwardSelectDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserForwardSelectDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserForwardSelectDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(UserForwardSelectDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const UserForwardSelectDDLReducer = UserForwardSelectDDLSlice.reducer