import { t } from "i18next"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthState } from "../../../Helper/Context/context"



export const SideBar = (props) => {

    const navigat = useNavigate()
    const { active, UserName } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { RoleID } = userDetails



    const [IsShow, setIsShow] = useState(false)

    function toggle() {
        let side = document.getElementById("myDIV")
        side.classList.toggle("hidden")
    }

    const handleIsShow = () => {
        setIsShow(!IsShow)
    }


    return (
        <>

            <div id="myDIV" className="fixedsidebar hidden " style={{ height: '100vh' }}>
                <ul className="navbar-nav bg-gradient-primarys sidebar sidebar-dark accordion " id="sidebar">
                    <div className="mt-lg-3 pt-lg-1 pc_none">
                        <span className='= hamburgersize  pointer pt-5 mt-5' >
                            <i className="fa-solid fa-xmark pt-1 text-danger  pe-4" style={{ fontSize: '20px' }} onClick={toggle}  >  </i>
                        </span>
                    </div>
                    <div className="pt-2" id="accordionSidebar">
                        {/* <span className="pt-3 ">
                        <img src={Data?.logoPhoto} alt="" srcset="" className="logo" style={{ borderRadius: "50%" }} />
                    </span> */}

                        {/* <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fas fa-times fs-2 ps-3 pt-2 text-white"></i>
 
                    </button> */}
                        {/* <hr className="sidebar-divider mt-2" /> */}

                        {
                            (UserName == 'NewUser') ?
                                // RoleID == 7 ?
                                <>
                                    <li className={active == "RegistrationDashboard" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate(`/RegistrationDashboard?UserName=${UserName}`)}>
                                            {/* <i className="fas fa-fw fa-tachometer-alt text-dark"></i> */}
                                            <span className="fw-bold hideLabel">{t('GrievanceRegistration.GrievanceRegistration')}</span></a>
                                    </li>
                                    <li className={active == "GrievanceStatus" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate(`/GrievanceStatus?UserName=${UserName}`)}>
                                            {/* <i className="fas fa-fw fa-tachometer-alt text-dark"></i> */}
                                            <span className="fw-bold hideLabel">{t('GrievanceStatus.GrievanceStatus')}</span></a>
                                    </li>
                                </>
                                :
                                <>


                                </>
                        }
                        {
                            RoleID == 1 || RoleID == 7 || RoleID == 3 || RoleID == 2 || RoleID == 4 ?
                                <></>
                                :
                                <>
                                    <li className={active == "MainDashboard" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/MainDashboard')}>
                                            <i className="fas fa-fw fa-tachometer-alt text-dark"></i>
                                            <span className=" hideLabel ">
                                                {t('DirectorDashboard.Dashboard')}
                                            </span>
                                        </a>
                                    </li>


                                </>

                        }

                        {

                            RoleID == 2 ?

                                <>
                                    <li className={active == "DirectorDashboard" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/DirectorDashboard')}>
                                            <i className="fas fa-fw fa-tachometer-alt text-dark"></i>
                                            <span className=" hideLabel ">
                                                {t('DirectorDashboard.Dashboard')}
                                            </span>
                                        </a>
                                    </li>


                                </>
                                :
                                <></>

                        }

                        {

                            RoleID == 3 ?

                                <>
                                    <li className={active == "DirectorDashboard" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/DirectorDashboard')}>
                                            <i className="fas fa-fw fa-tachometer-alt text-dark"></i>
                                            <span className=" hideLabel ">
                                                {t('DirectorDashboard.Dashboard')}
                                            </span>
                                        </a>
                                    </li>


                                </>
                                :
                                <></>

                        }

                        {

                            RoleID == 4 ?

                                <>
                                    <li className={active == "DirectorDashboard" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/DirectorDashboard')}>
                                            <i className="fas fa-fw fa-tachometer-alt text-dark"></i>
                                            <span className=" hideLabel ">
                                                {t('DirectorDashboard.Dashboard')}
                                            </span>
                                        </a>
                                    </li>


                                </>
                                :
                                <></>

                        }

                        {
                            RoleID == 1  ?
                                <>
                                    {/* <li className={active == "DesignationMaster" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                                            aria-expanded="true" aria-controls="collapseTwo"
                                            onClick={handleIsShow}
                                        >
                                            <i className="fas fa-fw fa-cog sidebar_icon text-dark px-3 px-md-0"></i>
                                            <span className="hideLabel masters ">Master</span>
                                            <i class="fa-solid fa-angle-down ps-2 hideLabel"></i>
                                        </a>
                                        <div id="collapseTwo" className={IsShow ? "collapse padd show" : "collapse padd "} aria-labelledby="headingTwo"
                                            data-parent="#accordionSidebar">
                                            <div className="py-2 collapse-inner rounded sub_icon">

                                                <a className="collapse-item"
                                                    onClick={() => navigate("/DesignationMaster")}>

                                                    <i class="fa-solid fa-clipboard text-danger"></i>
                                                    <span className="hideLabel ps-1">Designation</span>

                                                </a>

                                                <a className="collapse-item"
                                                    onClick={() => navigate("/EmployeeMaster")} >
                                                    <i class="fa-solid fa-user text-danger"></i>
                                                    <span className="hideLabel ps-1">Employee Master</span>
                                                </a>

                                                <a className="collapse-item"
                                                    onClick={() => navigate("/UserCreationMaster")} >
                                                    <i className="fa-solid fa-circle-plus text-danger"></i>
                                                    <span className="hideLabel ps-1 ">User Creation</span>
                                                </a>
                                            </div>
                                        </div>
                                    </li> */}

                                    <li className={active == "DesignationMaster" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/DesignationMaster')}>
                                            <i class="fa-solid fa-clipboard text-danger"></i>
                                            <span className=" hideLabel ">
                                                {/* {t('DirectorDashboard.Dashboard')} */}
                                                {t('DirectorDashboard.Designation')}
                                            </span>
                                        </a>
                                    </li>

                                    <li className={active == "EmployeeMaster" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/EmployeeMaster')}>
                                            <i class="fa-solid fa-user text-danger"></i>
                                            <span className=" hideLabel ">
                                                {/* {t('DirectorDashboard.Dashboard')} */}
                                                {t('DirectorDashboard.EmployeeMaster')}
                                            </span>
                                        </a>
                                    </li>

                                    <li className={active == "UserCreationMaster" ? "nav-item active" : "nav-item "}>
                                        <a className="nav-link" onClick={() => navigate('/UserCreationMaster')}>
                                            <i className="fa-solid fa-circle-plus text-danger"></i>
                                            <span className=" hideLabel ">
                                                {/* {t('DirectorDashboard.Dashboard')} */}
                                                {t('DirectorDashboard.UserCreation')}
                                            </span>
                                        </a>
                                    </li>
                                </>
                                :
                                ''
                        }

                    </div>

                </ul>
            </div>

        </>
    )
}