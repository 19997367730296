export const Marathi = {
    DirectorDashboard: {
        Dashboard: 'डॅशबोर्ड',
        TotalReceivedGrievance: 'एकूण प्राप्त तक्रारी',
        AcknowledgeGrievance: 'स्वीकारलेल्या तक्रारी',
        AcknowledgePending: 'प्रलंबित तक्रारी',
        CompletedSiteVisit: 'सेंटर पडताळणी ',
        PendingSiteVisit: 'प्रलंबित सेंटर पडताळणी ',
        ReceivedGrievance: 'प्राप्त  तक्रारी',
        ReceivedGrievanceByStateNodalOfficer: 'राज्य नोडल अधिकाऱ्याकडून प्राप्त झालेल्या तक्रारी ',
        ReslovedGrievance: 'निराकरण केलेल्या तक्रारी',
        PendingGrievance: 'प्रलंबित तक्रारी',
        FeedbackGivenbyCitizen: 'नागरिकांनी दिलेला अभिप्राय',
        FeedbackGivenbyRegisterCitizen: 'नोंदणीकृत नागरिकांनी दिलेला अभिप्राय',
        FeedbackPending: 'प्रलंबित अभिप्राय',
        EscalatedGrievancesToStateNodalOfficer: 'राज्य स्तरावर हस्तांतरित केलेल्या तक्रारी ',
        EscalatedGrievances: 'हस्तांतरित केलेल्या तक्रारी ',
        EscalatedResolvedGrievances: 'राज्य स्तरावर हस्तांतरित केलेल्या तक्रारिंचे निराकरण',
        AcknowledgeDate: 'स्वीकारलेली दिनांक',
        Grievance: 'तक्रारी',
        ReceivedDate: 'प्राप्त दिनांक',
        DistrictName: 'जिल्हाचे नाव',
        TalukaName: 'तालुक्याचे नाव',
        GrievanceType: 'तक्रार प्रकार',
        GrievanceDetails: 'तक्रार तपशील',
        OfficerName: 'अधिकाऱ्याचे नाव',
        Action: "कृती",
        MunicipalCorporation: 'महानगरपालिका',
        SiteVisitDate: 'सेंटर पडताळणी दिनांक',
        Photo: 'छायाचित्र',
        ResolvedDate: 'तक्रारी निराकरण दिनांक',
        Report: 'अहवाल',
        EscalatedGrievancesDate: 'हस्तांतरित केलेली दिनांक',
        StateNodalUploadReport: 'राज्य नोडल अपलोड अहवाल',
        RuralArea: 'ग्रामीण भाग',
        InMunicipalCorporation: "महापालिकेत",
        InRuralArea: "ग्रामीण भागात",
        FromMOHMunicipalCorporation: "(MOH) महानगरपालिकेकडून",
        FromCSRuralArea: "(CS) ग्रामीण भागातून",
        ActionReportUploadedforEscalatedGrievance: "हस्तांतरित तक्रारींचे निराकरण",
        UploadedActionReport: "अपलोड केलेले कृती अहवाल",
        FeedbackbyRegisteredCitizen: "नोंदणीकृत नागरिकांकडून अभिप्राय",
        EscalatedGrievanceDate: "हस्तांतरित केलेली दिनांक",
        FeedbackDate: "अभिप्राय दिनांक",
        ResolvedGrievance: 'तक्रारीचे निराकरण केले',
        Designation: 'पदनाम',
        EmployeeMaster: 'कर्मचारी मास्टर',
        UserCreation: 'यूजर तयार करणे',
        GrievanceDate: "तक्रार  दिनांक",
        SiteVerification: "सेंटर पडताळणी",
        Status: "स्थिती",
        PendingDate: "प्रलंबित दिनांक",
        ResovedDate:"प्राप्त दिनांक",
    },
    CitizenRegistration: {
        PublicGrievanceInAamchiMulgiScheme: 'आमची मुलगी योजनेत सार्वजनिक तक्रार',
        CitizenRegistration: 'नागरिक नोंदणी',
        CitizenName: 'नागरिकाचे नाव',
        DateOfBirth: 'जन्मदिनांक',
        EmailAddress: 'ईमेल पत्ता',
        MobileNo: 'मोबाईल क्र',
        Gender: 'लिंग',
        Address: 'पत्ता',
        UserName: 'यूजरचे नाव',
        Password: 'पासवर्ड',
        ConfirmPassword: 'कन्फर्म पासवर्ड',
        Register: 'नोंदणी करा',
        Cancel: 'रद्द करा',
    },
    GrievanceRegistration: {
        GrievanceRegistration: 'तक्रार नोंदणी',
        GrievanceAreaDetails: 'तक्रार क्षेत्र तपशील',
        GrievanceArea: 'तक्रार क्षेत्र',
        CenterName: 'केंद्राचे नाव',
        District: 'जिल्हा',
        Taluka: 'तालुका',
        Village: 'गाव',
        AreaName: 'क्षेत्राचे नाव',
        AreaAdress: 'क्षेत्राचा पत्ता',
        HospitalDetails: 'हॉस्पिटल तपशील',
        HospitalName: 'हॉस्पिटलचे नाव',
        HospitalAddress: 'हॉस्पिटलचा पत्ता',
        DoctorName: 'डॉक्टरांचे नाव',
        DoctorAddress: 'डॉक्टरांचा पत्ता',
        ReferredDoctorName: 'संदर्भित डॉक्टरांचे नाव',
        VanNumber: 'व्हॅन क्र.',
        PatientName: 'रुग्णाचे नाव',
        PatientAddress: 'रुग्णाचा पत्ता',
        GrievanceDetails: 'तक्रार तपशील',
        Grievance: 'तक्रार',
        Register: 'नोंदणी करा',
        Cancel: 'रद्द करा',
        Back: 'मागे',
        Remark: 'शेरा'
    },
    GrievanceStatus: {
        GrievanceStatus: 'तक्रारीची स्थिती',
        Resolved: 'निराकरण केले',
        GrievanceDetails: 'तक्रारीचे तपशील',
        GrievanceArea: 'तक्रार क्षेत्र',
        HospitalDetails: 'हॉस्पिटल तपशील',
        DoctorName: 'डॉक्टरांचे नाव',
        PatientName: 'रुग्णाचे नाव',
        Feedback: 'फीडबॅक',
        Pending: 'प्रलंबित',
        GrievanceDetails: 'तक्रारीचे तपशील',
        GrievanceArea: 'तक्रार क्षेत्र',
        HospitalDetails: 'हॉस्पिटल तपशील',
        DoctorName: 'डॉक्टरांचे नाव',
        PatientName: 'रुग्णाचे नाव',
        Track: 'ट्रॅक',
        GrievanceSubmitted: 'तक्रार सादर केली',
        AssignedGrievance: 'तक्रार नियुक्त केली',
        IfEscalatedThen: 'वाढले तर',
        GrievanceResolved: 'तक्रारीचे निराकरण केले',
        Submit: 'प्रस्तुत करणे',
        Reset: 'रीसेट',
    },
    GridHeading: {
        Sr_No: "अ.क्र",
        ComplaintID: "तक्रार क्र.",
        Date: "तक्रार दिनांक",
        Action: "कृती",
        Employee: "अधिकारी/कर्मचारी",
        Role: 'रोल',
        Area: 'क्षेत्र',
        District: 'जिल्हा',
        UserName: 'वापरकरताच नाव',
        Password: 'पासवर्ड',
        GrievanceNo: 'तक्रार.क्र.',
    }

}