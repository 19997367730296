import { t } from "i18next"
import { AstricSign } from "../../../Helper/AstricSign"

export const HospitalDetails = (props) => {
    const { GrievanceField, onChange } = props

    return (
        <>
            <div className="row border shadow bg-white mt-4 py-2 px-1 px-lg-3 mx-1 mx-lg-0">
                <div className="col-12 border-bottom py-2">
                    <h6 className="fw-bold text_color">
                        {t('GrievanceRegistration.HospitalDetails')}
                    </h6>

                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.HospitalName')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.HospitalName')}
                                name='HospitalName'
                                value={GrievanceField.HospitalName}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.HospitalAddress')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            {/* <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="eg. Mumbai Pune Bypass Rd, Narhe, Pune, Maharashtra 411041" rows="3"></textarea> */}
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.HospitalAddress')}
                                name='HospitalAddress'
                                value={GrievanceField.HospitalAddress}
                                onChange={onChange}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.DoctorName')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.DoctorName')}
                                name='DoctorName'
                                value={GrievanceField.DoctorName}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>


                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.DoctorAddress')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            {/* <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="eg. Mumbai Pune Bypass Rd, Narhe, Pune, Maharashtra 411041" rows="3"></textarea> */}
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.DoctorAddress')}
                                name='DoctorAddress'
                                value={GrievanceField.DoctorAddress}
                                onChange={onChange}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.ReferredDoctorName')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.ReferredDoctorName')}
                                name='ReferredDoctorName'
                                value={GrievanceField.ReferredDoctorName}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.PatientName')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.PatientName')}
                                name='PatientName'
                                value={GrievanceField.PatientName}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>


                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.PatientAddress')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            {/* <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="eg. Bharat Bulding, Sinhagad Road Rd, Vadgoan BK, Pune, Maharashtra 411041" rows="3"></textarea> */}
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.PatientAddress')}
                                name='PatientAddress'
                                value={GrievanceField.PatientAddress}
                                onChange={onChange}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.VanNumber')}
                            </span>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.VanNumber')}
                                name='VanNumber'
                                value={GrievanceField.VanNumber}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}