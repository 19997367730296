import { t } from "i18next"
import { DistrictDDL } from "../../../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../../../Components/CommonDDL/GenderDDL"
import { TalukaDDL } from "../../../Components/CommonDDL/TalukaDDL"
import { VillageDDL } from "../../../Components/CommonDDL/VillageDDL"
import { AstricSign } from "../../../Helper/AstricSign"

export const GrievanceAreaDetails = (props) => {
    const {
        Language,
        DistrictDDLID,
        setDistrictDDLID,
        GenderDDLID,
        setGenderDDLID,
        TalukaDDLID,
        setTalukaDDLID,
        VillageDDLID,
        setVillageDDLID,
        GrievanceField,
        setGrievanceField,
        onChange,
        UserID
    } = props

    // console.log('first',Language.Label)

    return (
        <>
            <div className="row border shadow bg-white mt-4 py-2 px-1 px-lg-3 mx-1 mx-lg-0">
                <div className="col-12 border-bottom py-2">
                    <h6 className="fw-bold text_color">
                        {t('GrievanceRegistration.GrievanceAreaDetails')}
                    </h6>

                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.GrievanceArea')}
                            </span> <AstricSign/>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <GenderDDL
                                GenderDDLID={GenderDDLID}
                                setGenderDDLID={setGenderDDLID}
                                Language={Language}
                                GroupID='1'
                                UserID={UserID}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.CenterName')}
                            </span> 
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.CenterName')}
                                name='CenterName'
                                value={GrievanceField.CenterName}
                                onChange={onChange}
                            />
                        </div>

                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.District')}
                            </span> <AstricSign/>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <DistrictDDL
                                DistrictDDLID={DistrictDDLID}
                                setDistrictDDLID={setDistrictDDLID}
                                Language={Language}
                                M_IndicatorGrievanceAreaID={GenderDDLID.ID}
                                UserID={UserID}
                               
                            />
                        </div>

                    </div>
                </div>

                {
                    GenderDDLID.ID == 2 &&
                    <>
                        <div className="col-12 col-lg-4 py-2 my-1">
                            <div className="row ">
                                <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                                    <span className="fw-bold text-dark">
                                        {t('GrievanceRegistration.Taluka')}
                                    </span>
                                </div>
                                <div className="col-12  mx-auto col-lg-12">
                                    <TalukaDDL
                                        TalukaDDLID={TalukaDDLID}
                                        setTalukaDDLID={setTalukaDDLID}
                                        M_StateID='1'
                                        M_DistrictID={DistrictDDLID.ID}
                                        Language={Language}
                                        UserID={UserID}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="col-12 col-lg-4 py-2 my-1">
                            <div className="row ">
                                <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                                    <span className="fw-bold text-dark">
                                        {t('GrievanceRegistration.Village')}
                                    </span>
                                </div>
                                <div className="col-12  mx-auto col-lg-12">
                                    <VillageDDL
                                        VillageDDLID={VillageDDLID}
                                        setVillageDDLID={setVillageDDLID}
                                        Language={Language}
                                        M_StateID='1'
                                        M_DistrictID={DistrictDDLID.ID}
                                        M_TalukaID={TalukaDDLID.ID}
                                        UserID={UserID}
                                    />
                                </div>

                            </div>
                        </div>
                    </>
                }

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.AreaName')}
                            </span><AstricSign/>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.AreaName')}
                                name='AreaName'
                                value={GrievanceField.AreaName}
                                onChange={onChange}
                            />
                        </div>

                    </div>
                </div>

                <div className="col-12 col-lg-4 py-2 my-1">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.AreaAdress')}
                            </span><AstricSign/>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            {/* <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="eg. 103, A2, Omkar Nandan, Navale Bridge, Opp.Navale IT Park, Vadgaon Bk. Pune 411041" rows="3"></textarea> */}
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder={t('GrievanceRegistration.AreaAdress')}
                                name='AreaAdress'
                                value={GrievanceField.AreaAdress}
                                onChange={onChange}
                            >
                            </textarea>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}