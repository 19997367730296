import { useState } from "react"
import { useDispatch } from "react-redux";
import { useAuthState } from "../../../../../Helper/Context/context";
import { DesignationMasterInsertAPI, DesignationMasterUpdateAPI } from "../../../../../Helper/Redux/MasterSlice/DesignationMasterSlice";

export const DesignationMasterPopUp = (props) => {
    const { handleShowModal, handleIsPost, ModalData } = props

    const dispatch = useDispatch();
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [DesignationName, setDesignationName] = useState(ModalData.RowData ? ModalData.RowData.designationName : '');

    const handleInsert = () => {
        const data = {
            DesignationName: DesignationName,
            M_UserID: UserID,
            token: token,
            handleShowModal: handleShowModal,
            handleIsPost: handleIsPost
        }
        dispatch(DesignationMasterInsertAPI({ data }))
    }

    const handleUpdate = () => {
        const data = {
            M_DesignationID: ModalData.RowData ? ModalData.RowData.m_DesignationID : '0',
            DesignationName: DesignationName,
            M_UserID: UserID,
            token: token,
            handleShowModal: handleShowModal,
            handleIsPost: handleIsPost
        }
        dispatch(DesignationMasterUpdateAPI({ data }))
    }

    const handleSubmit = () => {
        if (ModalData.Flag == 'Update') {
            handleUpdate()
        } else {
            handleInsert()
        }
    }


    return (
        <>
            <div className="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                style={{ display: "block", backgroundColor: "#0000008a" }} aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: '50%' }}>
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="heading fw-bold">Designation Master</h5>
                            <button type="button" className="btn-close"
                                onClick={() => handleShowModal()}
                            >
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row p-2">

                                <div className="col-12 col-md-6  rounded ">

                                    <label for="branch" className="text-dark fw-bold">Designation : </label>
                                    <input type="text"
                                        placeholder="Designation.."
                                        className="rounded border border_div py-1"
                                        value={DesignationName}
                                        onChange={(e) => setDesignationName(e.target.value)}
                                    />
                                </div>

                            </div>

                            <div className="py-3 pt-4 px-0 float-end">
                                <button type="button" className="btn bg-success text-white px-4"
                                    disabled={DesignationName == ''}
                                    onClick={handleSubmit}
                                >{ModalData.Flag == 'Update' ? 'Update' : 'Add'}</button>

                                <button type="button" className="btn bg-danger text-white mx-3"
                                    onClick={() => handleShowModal()}
                                > Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}