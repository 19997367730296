
import { useNavigate, useSearchParams } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"
import { SideBar } from "../SideBar/SideBar"
import { RegistrationDashboardContent } from "./RegistrationDashboardContent/RegistrationDashboardContent"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"
import { GrievanceRegistrationPostAPI } from "../../../Helper/Redux/Registration/RegistrationSlice"
import { GrievanceAreaDetails } from "../../WithoutRegistration/GrievanceAreaDetails/GrievanceAreaDetails"
import { HospitalDetails } from "../../WithoutRegistration/HospitalDetails/HospitalDetails"
import { GrievanceDetails } from "../../WithoutRegistration/GrievanceDetails/GrievanceDetails"
import { useAuthState } from "../../../Helper/Context/context"
import { Loading } from "../../../Helper/Loader/LogoLoader"

export const RegistrationDashboard = () => {
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [searchparams] = useSearchParams()
    const CitizenName = searchparams.get('CitizenName')
    const UserName = searchparams.get('UserName')
    const [IsClear, setIsClear] = useState(false)

    const [GrievanceField, setGrievanceField] = useState({
        CenterName: '',
        AreaName: '',
        AreaAdress: '',
        PatientAddress: '',
        PatientName: '',
        ReferredDoctorName: '',
        DoctorAddress: '',
        DoctorName: '',
        HospitalAddress: '',
        HospitalName: '',
        GrievanceDetails: '',
        VanNumber: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: '0',
        Label: 'Select...'
    })
    const [GenderDDLID, setGenderDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [TalukaDDLID, setTalukaDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [VillageDDLID, setVillageDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setGrievanceField(item => ({
            ...item, [name]: value
        }))
    }

    const handleClear = () => {
        setIsClear(!IsClear)
        setGrievanceField({
            CenterName: '',
            AreaName: '',
            AreaAdress: '',
            PatientAddress: '',
            PatientName: '',
            ReferredDoctorName: '',
            DoctorAddress: '',
            DoctorName: '',
            HospitalAddress: '',
            HospitalName: '',
            GrievanceDetails: '',
            VanNumber: ''
        })
        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGenderDDLID({
            ...GenderDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })
        setTalukaDDLID({
            ...TalukaDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setVillageDDLID({
            ...VillageDDLID,
            ID: 0,
            Label: 'Select...'
        })
    }

    const handleRegister = () => {
        const data = {
            M_IndicatorGrievanceAreaID: GenderDDLID.ID,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: TalukaDDLID.ID,
            M_VillageID: VillageDDLID.ID,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_UserID: UserID,
            GrievanceField: GrievanceField,
            handleClear: handleClear
        }
        dispatch(GrievanceRegistrationPostAPI({ data }))
    }
    const { Data, isPostLoading } = useSelector(state => state.GrievanceRegistrationPost)
    return (
        <>
            {isPostLoading && <Loading />}
            <Header
                Language={Language}
                setLanguage={setLanguage}
            />
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="RegistrationDashboard" UserName={UserName} />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-1 mx-0" id='content' >
                    <div className="container-fluid px-0 px-lg-4" >
                        <div className="col-12 pt-3 px-lg-1">
                            <h5 className="text-black fw-bold">{t('GrievanceRegistration.GrievanceRegistration')}</h5>
                        </div>
                        {/* <RegistrationDashboardContent /> */}
                        <GrievanceAreaDetails
                            Language={Language}
                            DistrictDDLID={DistrictDDLID}
                            setDistrictDDLID={setDistrictDDLID}
                            GenderDDLID={GenderDDLID}
                            setGenderDDLID={setGenderDDLID}
                            TalukaDDLID={TalukaDDLID}
                            setTalukaDDLID={setTalukaDDLID}
                            VillageDDLID={VillageDDLID}
                            setVillageDDLID={setVillageDDLID}
                            GrievanceField={GrievanceField}
                            onChange={onChange}
                            UserID={UserID}
                        />

                        <HospitalDetails
                            GrievanceField={GrievanceField}
                            onChange={onChange}
                        />

                        <GrievanceDetails
                            Language={Language}
                            GrievanceField={GrievanceField}
                            GrievanceTypeID={GrievanceTypeID}
                            setGrievanceTypeID={setGrievanceTypeID}
                            onChange={onChange}
                            UserID={UserID}
                        />

                        <div className="row">
                            <div className="col-12 pt-1 text-center">
                                <button
                                    type="button"
                                    className="btn bg-success text-white"
                                    disabled={GenderDDLID.ID == '0' || DistrictDDLID.ID == '0' || GrievanceField.AreaName == '' ||
                                        GrievanceField.AreaAdress == '' || GrievanceTypeID.ID == '0' || GrievanceField.GrievanceDetails == ''}
                                    onClick={handleRegister}
                                >
                                    {t('GrievanceRegistration.Register')}
                                </button>
                                <button type="button"
                                    className="btn bg-danger text-white mx-2"
                                    onClick={() => navigate('/')}
                                >
                                    {t('GrievanceRegistration.Cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )
}