import React from "react";
const Image_1 = "../assets/img/Loading.png";
const BrokenImage ='../assets/img/not_found.png'

export default function ViewURL({ setshowImagePopup, ImagePopUpData, setipost, ispost, Heading }) {

    const imageOnLoad = (event) => {
        console.log(`Picture successfully ${event.currentTarget.src} loaded.`);
        if (event.currentTarget.className !== "error") {
          event.currentTarget.className = "success";
        }
      };
      const imageOnError = (event) => {
        event.currentTarget.src = BrokenImage;
        event.currentTarget.className = "error";
      };
      
    return (
        <>
            <div className="img_pop_allign modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className="mt-2 ml-4">
                                <h4> Photo</h4>
                            </div>
                            <div className='mt-1'>
                                {/* <ExportOfficeLetterSecondPopUpCOMM /> */}
                            </div>

                            <button type="button" className="btn-close mr-3 mt-2 " data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    setshowImagePopup(false)
                                }}>
                                {/* <span className="x" aria-hidden="true">&times;</span> */}
                            </button>
                        </div>
                        <div className="col-12 modal-body " >
                            <div className="col-12 modal-header justify-content-center" >
                                {/* <img src={ImageNotFound} /> */}
                                {/* {
                                    ImagePopUpData.image === null ?
                                    // <img src={ImageNotFound} />
                                    <h1>hello</h1>
                                    :
                                    <h1>fj</h1>
                                } */}
                                {
                                    ImagePopUpData ?
                                        <img className="col-12" style={{width:"400px",height:"400px"}}  src={ImagePopUpData == null ?  "ImageNotFound" : ImagePopUpData} 
                                         onLoad={imageOnLoad} onError={imageOnError} />
                                        : <img src="../assets/img/not_found.png" />
                                }
                                {/* {

                                        ImagePopUpData ?
                                            <img className="col-12"
                                                src={`data:image/png;base64,${ImagePopUpData}`}
                                                style={{ width: "400px", height: "400px" }}
                                            />

                                            : 'No Image found'
                                } */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


