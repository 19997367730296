
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { EmployeeDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const EmployeeDDL = (props) => {
    const { EmployeeDDLID, setEmployeeDDLID, DesignationDDLID, setDesignationDDLID, Flag, data, Language, M_IndicatorGrievanceAreaID, UserID } = props

    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_DesignationID: DesignationDDLID.ID,
            Language: Language.Label,
            M_UserID: UserID ? UserID : '0',
        }
        dispatch(EmployeeDDLAPI({ data }))
    }, [DesignationDDLID.ID, Language.Label])

    const { DDLData } = useSelector(state => state.EmployeeDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setEmployeeDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.m_EmployeeID : 0,
                Label: Flag == 'Update' ? data?.employeeName : "Select...",
            })
        }
        else {
            setEmployeeDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <Select
                id='EmployeeID'
                isClearable={true}
                isSearchable
                maxMenuHeight={130}
                value={{ value: EmployeeDDLID.ID, label: EmployeeDDLID.Label }}
                onChange={(e) => {
                    e ?
                        setEmployeeDDLID({ ...EmployeeDDLID, ID: e.value, Label: e.label })
                        :
                        setEmployeeDDLID({ ...EmployeeDDLID, ID: 0, Label: "Select..." })

                }}
                options={EmployeeDDLID.DDL}
            />
        </div>
    )
}