import { useNavigate } from "react-router-dom"
import { RegistrationHeader } from "../RegistrationHeader/RegistrationHeader";
import { Footer } from "../Footer/Footer";
import SignUpPopup from "./SignUpPopup/SignUpPopup";
import React, { useState } from "react";
import { t } from "i18next";
import { GenderDDL } from "../../Components/CommonDDL/GenderDDL";
import { useDispatch } from "react-redux";
import { CitizenRegistrationPostAPI } from "../../Helper/Redux/Registration/RegistrationSlice";
import { RegExEmail, RegExMobile, RegExNumbersOnly, RegExOnlyText, RegExPassword } from "../../Helper/RegEx";
import { AstricSign } from "../../Helper/AstricSign";


export const WithRegistration = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [SignUpShowPopup, setSignUpShowPopup] = useState(false)

    const [CitizenField, setCitizenField] = useState({
        CitizenName: '',
        DateOfBirth: '',
        EmailAddress: '',
        MobileNo: '',
        Gender: '',
        Address: '',
        UserName: '',
        Password: '',
        ConfirmPassword: '',
    })

    const [GenderDDLID, setGenderDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: 'English'
    })

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("pass-eye");
        if (x.type === "password") {
            x.type = "text";
            y.className = "fa fa-eye-slash";
        } else {
            x.type = "password";
            y.className = "fa fa-eye";
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setCitizenField(item => ({
            ...item, [name]: value
        }))
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        onChange(e)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const [IsValidEmail, setIsValidEmail] = useState(false)
    const handleCheckEmail = (e) => {
        onChange(e)
        const IsValid = RegExEmail(e.target.value)
        setIsValidEmail(IsValid)
        return IsValid
    }

    const [IsValidNumber, setIsValidNumber] = useState(false)
    const handleCheckNumberOnly = (e) => {
        onChange(e)
        const IsValid = RegExNumbersOnly(e.target.value)
        setIsValidNumber(IsValid)
        return IsValid
    }

    const [IsValidPassword, setIsValidPassword] = useState(false)
    const handleCheckPassword = (e) => {
        onChange(e)
        const IsValid = RegExPassword(e.target.value)
        setIsValidPassword(IsValid)
        return IsValid
    }

    const handleIsPost = () => {
        setSignUpShowPopup(!SignUpShowPopup)
    }

    const handleRegister = () => {
        const data = {
            CitizenField: CitizenField,
            M_IndicatorGenderID: GenderDDLID.ID,
            M_UserID: '0',
            handleIsPost: handleIsPost,
        }
        dispatch(CitizenRegistrationPostAPI({ data }))
    }

    return (
        <>

            <div className="conatiner-fluid overflowx px-2 px-lg-4 py-2" onLoad={()=> window.scrollTo(0,0)}>

                <RegistrationHeader
                    Language={Language}
                    setLanguage={setLanguage}
                />

                <div className=" px-3 px-lg-1" style={{ paddingTop: '110px' }}>


                </div>

                <div className="row border shadow mt-4 mb-4 py-2 mx-1 mx-lg-0 px-1">
                    <div className="col-12 px-2 px-lg-5 border-bottom py-2">
                        <h5 className="text-black fw-bold pink-color">
                            {t('CitizenRegistration.CitizenRegistration')}
                        </h5>

                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.CitizenName')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('CitizenRegistration.CitizenName')}
                                    name='CitizenName'
                                    value={CitizenField.CitizenName}
                                    onChange={handleCheckText}
                                />
                                {
                                    IsValidText && <text className="RegxValidation text-danger">Please enter Alphabet characters only</text>
                                }
                            </div>

                        </div>
                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.DateOfBirth')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder={t('CitizenRegistration.DateOfBirth')}
                                    name='DateOfBirth'
                                    value={CitizenField.DateOfBirth}
                                    onChange={onChange}
                                />
                            </div>

                        </div>
                    </div>


                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.EmailAddress')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('CitizenRegistration.EmailAddress')}
                                    name='EmailAddress'
                                    value={CitizenField.EmailAddress}
                                    onChange={handleCheckEmail}
                                />
                                {
                                    IsValidEmail && <text className="RegxValidation">Please enter valid Email Address</text>
                                }
                            </div>

                        </div>
                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.MobileNo')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('CitizenRegistration.MobileNo')}
                                    name='MobileNo'
                                    value={CitizenField.MobileNo}
                                    onChange={handleCheckNumberOnly}
                                    maxLength={10}
                                />
                                 {
                                    IsValidNumber && <text className="RegxValidation">Please enter Numbers only</text>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.Gender')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <GenderDDL
                                    GenderDDLID={GenderDDLID}
                                    setGenderDDLID={setGenderDDLID}
                                    Language={Language}
                                    GroupID='2'
                                />
                            </div>

                        </div>
                    </div>


                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.Address')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                {/* <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="eg. 103, A2, Omkar Nandan, Navale Bridge, Opp.Navale IT Park, Vadgaon Bk. Pune 411041" rows="3"></textarea> */}
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder={t('CitizenRegistration.Address')}
                                    name='Address'
                                    value={CitizenField.Address}
                                    onChange={onChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.UserName')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('CitizenRegistration.UserName')}
                                    name='UserName'
                                    value={CitizenField.UserName}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.Password')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <div className="input-group ">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('CitizenRegistration.Password')}
                                        name='Password'
                                        value={CitizenField.Password}
                                        onChange={handleCheckPassword}
                                    />
                                    {
                                        IsValidPassword &&
                                        <div className="text-danger">
                                            <text >Please enter Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character.</text>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 py-2 my-1">
                        <div className="row px-lg-5">
                            <div className="col-12 mx-auto col-lg-12 pt-1 text-start py-2">
                                <span className="fw-bold text-dark">
                                    {t('CitizenRegistration.ConfirmPassword')}
                                </span> <AstricSign/>
                            </div>
                            <div className="col-12 mx-auto col-lg-12">
                                <div className="input-group">
                                    <input
                                        id='pass'
                                        type="text"
                                        className="form-control"
                                        placeholder={t('CitizenRegistration.ConfirmPassword')}
                                        name='ConfirmPassword'
                                        value={CitizenField.ConfirmPassword}
                                        onChange={onChange}
                                    />
                                    <span
                                        className="input-group-text cursor-pointer"
                                        id="addon-wrapping"
                                        onClick={showPass}
                                    >
                                        <i id="pass-eye" className="fa-solid fa-eye"></i>
                                    </span>

                                </div>
                                {
                                    (CitizenField.Password != CitizenField.ConfirmPassword) && CitizenField.ConfirmPassword != '' &&
                                    <span className="RegxValidation">
                                        <text >Password Mismatch</text>
                                    </span>
                                }
                            </div>

                        </div>
                    </div>

                    <div className="col-12 py-2 my-1 d-flex justify-content-center">
                        <button type="button"
                            className="btn bg-success text-white"
                            disabled={CitizenField.CitizenName == '' || CitizenField.DateOfBirth == '' || CitizenField.EmailAddress == '' || (CitizenField.MobileNo.length == 10) == ''||
                             GenderDDLID.ID == '0' || CitizenField.Address == '' || CitizenField.UserName == '' || CitizenField.Password == '' || CitizenField.ConfirmPassword == '' || (CitizenField.Password != CitizenField.ConfirmPassword) }
                            onClick={handleRegister}
                        >
                            {t('CitizenRegistration.Register')}
                        </button>
                        <button type="button"
                            className="btn bg-danger text-white mx-2"
                            onClick={() => navigate('/')}
                        >
                            {t('CitizenRegistration.Cancel')}
                        </button>
                    </div>
                </div>
                <Footer />
            </div>
            {
                SignUpShowPopup ?
                    <SignUpPopup
                        setSignUpShowPopup={setSignUpShowPopup}
                        CitizenField={CitizenField}
                    />
                    : ''
            }
        </>
    )
}