import { t } from "i18next"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Rating } from "react-simple-star-rating"
import { useAuthState } from "../../../../../Helper/Context/context"

export default function (props) {
    const { handleFeedbackConfirmation, message, Title, handleFeedback, Feedback, setFeedback, Flag, ModalData, ScreenName1 } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [rating, setRating] = useState(0)

    // const [Feedback, setFeedback] = useState(Flag == 'ViewFeedBackPopUp' ? ModalData.RowData.feedback : '')

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
    }

    const handleReset = () => {
        // Set the initial value
        setRating(0)
    }
    // console.log('Feedbackdvds',Flag)

    return (
        <>
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className="mt-2 ml-4">
                                <h5 className='  text-center login_text '>
                                    {t('GrievanceStatus.Feedback')}
                                </h5>
                            </div>
                            <div className='mt-1'>
                                {/* <ExportOfficeLetterSecondPopUpCOMM /> */}
                            </div>

                            <button type="button" className="btn-close mr-3 mt-2 " data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={handleFeedbackConfirmation}
                            >

                            </button>
                        </div>
                        {
                            ScreenName1 != "GrivancesStatus" ?
                                <>
                                    <div className="col-10 mx-auto modal-body text-center py-4" >
                                        <div className="form-group">
                                            <textarea className="form-control"
                                                type='text'
                                                rows="5"
                                                value={ModalData.RowData.feedback ? ModalData.RowData.feedback : '-'}
                                            ></textarea>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-10 mx-auto modal-body text-center py-4" >
                                        <div className="form-group">
                                            <textarea className="form-control"
                                                id="Feedback"
                                                placeholder="Type Here...."
                                                type='text'
                                                rows="5"
                                                value={Feedback ? Feedback : ModalData.RowData.feedback}
                                                onChange={(e) => setFeedback(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                </>
                        }

                        {
                            ScreenName1 != "GrivancesStatus" ?
                                <></>
                                :
                                <>
                                    <div className="row text-center">
                                        <div className="col-lg-10 mx-auto mb-4" >
                                            <Rating onClick={handleRating} initialValue={rating} />
                                            <button className="btn bg-danger text-white px-2 ms-4" onClick={handleReset}>
                                                {t('GrievanceStatus.Reset')}
                                            </button>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" onClick={() => handleFeedback()} className="btn bg-success text-white" data-bs-dismiss="modal">
                                            {t('GrievanceStatus.Submit')}
                                        </button>
                                    </div>
                                </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}