import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess, toastUnauthorized } from "../../AlertMessage/AlertMessage";

export const ReceivedGrievanceGetAPI = createAsyncThunk("ReceivedGrievanceGet", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        GrievanceDate,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_TalukaID,
        M_IndicatorGrievanceTypeID,
        M_StatusID,
        ShowBy,
        Flag,
        Language,
        FromTop,
        ToTop,
        M_UserID,
        token,
        handleGetExport
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Dashboard/Get_DB_T_GrievanceRegistration_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID =${M_MonthID}&GrievanceDate=${GrievanceDate}&M_IndicatorGrievanceAreaID=${M_IndicatorGrievanceAreaID}&M_DistrictID=${M_DistrictID}&M_TalukaID=${M_TalukaID}&M_IndicatorGrievanceTypeID=${M_IndicatorGrievanceTypeID}&M_StatusID=${M_StatusID}&ShowBy=${ShowBy}&Flag=${Flag}&Language=${Language}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetExport && handleGetExport()
                return result.data
            } else {
                return result
            }
        })
})

const ReceivedGrievanceGetSlice = createSlice({
    name: "ReceivedGrievanceGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceivedGrievanceGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ReceivedGrievanceGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(ReceivedGrievanceGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const ReceivedGrievanceGetReducer = ReceivedGrievanceGetSlice.reducer

export const ReceivedGrievanceExportGetAPI = createAsyncThunk("ReceivedGrievanceExportGet", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        GrievanceDate,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_TalukaID,
        M_IndicatorGrievanceTypeID,
        M_StatusID,
        ShowBy,
        Flag,
        Language,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Dashboard/Get_DB_T_GrievanceRegistration_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID =${M_MonthID}&GrievanceDate=${GrievanceDate}&M_IndicatorGrievanceAreaID=${M_IndicatorGrievanceAreaID}&M_DistrictID=${M_DistrictID}&M_TalukaID=${M_TalukaID}&M_IndicatorGrievanceTypeID=${M_IndicatorGrievanceTypeID}&M_StatusID=${M_StatusID}&ShowBy=${ShowBy}&Flag=${Flag}&Language=${Language}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ReceivedGrievanceExportGetSlice = createSlice({
    name: "ReceivedGrievanceExportGet",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceivedGrievanceExportGetAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ReceivedGrievanceExportGetAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(ReceivedGrievanceExportGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const ReceivedGrievanceExportGetReducer = ReceivedGrievanceExportGetSlice.reducer

//Insert API

export const ReceivedGrievanceAcknowledgePostAPI = createAsyncThunk("ReceivedGrievanceAcknowledgePost", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        Remark,
        Flag,
        M_UserID,
        token,
        handleShowConfirmation,
        handleIsPost,
        screenname,
        setIsSubmitting,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();

    if (screenname == "Acknowledge Grievance") {
        formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
        formdata.append("EscalatedRemark", Remark);
        formdata.append("Flag", Flag);
        formdata.append("M_UserID", M_UserID);

        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(`${BaseUrl}/Dashboard/Patch_DB_T_GrievanceRegistrationEscalated_Action`, requestOptions)
            .then((res) => res.json())
            .then((result) => {
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    setIsSubmitting && setIsSubmitting(false)
                    handleShowConfirmation()
                    toastSuccess(result.message)
                    handleIsPost()
                    return result.data
                } else {
                    return result
                }
            })
    } else {
        formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
        formdata.append("Remark", Remark);
        formdata.append("Flag", Flag);
        formdata.append("M_UserID", M_UserID);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(`${BaseUrl}/Dashboard/Post_DB_T_GrievanceTransfer_Action`, requestOptions)
            .then((res) => res.json())
            .then((result) => {
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    handleShowConfirmation()
                    toastSuccess(result.message)
                    handleIsPost()
                    return result.data
                } else {
                    return result
                }
            })
    }


})

const ReceivedGrievanceAcknowledgePostSlice = createSlice({
    name: "ReceivedGrievanceAcknowledgePost",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceivedGrievanceAcknowledgePostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(ReceivedGrievanceAcknowledgePostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(ReceivedGrievanceAcknowledgePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const ReceivedGrievanceAcknowledgePostReducer = ReceivedGrievanceAcknowledgePostSlice.reducer


//Forward API 

export const ForwardPostAPI = createAsyncThunk("ForwardPost", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        Remark,
        M_DistrictID,
        To_M_UserID,
        From_M_UserID,
        M_UserID,
        token,
        handleIsPost,
        setIsSubmitting
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();

    formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
    formdata.append("M_DistrictID", M_DistrictID);
    formdata.append("To_M_UserID", To_M_UserID);
    formdata.append("From_M_UserID", From_M_UserID);
    formdata.append("Remark", Remark);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Dashboard/Patch_T_GrievanceRegistration_Forward`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setIsSubmitting && setIsSubmitting()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                toastError('Something went wrong!')
                return result
            }
        })
})

const ForwardPostSlice = createSlice({
    name: "ForwardPost",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ForwardPostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(ForwardPostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(ForwardPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const ForwardPostReducer = ForwardPostSlice.reducer



//Upload Photo Patch API

export const GrievanceRegistrationWiseReportActionPatchAPI = createAsyncThunk("GrievanceRegistrationWiseReportActionPatch", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        ActionReport,
        ActionRemark,
        M_UserID,
        token,
        handleShowConfirmation,
        handleIsPost,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
    formdata.append("ActionReport", ActionReport)
    formdata.append("ActionRemark", ActionRemark);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Dashboard/Patch_DB_T_GrievanceRegistrationWiseReport_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowConfirmation()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const GrievanceRegistrationWiseReportActionPatchSlice = createSlice({
    name: "GrievanceRegistrationWiseReportActionPatch",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GrievanceRegistrationWiseReportActionPatchAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(GrievanceRegistrationWiseReportActionPatchAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(GrievanceRegistrationWiseReportActionPatchAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const GrievanceRegistrationWiseReportActionPatchReducer = GrievanceRegistrationWiseReportActionPatchSlice.reducer

export const SiteVerificationUploadImagePatchAPI = createAsyncThunk("SiteVerificationUploadImagePatch", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        VisitPhoto,
        VisitRemark,
        M_UserID,
        token,
        handleShowConfirmation,
        handleIsPost,
        screenname,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();


    formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
    formdata.append("VisitPhoto", VisitPhoto)
    formdata.append("VisitRemark", VisitRemark);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Dashboard/Patch_DB_T_GrievanceRegistrationWiseSiteVisit_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowConfirmation()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })

})

const SiteVerificationUploadImagePatchSlice = createSlice({
    name: "SiteVerificationUploadImagePatch",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SiteVerificationUploadImagePatchAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(SiteVerificationUploadImagePatchAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(SiteVerificationUploadImagePatchAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const SiteVerificationUploadImagePatchReducer = SiteVerificationUploadImagePatchSlice.reducer


