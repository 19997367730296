
const getBase64 = (file) => {

    return new Promise((resolve,reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror=()=>{
            reject("error...")
        }
    });
};

export default getBase64

export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result.split(',')[1];
            // setDocuments1(reader.result.split(',')[1])

            resolve(base64data);
        }
    });
}

// export const convertAfterImageBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//         const fileReader = new FileReader
//         fileReader.readAsDataURL(file)

//         fileReader.onload = () => {
//             resolve(fileReader.result)
//             // setafterDocument(fileReader.result.split(",")[1])
//         }

//         fileReader.onerror = (error) => {
//             reject(error)
//         }

//         // if (file.size >= "16000000") {
//         //     setafterImageSizeError("Please upload a file smaller than 2 MB")
//         // }

//     })
// }
