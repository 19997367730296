import { t } from "i18next"
import FeedbackPopup from "./FeedbackPopup/FeedbackPopup"
import React, { useEffect, useState } from "react"
import GrievanceStatusPopup from "./GrievanceStatusPopup/GrievanceStatusPopup"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../Helper/Context/context"
import { GrievanceStatusFeedbackPatchAPI, GrievanceStatusGetAPI } from "../../../../Helper/Redux/CitizenRegistrationSlice/GrievanceStatusSlice"
import moment from "moment"
import TrackPopup from "./TrackPopup/TrackPopup"
import { useSearchParams } from "react-router-dom"

export const Resolved = ({Language}) => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [ShowConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)
    const [FeedBackShowPopup, setFeedBackShowPopup] = React.useState(false)
    const [GrievanceStatusShowPopup, setGrievanceStatusShowPopup] = React.useState(false)
    const [TrackShowPopup, setTrackShowPopup] = React.useState(false)

    const [ShowModal, setShowModal] = useState(false)
    const [ShowDeletePopup, setShowDeletePopup] = useState(false)
    const [ShowDeletePopupData, setShowDeletePopupData] = useState('')
    const [ReceivedDate, setReceivedDate] = useState('')
    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [From, setFrom] = useState(1)

    const [isopen, setIsOpen] = useState(false);

    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    

    const [Feedback, setFeedback] = useState(Flag == 'ViewFeedBackPopUp' ? ModalData.RowData.feedback : '')

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: sessionStorage.getItem('LanguageChange')
    // })

    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: ''
    // })

    // useEffect(() => {
    //     setLanguage({
    //         ...Language,
    //         DDL: [],
    //         ID: 0,
    //         Label: sessionStorage.getItem('LanguageChange'),
    //     })
    // }, [])


    const handleShowView = (rowData, t_GrievanceRegistrationID, Heading) => {
        // console.log("dgdfg",t_GrievanceRegistrationID)
        setGrievanceStatusShowPopup(!GrievanceStatusShowPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID,
            Heading: Heading
        })
    }

    const handleFeedbackConfirmation = (rowData) => {
        setFeedBackShowPopup(!FeedBackShowPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            // heading: heading

        })
    }
    const handleTrackDetails = (rowData, t_GrievanceRegistrationID) => {
        setTrackShowPopup(!TrackShowPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID
            // heading: heading

        })
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {
            GrievanceDate: "",
            M_IndicatorGrievanceTypeID: 0,
            M_StatusID: 0,
            Flag: "GrievanceStatus",
            // Language: "English",
            M_UserID: UserID,
            FromTop: From,
            ToTop: '9999',
            token: token,
            Language: Language.Label,
        }
        { Language.Label != '' && dispatch(GrievanceStatusGetAPI({ data })) }

    }, [IsSearch, IsPost, From, Language.Label])

    const { TableData, isLoading } = useSelector(state => state.GrievanceStatusGetData)

    // console.log('first',Language.Label)

    const handleFeedback = () => {
        // console.log('Feedback',feedback)
        const data = {
            T_GrievanceRegistrationID: ModalData.RowData.t_GrievanceRegistrationID,
            Feedback: Feedback,
            token: token,
            M_UserID: UserID,
            handleFeedbackConfirmation: handleFeedbackConfirmation,
            handleIsPost: handleIsPost,

        }
        dispatch(GrievanceStatusFeedbackPatchAPI({ data }))
    }

    const { isPostLoading } = useSelector(state => state.GrievanceStatusFeedbackPatchData)
    
    return (
        <>
            <div className="col-12">
                <h6 className="fw-bold text-danger">
                    {t('DirectorDashboard.PendingGrievance')}

                </h6>
            </div>
            {/* <div className="col-12 col-lg-6  ">
                <div className="p-4 shadow  border bg-white  pb-5">
                    <div className="">
                        <span className=" resolved">
                            {t('GrievanceStatus.Resolved')}
                        </span>
                    </div>

                    <div className="mb-1 border-bottom">
                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                            {t('GrievanceStatus.GrievanceDetails')}:
                        </label>
                        <p> Grievance Details Here........</p>
                    </div>


                    <div className="mb-1 border-bottom">
                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                            {t('GrievanceStatus.GrievanceArea')}:
                        </label>
                        <p> Grievance Area Here........</p>
                    </div>

                    <div className="mb-1 border-bottom">
                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                            {t('GrievanceStatus.HospitalDetails')}:
                        </label>
                        <p> Hospital Details Here........</p>
                    </div>

                    <div className="mb-1 border-bottom">
                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                            {t('GrievanceStatus.DoctorName')}:
                        </label>
                        <p> Doctor Name Here........</p>
                    </div>

                    <div className="mb-1 border-bottom">
                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                            {t('GrievanceStatus.PatientName')}:
                        </label>
                        <p> Patient Name Here........</p>
                    </div>

                    <div className="py-2">
                       <span className="feedback"
                            onClick={() => setFeedBackShowPopup(true)}
                        >
                            {t('GrievanceStatus.Feedback')}
                        </span> 
                    </div>
                </div>
            </div> */}

            {

                TableData && TableData.length > 0 ?
                    TableData.map((i, index) => {
                        return (
                            i.m_StatusID != 7 ?
                                <div className="col-12 col-lg-3 pt-2 pt-lg-3">
                                    <div className="shadow  border bg-white py-2 pb-4 px-2">
                                        <span className={`Status-${i.m_StatusID}`}>
                                            {/* {t('GrievanceStatus.Resolved')} */}
                                            {i?.statusName}
                                            {/* <i class="fa-solid fa-check ps-2"></i> */}
                                            <i class="fa-solid fa-star ps-2"></i>
                                        </span>

                                        <span
                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID, "Pending")} className="cursor-pointer">
                                            <div className="border-bottom">
                                                <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                    {t('DirectorDashboard.GrievanceDate')}
                                                    {/* Grievance Date */}
                                                </label>
                                                <p>{i.transactionDate ? moment(i.transactionDate).format('DD-MM-YYYY') : '-'}</p>
                                            </div>
                                            <div className="border-bottom mb-1">
                                                <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                    {/* {t('GrievanceStatus.GrievanceDetails')}: */}
                                                    {t('DirectorDashboard.GrievanceType')}
                                                </label>
                                                <p>{i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</p>
                                            </div>
                                        </span>

                                        <div>
                                            <span className=" track_feedback cursor-pointer"
                                                onClick={() => handleTrackDetails(i, i.t_GrievanceRegistrationID)}>
                                                {/* {t('GrievanceStatus.Resolved')} */}
                                                {t('GrievanceStatus.Track')}
                                                {/* <i class="fa-solid fa-check ps-2"></i> */}
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                        )
                    })
                    : <></>
            }

            <div className="col-12 ">
                <hr className="mt-4" style={{ background: "black" }} />
                <h6 className="fw-bold text-success">
                    {t('DirectorDashboard.ReslovedGrievance')}
                </h6>
            </div>

            {

                TableData && TableData.length > 0 ?
                    TableData.map((i, index) => {
                        return (
                            i.m_StatusID == 7 ?
                                <div className="col-12 col-lg-3 pt-2 pt-lg-3">
                                    <div className="shadow  border bg-white py-2 pb-4 px-2">
                                        <span className={`Status-${i.m_StatusID}`}>
                                            {/* {t('GrievanceStatus.Resolved')} */}
                                            {i?.statusName}
                                            {/* <i class="fa-solid fa-check ps-2"></i> */}
                                            <i class="fa-solid fa-star ps-2"></i>
                                        </span>

                                        <span
                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID, "Resolved")} className="cursor-pointer">
                                            <div className="border-bottom">
                                                <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                    {t('DirectorDashboard.GrievanceDate')}
                                                    {/* Grievance Date */}
                                                </label>
                                                <p>{i.transactionDate ? moment(i.transactionDate).format('DD-MM-YYYY') : '-'}</p>
                                            </div>
                                            <div className="border-bottom mb-1">
                                                <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                    {t('DirectorDashboard.GrievanceType')}
                                                    {/* Grievance Type */}
                                                </label>
                                                <p>{i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</p>
                                            </div>
                                        </span>

                                        <div>
                                            <span className=" track_feedback cursor-pointer"
                                                onClick={() => handleFeedbackConfirmation(i)}>
                                                {/* {t('GrievanceStatus.Resolved')} */}
                                                {t('GrievanceStatus.Feedback')}
                                                {/* <i class="fa-solid fa-check ps-2"></i> */}
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                :
                                ''

                        )
                    })
                    : <></>
            }
            {
                FeedBackShowPopup ?
                    <FeedbackPopup
                        handleFeedbackConfirmation={handleFeedbackConfirmation}
                        setFeedBackShowPopup={setFeedBackShowPopup}
                        handleFeedback={handleFeedback}
                        Feedback={Feedback}
                        setFeedback={setFeedback}
                        Flag='ViewFeedBackPopUp'
                        ModalData={ModalData}
                        ScreenName1='GrivancesStatus'

                    />
                    : ''
            }

            {
                TrackShowPopup ?
                    <TrackPopup
                        handleTrackDetails={handleTrackDetails}
                        setTrackShowPopup={setTrackShowPopup}
                        ModalData={ModalData}
                    // ImagePopUpData={ImagePopUpData}
                    />
                    : ''
            }

            {
                GrievanceStatusShowPopup ?
                    <GrievanceStatusPopup
                        // Heading={Heading}
                        setGrievanceStatusShowPopup={setGrievanceStatusShowPopup}
                        Title={`${ModalData.Heading}`}
                        handleShowView={handleShowView}
                        ModalData={ModalData}
                    />
                    : ''
            }



        </>


    )
}