
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { DistrictDDLAPI, GenderDDLAPI, TalukaDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const TalukaDDL = (props) => {
    const { TalukaDDLID, setTalukaDDLID, Flag, data, Language, M_DistrictID, M_StateID, UserID } = props

    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_StateID: M_StateID,
            M_DistrictID: M_DistrictID,
            Language: Language.Label,
            M_UserID: UserID ? UserID : '0',
        }
        dispatch(TalukaDDLAPI({ data }))
    }, [Language.Label, M_DistrictID])

    const { DDLData } = useSelector(state => state.TalukaDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setTalukaDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.valueID : 0,
                Label: Flag == 'Update' ? data?.valueParam : "Select...",
            })
        }
        else {
            setTalukaDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <Select
                id='TalukaID'
                isClearable={false}
                isSearchable
                maxMenuHeight={150}
                value={{ value: TalukaDDLID.ID, label: TalukaDDLID.Label }}
                onChange={(e) => {
                    e ?
                        setTalukaDDLID({ ...TalukaDDLID, ID: e.value, Label: e.label })
                        :
                        setTalukaDDLID({ ...TalukaDDLID, ID: 0, Label: "Select..." })

                }}
                options={TalukaDDLID.DDL}
            />
        </div>
    )
}