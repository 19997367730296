import { useNavigate } from "react-router-dom"
import { LanguageDDL } from "../../../Components/CommonDDL/LanguageDDL"
import { logout } from "../../../Helper/Context/action";
import { useDispatch } from "react-redux";
import { useAuthState } from "../../../Helper/Context/context";
import { FinancialYearDDL } from "../../../Components/CommonDDL/FinancialYearDDL";
import { MonthDDL } from "../../../Components/CommonDDL/MonthDDL";

export const Header = (props) => {
    const { Language, setLanguage, DDLFinantialYearID, setDDLFinantialYearID, DDLMonthID, setDDLMonthID, name } = props

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { EmployeeName } = userDetails

    function togglepc() {
        let side = document.getElementById("sidebar")
        side.classList.toggle("active")
    }

    function toggle() {
        let side = document.getElementById("myDIV")
        side.classList.toggle("hidden")
        side.classList.toggle("visible")

    }

    function toggleuser() {
        let side = document.getElementById("userdata")
        side.classList.toggle("hidden1")
        side.classList.toggle("visible")

    }

    const LogOut = () => {
        logout(dispatch)
        navigate('/')
    }

    return (
        <>
            {/* <!-- Topbar --> */}
            <span className="text-dark poppins heading_text pt-2  d-md-none d-block text-start ps-3 ps-lg-0">Public Grievance In Aamchi Mulgi Scheme</span>
            <nav className="navbar py-4 mb-3 mb-md-0 navbar-expand navbar-light bg-white topbar shadow  static-top rounded ">

                <span className="">
                    <img src="assets/img/Logo/beti1-01.png" className='logo_size_Wregi ' alt="" />
                </span>

                {/* <!-- Sidebar Toggle (Topbar) --> */}
                <div className="mt-1 pt-1 pc_none ps-4">
                    <span className='hamburger text-dark hamburgersize  pointer pt-5 mt-5' >
                        <i className="fa-solid fa-bars pt-1 text-danger  " style={{ fontSize: '20px' }} onClick={toggle}  >  </i>
                    </span>
                </div>

                <div className=" px-4  mobile_none">
                    <span className='hamburger text-dark hamburgersize  pointer pt-5 mt-5' >
                        <i className="fa-solid fa-bars pt-1 text-danger  " style={{ fontSize: '20px' }} onClick={togglepc}  >  </i>
                    </span>
                </div>
                {/* 
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button> */}

                {/* <!-- Topbar Search --> */}

                <h4 className="text-dark poppins heading_text pt-1 ps-3 ps-lg-0 d-none d-md-block">Public Grievance In Aamchi Mulgi Scheme</h4>

                {/* <!-- Topbar Navbar --> */}
                <div className="row ml-auto">

                    {
                        name == 'Dashboard' &&
                        <div className='col-3 '>
                            <FinancialYearDDL
                                DDLFinantialYearID={DDLFinantialYearID}
                                setDDLFinantialYearID={setDDLFinantialYearID}
                            // Flag='Edit'
                            />
                        </div>
                    }
                    {
                        name == 'Dashboard' &&
                        <>
                            <div className=' col-5 '>
                                <MonthDDL
                                    DDLMonthID={DDLMonthID}
                                    setDDLMonthID={setDDLMonthID}
                                // Flag='Edit'
                                />
                            </div>
                        </>

                    }
                    <div className="col-4" >
                        <LanguageDDL
                            Language={Language}
                            setLanguage={setLanguage}
                        />
                    </div>

                    {/* <div className="col-6">
                        <select className="form-control dark" id="filterYear">
                            <option defaultValue>Years</option>
                            <option>2021-22</option>
                            <option>2020-21</option>
                            <option>2019-20</option>
                            <option>2018-19</option>
                            <option>2017-18</option>
                            <option>2016-17</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <select className="form-control dark " id="filterYear">
                            <option defaultValue>Month</option>
                            <option>Jan</option>
                            <option>Feb</option>
                            <option>March</option>

                        </select>
                    </div> */}
                </div>




                <ul className="navbar-nav ml-lg-3">
                    {/* <!-- Dropdown - Messages --> */}

                    <div className="dropdown-menu dropdown-menu-right  shadow animated--grow-in"
                        aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small"
                                    placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <li className=" dropdown no-arrow ms-1 ms-lg-0" id="userDropdown">
                        <a className="nav-link dropdown-toggle " onClick={toggleuser} href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <span className="mr-2 d-none d-lg-inline heading">
                            </span> */}
                            <img className="img-profile rounded-circle" style={{ width: "30px" }} src="assets/img/profile.png" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right mx-auto shadow " id="userdata"
                        >
                            <a className="dropdown-item" href="#">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400 me-2"></i>
                                {/* akash */}
                                {EmployeeName}
                            </a>

                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" data-toggle="modal" data-target="#logoutModal"
                                onClick={LogOut}
                            >
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400 me-2"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
            {/* <!-- End of Topbar --> */}
        </>
    )
}