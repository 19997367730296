
import { useNavigate } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"
import { SideBar } from "../SideBar/SideBar"
import { MainDashboardContent } from "./MainDashboardContent/MainDashboardContent"
import { useEffect, useState } from "react"
import { t } from "i18next"
import { DashMainCountDataAPI } from "../../../Helper/Redux/DashboardSlice/DashboardMainCountSlice"
import { Loading } from "../../../Helper/Loader/LogoLoader"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../Helper/Context/context"


export const MainDashboard = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const navigate = useNavigate();
    const [DDLFinantialYearID, setDDLFinantialYearID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Year'
    })
    const [DDLMonthID, setDDLMonthID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Month'
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })

    const [Countdata, setCountdata] = useState({})

    const handleGetCount = (data) => {
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID.ID,
            M_MonthID: DDLMonthID.ID,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            handleGetCount: handleGetCount
        }

        if (DDLFinantialYearID.ID != '0') {
            dispatch(DashMainCountDataAPI({ data }))
        }
    }, [DDLFinantialYearID.ID, DDLMonthID.ID])


    const { Data, isLoading } = useSelector(state => state.DashMainCountGetData)

    return (
        <>
            {isLoading && <Loading />}
            <Header
                Language={Language}
                setLanguage={setLanguage}
                DDLFinantialYearID={DDLFinantialYearID}
                setDDLFinantialYearID={setDDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                setDDLMonthID={setDDLMonthID}
                name='Dashboard'
            />
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="MainDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >
                    <div className="row ">

                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="text-black fw-bold ps-2 pt-1 mb-2">
                                {t('DirectorDashboard.Dashboard')}
                            </span>
                            {/* <div class="btn-group  " role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
                                <label class="btn btn-outline-danger parrot_green_border text-black RADIUS_LEFT" for="btnradio1"
                                    onClick={() => navigate('/DirectorDashboard')}
                                >
                                    Add Director
                                </label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked />
                                <label class="btn btn-outline-danger parrot_green" for="btnradio2"
                                    onClick={() => navigate('/MainDashboard')}>
                                    MOH | CS
                                </label>
                            </div> */}
                        </div>
                    </div>

                    

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '1px solid #dddcdc' }}>
                        <MainDashboardContent
                            DDLFinantialYearID={DDLFinantialYearID}
                            DDLMonthID={DDLMonthID}
                            Countdata={Countdata}
                        />
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )
}