
import { AllRoutes } from './AllRoutes';
import { I18nextProvider } from 'react-i18next';
import i18n from './Helper/Langauges/LangMain';
import store from './Helper/Redux/store';
import { AuthProvider } from './Helper/Context/context';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import ReactPlayer from 'react-player';

function App() {
  return (
    <>

      <Toaster
         position="top-right"
        toastOptions={{
          success: {
            style: {
              background: 'green',
              color: 'white',
              fontSize: "13px"
            },
          },
          error: {
            style: {
              background: 'red',
              color: 'white',
              fontSize: "13px"
            },
          },
        }}
      />
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <AuthProvider>     
            <AllRoutes />
          </AuthProvider>
        </Provider>
      </I18nextProvider>
    </>
  );
}

export default App;
