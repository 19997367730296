import { t } from "i18next"
import { GenderDDL } from "../../../Components/CommonDDL/GenderDDL"
import { AstricSign } from "../../../Helper/AstricSign"

export const GrievanceDetails = (props) => {

    const {
        Language,
        GrievanceField,
        onChange,
        GrievanceTypeID,
        setGrievanceTypeID,
        UserID
    } = props

    return (
        <>
            <div className="row border bg-white shadow mt-4 mb-4 py-2 px-1 px-lg-3 mx-1 mx-lg-0">
                <div className="col-12 border-bottom py-2">
                    <h6 className="fw-bold text_color">
                        {t('GrievanceRegistration.GrievanceDetails')}
                    </h6>
                </div>
                <div className="col-12 col-lg-4 py-2 my-2">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.Grievance')}
                            </span><AstricSign/>
                        </div>
                        <div className="col-12  mx-auto col-lg-12">
                            <GenderDDL
                                GenderDDLID={GrievanceTypeID}
                                setGenderDDLID={setGrievanceTypeID}
                                Language={Language}
                                GroupID='3'
                                UserID={UserID}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 py-2 my-2">
                    <div className="row ">
                        <div className="col-12  mx-auto col-lg-12 pt-1 text-start py-2 ">
                            <span className="fw-bold text-dark">
                                {t('GrievanceRegistration.GrievanceDetails')}
                            </span><AstricSign/> 
                        </div>
                        
                        <div className="col-12  mx-auto col-lg-12">
                        <textarea
                         type="text"
                         className="form-control"
                         placeholder={t('GrievanceRegistration.GrievanceDetails')}
                         name='GrievanceDetails'
                         value={GrievanceField.GrievanceDetails}
                         onChange={onChange}>

                        </textarea>
                          
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}