import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom"

import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useAuthState } from "../../../../../../../Helper/Context/context";
import { CommonData, Municipal } from "../../../../../../../Helper/ChartDeta";
import { useDispatch, useSelector } from "react-redux";
import { ChartCountAPI } from "../../../../../../../Helper/Redux/DirectorDashboardSlice/DirectorDashboardMainCountSlice";

export const CommonBarChart = (props) => {

    const { DDLFinantialYearID, DDLMonthID, handleShowView, Title, Flag } = props
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails


    const [ChartCountdata, setChartCountdata] = useState({})
    const [ChartLableName, setChartLableName] = useState([])
    const [PendingColor, setPendingColor] = React.useState()

    var PendingColor1 = []



    const handleGetCount = (data) => {
        let tempData = {}
        let lableData = []

        data.forEach((item) => {
            tempData[item.indicatorName] = item.totalCount
            lableData[item.indicatorName] = item.indicatorName

        })
        setChartCountdata(tempData)
        setChartLableName(lableData)

        console.log("iji", lableData)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID.ID,
            M_MonthID: DDLMonthID.ID,
            M_UserID: UserID,
            Flag: Flag,
            token: token,
            handleGetCount: handleGetCount
        }

        if (DDLFinantialYearID.ID != '0' && DDLMonthID.ID != '0') {
            dispatch(ChartCountAPI({ data }))
        }
    }, [DDLFinantialYearID.ID, DDLMonthID.ID])


    const { Data, isLoading } = useSelector(state => state.ChartCountCountGetData)

    // console.log("ChartLableName", Data)

    const data = {

        // labels: ['Received Grievance', 'Acknowledge Grievance', 'Resolved Grievance', 'Pending Grievance'],ResolvedGrievanceStateNodal_MCTotalCount
        // labels: [ChartLableName],
        labels: Data && Data.map(i => i.indicatorName),
        datasets: [
            {
                label: ['Count '],
                fill: true,
                backgroundColor: PendingColor,
                // data: [WorkAssignedTotalCount.table[0].DeputyMunicipalCommissioner]
                // data: [ChartCountdata]
                data: Data && Data.map(i => i.totalCount)

            },
        ],
    };

    useEffect(() => {
        PieColorData()
    }, [Data])

    const PieColorData = () => {
        // console.log(Data)

        var dynamicColors = function () {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            // console.log("rgb(" + r + "," + g + "," + b + ")")
            PendingColor1.push("rgb(" + r + "," + g + "," + b + ")")
            return "rgb(" + r + "," + g + "," + b + ")";
        };
        setPendingColor(PendingColor1)

        for (var i = 0; i < (Data && Data.length); i++) {
            dynamicColors()
        }

    }

    return (
        <>

            <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
                <div className="modal-dialog modal-xl view_modal_size" role="document" style={{ width: '50vw' }} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">{Title}</h5>
                            <button onClick={handleShowView} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mx-lg-4">
                            <div className="row" style={{ height: '65vh', overflow: "auto" }}>

                                <div className=" px-3 bg-white rounded">

                                    <div className="col-lg-12 text-center">

                                        <>
                                            <Bar
                                                options={CommonData}
                                                data={data}
                                            />
                                            
                                        </>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}