export const English = {
    DirectorDashboard: {
        Dashboard: 'Dashboard',
        TotalReceivedGrievance: 'Total Received Grievances',
        AcknowledgeGrievance: 'Acknowledged Grievances',
        AcknowledgePending: 'Acknowledged Pending',
        CompletedSiteVisit: 'Completed Site Visit',
        PendingSiteVisit: 'Pending Site Visit',
        ReceivedGrievance: 'Received Grievances',
        ReceivedGrievanceByStateNodalOfficer: 'Received Grievances By State Nodal Officer',
        ReslovedGrievance: 'Resolved Grievances',
        PendingGrievance: 'Pending Grievances',
        FeedbackGivenbyRegisterCitizen: 'Feedback Given by Register Citizen',
        FeedbackGivenbyCitizen: 'Feedback Given by Citizen',
        FeedbackPending: 'Feedback Pending',
        EscalatedGrievancesToStateNodalOfficer: 'Escalated Grievances To State Nodal Officer',
        EscalatedGrievances: 'Escalated Grievances',
        EscalatedResolvedGrievances: 'Escalated Resolved Grievances',
        AcknowledgeDate: 'Acknowledge Date',
        Grievance: 'Grievance Type',
        ReceivedDate: 'Received Date',
        DistrictName: 'District Name',
        TalukaName: 'Taluka Name',
        GrievanceType: 'Grievance Type',
        GrievanceDetails: 'Grievance Details',
        OfficerName: 'Officer Name',
        Action: "Action",
        MunicipalCorporation: 'Municipal Corporation',
        SiteVisitDate: 'Site Visit Date',
        Photo: 'Photo',
        ResolvedDate: 'Resolved Date',
        Report: 'Report',
        StateNodalUploadReport: 'State Nodal Upload Report',
        RuralArea: 'Rural Area',
        InMunicipalCorporation: "In Municipal Corporation",
        InRuralArea: "In Rural Area",
        FromMOHMunicipalCorporation: "From (MOH) Municipal Corporation",
        FromCSRuralArea: "From (CS) Rural Area",
        ActionReportUploadedforEscalatedGrievance: "Action Report Uploaded for Escalated Grievance",
        UploadedActionReport: "Uploaded Action Report",
        FeedbackbyRegisteredCitizen: " Feedback by Registered Citizen",
        EscalatedGrievanceDate: "Escalated Grievance Date",
        FeedbackDate: "Feedback Date",
        ResolvedGrievance: 'Resolved Grievance',
        EscalatedGrievancesDate: 'Escalated Grievances Date',
        Designation: 'Designation',
        EmployeeMaster: 'Employee Master',
        UserCreation: 'User Creation',
        GrievanceDate: "Grievance Date",
        SiteVerification: "Site Verification",
        Status: "Status",
        PendingDate:"Pending Date",
        ResovedDate:"Resoved Date",

    },
    CitizenRegistration: {
        PublicGrievanceInAamchiMulgiScheme: 'Public Grievance In Aamchi Mulgi Scheme',
        CitizenRegistration: 'Citizen Registration',
        CitizenName: 'Citizen Name',
        DateOfBirth: 'Date Of Birth',
        EmailAddress: 'Email Address',
        MobileNo: 'Mobile No',
        Gender: 'Gender',
        Address: 'Address',
        UserName: 'UserName',
        Password: 'Password',
        ConfirmPassword: 'Confirm Password',
        Register: 'Register',
        Cancel: 'Cancel',
    },

    GrievanceRegistration: {
        GrievanceRegistration: 'Grievance Registration',
        GrievanceAreaDetails: 'Grievance Area Details',
        GrievanceArea: 'Grievance Area',
        CenterName: 'Center Name',
        District: 'District',
        Taluka: 'Taluka',
        Village: 'Village',
        AreaName: 'Area Name',
        AreaAdress: 'Area Adress',
        HospitalDetails: 'Hospital Details',
        HospitalName: 'Hospital Name',
        HospitalAddress: 'Hospital Address',
        DoctorName: 'Doctor Name',
        DoctorAddress: 'Doctor Address',
        ReferredDoctorName: 'Referred Doctor Name',
        VanNumber: 'Van Number',
        PatientName: 'Patient Name',
        PatientAddress: 'Patient Address',
        GrievanceDetails: 'Grievance Details',
        Grievance: 'Grievance',
        GrievanceDetails: 'Grievance Details',
        Register: 'Register',
        Cancel: 'Cancel',
        Back: 'Back',
        Remark: 'Remark'
    },
    GrievanceStatus: {
        GrievanceStatus: 'Grievance Status',
        Resolved: 'Resolved',
        GrievanceDetails: 'Grievance Details',
        GrievanceArea: 'Grievance Area',
        HospitalDetails: 'Hospital Details',
        DoctorName: 'Doctor Name',
        PatientName: 'Patient Name',
        Feedback: 'Feedback',
        Pending: 'Pending',
        GrievanceDetails: 'Grievance Details',
        GrievanceArea: 'Grievanc Area',
        HospitalDetails: 'Hospital Details',
        DoctorName: 'Doctor Name',
        PatientName: 'Patient Name',
        Track: 'Track',
        ClickOnTrack: 'Click On Track',
        GrievanceSubmitted: 'Grievance Submitted',
        AssignedGrievance: 'Assigned Grievance',
        IfEscalatedThen: 'If Escalated Then',
        GrievanceResolved: 'Grievance Resolved',
        Submit: 'Submit',
        Reset: 'Reset',
    },
    GridHeading: {
        Sr_No: "Sr.No",
        ComplaintID:"Complaint ID",
        Date:"Date",
        Action: "Action",
        Employee: 'Employee',
        Role: 'Role',
        Area: 'Area',
        District: 'District',
        UserName: 'UserName',
        Password: 'Password',
        GrievanceNo: 'Grievance.No.',
    }
}