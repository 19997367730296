
import toast from "react-hot-toast"


export const toastSuccess = (message) => {
  toast.success(message, {
    backgroundColor: 'green',
    color: '#ffffff',
    duration: 3000
  })
}

export const toastError = (message) => {
  toast.error(message, {
    backgroundColor: 'red',
    color: '#ffffff',
    duration: 3000
  })
}

export const toastUnauthorized = () => {
  toast(
    <span >
      <br />

      <b style={{ color: 'red', fontSize: '16px' }}>Session Expired, Please Login again!</b>

      <br />
      <button className="btn btn-outline-dark border-2 mx-2 button-size my-2"
        onClick={() => { sessionStorage.clear(); window.location.reload() }}
      >
        Login Again
      </button>

    </span>

  );
}
