import { t } from "i18next"
import { useDispatch } from "react-redux";
import { useAuthState } from "../../../Helper/Context/context";


const ViewPopup = (props) => {

    const { handleShowView, Title, ModalData } = props
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    console.log("ModalData", ModalData)

    return (
        <>
            <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
                <div className="modal-dialog modal-xl view_modal_size" role="document" style={{ width: '50vw' }} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">{Title}</h5>
                            <button onClick={handleShowView} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mx-lg-4">
                            <div className="row" style={{ height: '65vh', overflow: "auto" }}>
                                <div className="col-12">
                                    <div className="py-1 px-4  bg-white  pb-2">
                                        <div className="">
                                            <span className=" resolved">
                                                {/* {t('GrievanceStatus.Resolved')} */}
                                                {/* {ModalData.RowData.statusName} */}
                                            </span>
                                        </div>

                                        <div className=" border-bottom mb-2">
                                            <h6 className="fw-bold text-success">
                                                Grievance Area Details
                                            </h6>
                                            {/* <h5 className="form-label pe-4 text-black font-weight-bold">
                                                {t('GrievanceStatus.GrievanceDetails')}Grievance Area Details
                                            </h5> */}
                                        </div>
                                        <div className="mb-1">
                                            <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceDetails')} */}
                                                Grievance Area :  
                                            </label>
                                            <span> {ModalData.RowData.m_IndicatorGrievanceArea ? ModalData.RowData.m_IndicatorGrievanceArea : '-'} </span>
                                           
                                        </div>


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceArea')}: */}
                                                Center Name :
                                            </label>
                                            <span>{ModalData.RowData.centerName ? ModalData.RowData.centerName : '-'}</span>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.HospitalDetails')}: */}
                                                District :
                                            </label>
                                            <span>{ModalData.RowData.districtName ? ModalData.RowData.districtName : '-'}</span>
                                        </div>
                                        {
                                            RoleID == '6' ?
                                                <>
                                                    <div className="mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                            {/* {t('GrievanceStatus.HospitalDetails')}: */}
                                                            Taluka :
                                                        </label>
                                                        <span>{ModalData.RowData.talukaName ? ModalData.RowData.talukaName : '-'}</span>
                                                    </div>
                                                    <div className="mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                            {/* {t('GrievanceStatus.HospitalDetails')}: */}
                                                            Village :
                                                        </label>
                                                        <span>{ModalData.RowData.villageName ? ModalData.RowData.villageName : '-'}</span>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.DoctorName')}: */}
                                                AreaName :
                                            </label>
                                            <span>{ModalData.RowData.areaName ? ModalData.RowData.areaName : '-'}</span>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                AreaAdress :
                                            </label>
                                            <span>{ModalData.areaAdress ? ModalData.RowData.areaAdress : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="py-1 px-4  bg-white  pb-2">
                                        {/* <div className="">
                                            <span className=" resolved">
                                                {t('GrievanceStatus.Resolved')}
                                            </span>
                                        </div> */}

                                        <div className="border-bottom mb-2">
                                            <h6 className="fw-bold text-success">
                                                Hospital Details
                                            </h6>
                                        </div>
                                        <div className="mb-1">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceDetails')} */}
                                                Hospital Name :
                                            </label>
                                            <span>{ModalData.RowData.hospitalName ? ModalData.RowData.hospitalName : '-'}</span>
                                        </div>


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceArea')}: */}
                                                Hospital Address :
                                            </label>
                                            <span>{ModalData.RowData.hospitalAddress ? ModalData.RowData.hospitalAddress : '-'}</span>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.HospitalDetails')}: */}
                                                Doctor Name :
                                            </label>
                                            <span>{ModalData.RowData.doctorName ? ModalData.RowData.doctorName : '-'}</span>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.DoctorName')}: */}
                                                Doctor Address :
                                            </label>
                                            <span>{ModalData.RowData.doctorAddress ? ModalData.RowData.doctorAddress : '-'}</span>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Referred Doctor Name :
                                            </label>
                                            <span>{ModalData.RowData.referredDoctorName ? ModalData.RowData.referredDoctorName : '-'}</span>
                                        </div>
                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Patient Name :
                                            </label>
                                            <span>{ModalData.RowData.patientName ? ModalData.RowData.patientName : '-'}</span>
                                        </div>
                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Patient Address :
                                            </label>
                                            <span>{ModalData.RowData.patientAddress}</span>
                                        </div>
                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Van Number :
                                            </label>
                                            <span>{ModalData.RowData.vanNumber ? ModalData.RowData.vanNumber : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border-bottom ">
                                    <div className="py-1 px-4  bg-white  pb-2">
                                        {/* <div className="">
                                            <span className=" resolved">
                                                {t('GrievanceStatus.Resolved')}
                                            </span>
                                        </div> */}
                                        <div className="border-bottom mb-2">
                                            <h6 className="fw-bold text-success">
                                                Grievance Details
                                            </h6>
                                        </div>

                                        <div className="mb-1">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceDetails')} */}
                                                Grievance :
                                            </label>
                                            <span>{ModalData.RowData.m_IndicatorGrievanceType ? ModalData.RowData.m_IndicatorGrievanceType : '-'}</span>
                                        </div>


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceArea')}: */}
                                                Grievance Details :
                                            </label>
                                            <span>{ModalData.RowData.grievanceDetails ? ModalData.RowData.grievanceDetails : '-'}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div class="modal-footer">
                            <div className="row">
                                <div className="col-12  d-flex justify-content-center">
                                    <div className="form-group w-80 d-flex justify-content-center">
                                        <button
                                            type='button'
                                            // onClick={() => handleDeleteData()}
                                            className="btn bg-success text-white border-2 mx-2 px-4" data-ktwizard-type="action-next">
                                            Yes
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleShowView}
                                            className="btn btn-danger border-2" data-ktwizard-type="action-next">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
            </div>
        </>);
}

export default ViewPopup;