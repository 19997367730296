import { t } from "i18next"

export default function GrievanceStatusPopup(props) {
    const { handleShowView, Title, ModalData } = props

    console.log("ModalData", ModalData)

    return (
        <>
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className="mt-2 ml-4">
                                <h5 className='  text-center login_text '>
                                    {/* {t('GrievanceStatus.Track')} */}
                                    {Title}
                                </h5>
                            </div>
                            <div className='mt-1'>
                                {/* <ExportOfficeLetterSecondPopUpCOMM /> */}
                            </div>

                            <button type="button" className="btn-close mr-3 mt-2 " data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={handleShowView}
                            >

                            </button>
                        </div>

                        <div className="modal-body mx-4" >
                            <div className="row" style={{ height: '65vh', overflow: "auto" }}>
                                <div className="col-12">
                                    <div className="py-1 px-4  bg-white  pb-2">
                                        <div className="">
                                            <span className=" resolved">
                                                {/* {t('GrievanceStatus.Resolved')} */}
                                                {/* {ModalData.RowData.statusName} */}
                                            </span>
                                        </div>

                                        <div className=" border-bottom mb-2">
                                            <h6 className="fw-bold text-success">
                                                Grievance Area Details
                                            </h6>
                                            {/* <h5 className="form-label pe-4 text-black font-weight-bold">
                                                {t('GrievanceStatus.GrievanceDetails')}Grievance Area Details
                                            </h5> */}
                                        </div>
                                        <div className="mb-1">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceDetails')} */}
                                                Grievance Area:
                                            </label>
                                            <p>{ModalData.RowData.m_IndicatorGrievanceArea}</p>
                                        </div>


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceArea')}: */}
                                                Center Name:
                                            </label>
                                            <p>{ModalData.RowData.centerName}</p>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.HospitalDetails')}: */}
                                                District:
                                            </label>
                                            <p>{ModalData.RowData.districtName}</p>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.DoctorName')}: */}
                                                AreaName :
                                            </label>
                                            <p>{ModalData.RowData.areaName}</p>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                AreaAdress :
                                            </label>
                                            <p>{ModalData.RowData ? ModalData.RowData.areaAddress : '-'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="py-1 px-4  bg-white  pb-2">
                                        {/* <div className="">
                                            <span className=" resolved">
                                                {t('GrievanceStatus.Resolved')}
                                            </span>
                                        </div> */}

                                        <div className="border-bottom mb-2">
                                            <h6 className="fw-bold text-success">
                                                Hospital Details
                                            </h6>
                                        </div>
                                        <div className="mb-1">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceDetails')} */}
                                                Hospital Name:
                                            </label>
                                            <p>{ModalData.RowData.hospitalName}</p>
                                        </div>


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceArea')}: */}
                                                Hospital Address:
                                            </label>
                                            <p>{ModalData.RowData.hospitalAddress}</p>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.HospitalDetails')}: */}
                                                Doctor Name:
                                            </label>
                                            <p>{ModalData.RowData.doctorName}</p>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.DoctorName')}: */}
                                                Doctor Address :
                                            </label>
                                            <p>{ModalData.RowData.doctorAddress}</p>
                                        </div>

                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Referred Doctor Name :
                                            </label>
                                            <p>{ModalData.RowData.referredDoctorName}</p>
                                        </div>
                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Patient Name:
                                            </label>
                                            <p>{ModalData.RowData.patientName}</p>
                                        </div>
                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.PatientName')}: */}
                                                Patient Address:
                                            </label>
                                            <p>{ModalData.RowData.patientAddress}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border-bottom ">
                                    <div className="py-1 px-4  bg-white  pb-2">
                                        {/* <div className="">
                                            <span className=" resolved">
                                                {t('GrievanceStatus.Resolved')}
                                            </span>
                                        </div> */}
                                        <div className="border-bottom mb-2">
                                            <h6 className="fw-bold text-success">
                                                Grievance Details
                                            </h6>
                                        </div>

                                        <div className="mb-1">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceDetails')} */}
                                                Grievance :
                                            </label>
                                            <p>{ModalData.RowData.m_IndicatorGrievanceType}</p>
                                        </div>


                                        <div className="mb-1 ">
                                            <label for="exampleFormControlInput1" className="form-label pe-4 text-black font-weight-bold">
                                                {/* {t('GrievanceStatus.GrievanceArea')}: */}
                                                Grievance Details:
                                            </label>
                                            <p>{ModalData.RowData.grievanceDetails}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>





                        </div>


                        <div className="modal-footer">
                            <button type="button" onClick={handleShowView} className="btn bg-success text-white" data-bs-dismiss="modal">
                                {/* {t('GrievanceStatus.Submit')} */}
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}