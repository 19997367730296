import { useEffect, useState } from "react"
import { Footer } from "../../../Footer/Footer"
import { Header } from "../../Header/Header"
import { SideBar } from "../../SideBar/SideBar"
import Select from 'react-select'
import { UserCreationMasterPopUp } from "./Modal/UserCreationMasterPopUp"
import DeletePopUp from "../../CommonPopUp/DeletePopUp"
import { DistrictDDL } from "../../../../Components/CommonDDL/DistrictDDL"
import { t } from "i18next"
import { RoleDDL } from "../../../../Components/CommonDDL/RoleDDl"
import { GrievanceTypeDDL } from "../../../../Components/CommonDDL/GrievanceTypeDDL"
import { GenderDDL } from "../../../../Components/CommonDDL/GenderDDL"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../Helper/Context/context"
import { UserCreationMasterDeleteAPI, UserCreationMasterGetAPI } from "../../../../Helper/Redux/MasterSlice/UserCreationMasterSlice"
import { Loading } from "../../../../Helper/Loader/LogoLoader"
import { Pegination } from "../../../../Components/Pagination/Pagination"

export const UserCreationMaster = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [ShowModal, setShowModal] = useState(false)
    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [ShowDeletePopup, setShowDeletePopup] = useState(false)
    const [ShowDeletePopupData, setShowDeletePopupData] = useState('')
    const [IsClear, setIsClear] = useState(false)
    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: 'English'
    })

    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const [RoleDDLID, setRoleDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const [GenderDDLID, setGenderDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleShowModal = (apiFlag, rowData) => {
        setShowModal(!ShowModal)
        setModalData({
            Flag: apiFlag ? apiFlag : '',
            RowData: rowData ? rowData : ''
        })
    }
    // const handleShowDelete = (data) => {
    //     setShowDeletePopup(!ShowDeletePopup)
    //     setShowDeletePopupData(data)
    // }

    const handleShowDelete = (rowData) => {
        setShowDeletePopup(!ShowDeletePopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : ''
        })
    }

    const handleDeleteData = () => {
        const data = {

        }
        // dispatch()
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {

        setIsClear(!IsClear)
        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setRoleDDLID({ 
            ...RoleDDLID,
            ID: 0,
            Label: 'Select...'  
        })
        setGenderDDLID({
            ...GenderDDLID,
            ID: 0, 
            Label: 'Select...'
        })
    }

    useEffect(() => {
        const data = {
            M_RoleID: RoleDDLID.ID,
            M_IndicatorGrievanceAreaID: GenderDDLID.ID,
            M_DistrictID: DistrictDDLID.ID,
            FromTop: From,
            ToTop: To,
            token: token,
            M_UserID: UserID,
        }
        dispatch(UserCreationMasterGetAPI({ data }))
    }, [IsClear, IsSearch, IsPost, To])

    const { TableData, isLoading } = useSelector(state => state.UserCreationMasterGetData)

    const handleDelete = () => {
        const data = {
            M_UserID: ModalData.RowData.m_UserID,
            token: token,
            handleShowDelete: handleShowDelete,
            handleIsPost: handleIsPost
        }
        dispatch(UserCreationMasterDeleteAPI({ data }))
    }

    return (
        <>
            {isLoading && <Loading />}
            <Header
                Language={Language}
                setLanguage={setLanguage}
            />
            <div id="wrapper" className="d-flex justify-content-center">
                {/* <!-- Content Wrapper --> */}
                <SideBar active="UserCreationMaster" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-1 px-lg-3 justify-content-between" id='content' >
                    <div className="content-wrapper " >
                        <div className="row my-2">
                            <div className="page-heading col-12 mb-xl-0  px-lg-4 d-flex justify-content-between align-items-center">
                                <h6 className="fs-7 font-weight-bold text-black ">{t('DirectorDashboard.UserCreation')}</h6>
                                <a type="button"
                                    onClick={() => handleShowModal('Insert')}
                                    className="btn btn-dark border-2  "><i
                                        className="fa-solid fa-plus me-1"></i> Add
                                </a>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-12 ">
                                <div className="card mb-4 overflow-hidden">
                                    <div className="card-body px-3 py-2 linear-g">
                                        <div className="row mb-4 ">
                                            <div className=" col-lg-3 ">
                                                <label for="Status" style={{ fontWeight: '500', color: '#696969' }}>{t('GridHeading.Role')}</label>
                                                <RoleDDL
                                                    RoleDDLID={RoleDDLID}
                                                    setRoleDDLID={setRoleDDLID}
                                                />
                                            </div>
                                            <div className=" col-lg-3 pt-3 pt-lg-0">
                                                <label for="Status" style={{ fontWeight: '500', color: '#696969' }}>{t('GridHeading.Area')}</label>
                                                <GenderDDL
                                                    GenderDDLID={GenderDDLID}
                                                    setGenderDDLID={setGenderDDLID}
                                                    Language={Language}
                                                    GroupID='1'
                                                />
                                            </div>
                                            <div className=" col-lg-3 pt-3 pt-lg-0">
                                                <label for="Status" style={{ fontWeight: '500', color: '#696969' }}>{t('GridHeading.District')}</label>
                                                <DistrictDDL
                                                    DistrictDDLID={DistrictDDLID}
                                                    setDistrictDDLID={setDistrictDDLID}
                                                    Language={Language}
                                                    M_IndicatorGrievanceAreaID={GenderDDLID.ID}
                                                    UserID={UserID}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3 pt-2 mt-3 mt-lg-2 pt-lg-4 pb-3 pb-lg-0 align-items-end ">
                                                <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                                    onClick={handleSearch}
                                                >
                                                    Search</button>

                                                <button type="button" className="btn btn-danger border-2 button-size"
                                                    onClick={handleClear}
                                                >Clear</button>
                                            </div>
                                        </div>

                                        {/* <TableData /> */}

                                        <div className="table-responsive p-0">
                                            <table className="table table-bordered w-100">
                                                <thead>
                                                    <tr className="bg-dark">
                                                        <th className="">{t('GridHeading.Sr_No')}</th>
                                                        <th className=" ps-2">{t('GridHeading.Area')}</th>
                                                        <th className=" ps-2">{t('GridHeading.District')}</th>
                                                        <th className=" ps-2">{t('DirectorDashboard.Designation')}</th>
                                                        <th className=" ps-2">{t('GridHeading.Employee')}</th>
                                                        <th className=" ps-2">{t('GridHeading.UserName')}</th>
                                                        <th className=" ps-2">{t('GridHeading.Password')}</th>
                                                        <th className=" ps-2  w-10">{t('GridHeading.Action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        TableData && TableData.length > 0 ?
                                                            TableData.map((i, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="w-7">{i?.rowNum}</td>
                                                                        <td>{i?.indicatorName}</td>
                                                                        <td>{i?.distrcitName}</td>
                                                                        <td>{i?.designationName}</td>
                                                                        <td>{i?.employeeName}</td>
                                                                        <td>{i?.userName}</td>
                                                                        <td>{i?.password}</td>
                                                                        <td className="text-center w-20">
                                                                            <span className="cursor-pointer"
                                                                                onClick={() => handleShowModal('Update', i)}
                                                                            >
                                                                                <i class="fa-solid fa-pen-to-square"></i>
                                                                            </span>
                                                                            <span className="cursor-pointer"
                                                                                onClick={() => handleShowDelete(i)}
                                                                            >
                                                                                <i class="fa-solid fa-trash mx-4"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            : <></>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {TableData && TableData.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={TableData[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                                IsClear={IsClear}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                {
                    ShowModal &&
                    <UserCreationMasterPopUp
                        handleShowModal={handleShowModal}
                        handleIsPost={handleIsPost}
                        ModalData={ModalData}
                    />
                }
                {
                    ShowDeletePopup &&
                    <DeletePopUp
                        handleShowDelete={handleShowDelete}
                        handleDelete={handleDelete}
                    />
                }
            </div>
        </>
    )
}