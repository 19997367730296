import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../../../Helper/Context/context";
import { Pie } from "react-chartjs-2";
import { MunicipalCorporation, MunicipalCorporationdata, ResolvedGrievance, ResolvedGrievanceMunicipalCorporation, ResolvedGrievanceMunicipalCorporationoptions, ResolvedGrievanceoptions, RuralArea, RuralAreadata } from "../../../../Helper/ChartDeta";
import Chart from "react-google-charts";
import { useEffect, useState } from "react";
import { t } from "i18next";


export const DirectorResolvedGrievance = (props) => {
    const { DDLFinantialYearID, DDLMonthID, Countdata, LableName } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    // const [CheckCountData, setCheckCountData] = useState([Countdata])

    const [CheckTrue, setCheckTrue] = useState(false)
    const [IsCheckTrue, setIsCheckTrue] = useState(false)

    // function zeroTest(element) {
    //     return element === 0;
    // }

    // var allZeros

    // if (Countdata) {
    //     allZeros = CheckCountData.every(zeroTest);
    // }

    // const handleResolvedGrievanceMunicipalCorporation = () => {
    //     if ((Countdata && Countdata.Received_MCTotalCount == 0) && (Countdata && Countdata.Resolved_MCTotalCount == 0) && (Countdata && Countdata.Pending_MCTotalCount == 0)) {
    //         setCheckTrue(true)
    //     } else {
    //         setCheckTrue(false)
    //     }

    // }
    // const handleResolvedGrievance = () => {

    //     if ((Countdata && Countdata.Received_RuralTotalCount == 0) && (Countdata && Countdata.Resolved_RuralTotalCount == 0) && (Countdata && Countdata.Pending_RuralTotalCount == 0)) {
    //         setIsCheckTrue(true)
    //     } else {
    //         setIsCheckTrue(false)
    //     }

    // }


    // console.log(Countdata && Countdata.TotalReceivedGrievanceTotalCount,Countdata && Countdata.TotalReceived_MCTotalCount,Countdata && Countdata.TotalReceived_RuralTotalCount)
    // useEffect(() => {
    //     handleResolvedGrievance()
    //     handleResolvedGrievanceMunicipalCorporation()

    // }, [Countdata])

    const ResolvedGrievanceMunicipalCorporation = [
        ["Task", "Hours per Day"],
        // [LableName && LableName.Received_MCTotalCount, Countdata && Countdata.Received_MCTotalCount],
        [LableName && LableName.Resolved_MCTotalCount, Countdata && Countdata.Resolved_MCTotalCount],
        [LableName && LableName.Pending_MCTotalCount, Countdata && Countdata.Pending_MCTotalCount],

    ];

    const ResolvedGrievanceMunicipalCorporationoptions = {
        // title: "My Daily Activities",
        is3D: true,
        colors: ['#f34057', '#ef8f00',],
        legend: { position: 'bottom' },
        chartArea: {
            left: 0, // Customize the left margin
            top: 0, // Customize the top margin
            width: "92%", // Customize chart width
            height: "92%", // Customize chart height
        },
    };

    const ResolvedGrievance = [
        ["Task", "Hours per Day"],
        // [LableName && LableName.Received_RuralTotalCount, Countdata && Countdata.Received_RuralTotalCount],
        [LableName && LableName.Resolved_RuralTotalCount, Countdata && Countdata.Resolved_RuralTotalCount],
        [LableName && LableName.Pending_RuralTotalCount, Countdata && Countdata.Pending_RuralTotalCount],

    ];

    const ResolvedGrievanceoptions = {
        // title: "My Daily Activities",
        is3D: true,
        colors: ['#f88720', '#f75d94',],
        legend: { position: 'bottom' },
        chartArea: {
            left: 0, // Customize the left margin
            top: 0, // Customize the top margin
            width: "92%", // Customize chart width
            height: "92%", // Customize chart height
        },
    };

    return (
        <>
            <div className="row pt-2 pb-2">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 ">
                        {t('DirectorDashboard.ReslovedGrievance')}
                        {/* Resolved Grievance */}
                    </h6>
                </div>
            </div>


            <div className="bg-white px-3 rounded">


                <div className="row pt-3 pb-2 border-bottom">

                    <div className="col-12 col-lg-9">
                        <h6 className="text-black  h8  pb-2 fw_text">
                            {t('DirectorDashboard.InMunicipalCorporation')}
                            {/* In Municipal Corporation */}
                        </h6>
                        <div className="row">
                            <div className="col-xl-4 col-md-6 mb-2">
                                <div className="cursor_pointer card_design_purple shadow  py-2"
                                    onClick={() => navigate(`/DirectorResolvedGrievanceGrid?screenheading=Resolved Grievance&screenname=Received&screensubheading=Municipal Corporation&Flag=Received_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCorporationID=1`)}
                                >
                                    <div className="card-body p-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold mb-1 card_heading_purple ">
                                                    {t('DirectorDashboard.ReceivedGrievance')}
                                                    {/* Received Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            {Countdata.Received_MCTotalCount ? Countdata.Received_MCTotalCount : '0'}
                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <i className="fas fa-circle-check  border_all_radius text-white icon_bg_purple "></i>

                                                            {/* <img src="assets/img/Director/received.png" className="Director_icon icon_bg_purple mb-2" alt="" />  */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                                                {/* <img src="assets/img/Dashboard_img/received.png" className="icon_size" alt="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-2">
                                <div className="cursor_pointer card_design_pink shadow  py-2"
                                    onClick={() => navigate(`/DirectorResolvedGrievanceGrid?screenheading=Resolved Grievance&screenname=Resolved&screensubheading=Municipal Corporation&Flag=Resolved_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCorporationID=1`)}
                                >
                                    <div className="card-body p-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold card_heading_pink mb-1">
                                                    {t('DirectorDashboard.ReslovedGrievance')}
                                                    {/* Resolved Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            {Countdata.Resolved_MCTotalCount ? Countdata.Resolved_MCTotalCount : '0'}
                                                        </div>
                                                        <div className="col-6 text-end">
                                                            {/* <img src="assets/img/Director/corporation.png" className="Director_icon icon_bg_pink   mb-2" alt="" />  */}
                                                            <i className="fas fa-rotate-right border_all_radius text-white icon_bg_pink"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-dollar-sign fa-2x text-gray-300"></i> */}
                                                {/* <img src="assets/img/Dashboard_img/acknowledge.png" className="icon_size" alt="" /> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-2 ">
                                <div className="card_design_orange cursor_pointer shadow  py-2"
                                    onClick={() => navigate(`/DirectorResolvedGrievanceGrid?screenheading=Resolved Grievance&screenname=Pending&screensubheading=Municipal Corporation&Flag=Pending_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCorporationID=1`)}
                                >
                                    <div className="card-body p-1">
                                        {/* <div className="row no-gutters align-items-center"> */}
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold card_heading_orange mb-1">
                                                {t('DirectorDashboard.PendingGrievance')}
                                                {/* Pending Grievance */}
                                            </div>
                                            <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">
                                                <div className="row">
                                                    <div className="col-6">
                                                        {Countdata.Pending_MCTotalCount ? Countdata.Pending_MCTotalCount : '0'}
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {/* <img src="assets/img/Director/Rural.png" className="Director_icon icon_bg_oranges1  mb-2" alt="" />  */}
                                                        <i className="fas fa-clock border_all_radius text-white icon_bg_oranges1"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            {/* <i className="fas fa-comments fa-2x text-gray-300"></i> */}
                                            {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">

                        {/* <Pie
                            height={160}
                            // width={100}
                            options={MunicipalCorporation}
                            data={MunicipalCorporationdata}
                        /> */}

                        {/* {
                            CheckTrue ?
                                <div className='loadingcenter' style={{ height: '51vh', marginTop: '-3%' }}>
                                    <img
                                        src="assets/img/folder.png"
                                        style={{ height: "40px", marginLeft: "10px" }}
                                    /><label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                </div>

                                : */}

                        <Chart
                            chartType="PieChart"
                            data={ResolvedGrievanceMunicipalCorporation}
                            options={ResolvedGrievanceMunicipalCorporationoptions}
                            width={"100%"}
                            height={"200px"}
                        />
                        {/* } */}

                    </div>
                </div>

                {/* <hr className="hr_line"/> */}

                <div className="row pt-3 pb-2">

                    <div className="col-12 col-lg-9">
                        <h6 className="text-black  h8 pt-0 pb-2 fw_text">
                            {t('DirectorDashboard.InRuralArea')}
                            {/* In Rural Area */}
                        </h6>
                        <div className="row">
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="cursor_pointer  shadow h-100 py-2"
                                    style={{ background: 'rgb(80, 138, 255)', border: '2px solid rgba(143, 5, 239, 0.21)' }}

                                    onClick={() => navigate(`/DirectorRuralAreaGrid?screenheading=Resolved Grievance&screenname=Received&screensubheading=Rural Area&Flag=Received_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCorporationID=2`)}
                                >
                                    <div className="card-body p-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold mb-1 text-white ">
                                                    {t('DirectorDashboard.ReceivedGrievance')}
                                                    {/* Received Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold text-white">{Countdata.Received_RuralTotalCount ? Countdata.Received_RuralTotalCount : '0'}</div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                                                {/* <img src="assets/img/Dashboard_img/received.png" className="icon_size" alt="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="cursor_pointer  shadow h-100 py-2"
                                    style={{ background: 'rgb(252,135,32)', border: '2px solid rgba(183, 89, 5, 0.29)' }}

                                    onClick={() => navigate(`/DirectorRuralAreaGrid?screenheading=Resolved Grievance&screenname=Resolved&screensubheading=Rural Area&Flag=Resolved_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCorporationID=2`)}
                                >
                                    <div className="card-body p-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-white mb-1">
                                                    {t('DirectorDashboard.ReslovedGrievance')}
                                                    {/* Resolved Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold text-white">{Countdata.Resolved_RuralTotalCount ? Countdata.Resolved_RuralTotalCount : '0'}</div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-dollar-sign fa-2x text-gray-300"></i> */}
                                                {/* <img src="assets/img/Dashboard_img/acknowledge.png" className="icon_size" alt="" /> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-4 ">
                                <div className="card_design_orange cursor_pointer shadow h-100 py-2"
                                    style={{ background: 'rgb(255, 93, 148)', border: '2px solid rgba(241, 0, 82, 0.19)' }}

                                    onClick={() => navigate(`/DirectorRuralAreaGrid?screenheading=Resolved Grievance&screenname=Pending&screensubheading=Rural Area&Flag=Pending_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCorporationID=2`)}
                                >
                                    <div className="card-body p-1">
                                        {/* <div className="row no-gutters align-items-center"> */}
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-white mb-1">
                                                {t('DirectorDashboard.PendingGrievance')}
                                                {/* Pending Grievance */}
                                            </div>
                                            <div className="h3 mb-0 pt-2 font-weight-bold text-white">{Countdata.Pending_RuralTotalCount ? Countdata.Pending_RuralTotalCount : '0'}</div>
                                        </div>
                                        <div className="col-auto">
                                            {/* <i className="fas fa-comments fa-2x text-gray-300"></i> */}
                                            {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-lg-3">

                        {/* <Pie
                           height={160}
                            options={RuralArea}
                            data={RuralAreadata}
                        /> */}
                        {/* {
                            IsCheckTrue ?
                                <div className='loadingcenter' style={{ height: '51vh', marginTop: '-3%' }}>
                                    <img
                                        src="assets/img/folder.png"
                                        style={{ height: "40px", marginLeft: "10px" }}
                                    /><label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                </div>

                                : */}

                        <Chart
                            chartType="PieChart"
                            data={ResolvedGrievance}
                            options={ResolvedGrievanceoptions}
                            width={"100%"}
                            height={"200px"}
                        />
                        {/* } */}

                    </div>
                </div>

            </div>
        </>
    )
}
