import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom"
import { Municipal, Rural } from "../../../../Helper/ChartDeta";
import { useAuthState } from "../../../../Helper/Context/context";
import { useState } from "react";
import { t } from "i18next";

export const DirectorEscalatedGrievance = (props) => {
    const { DDLFinantialYearID, DDLMonthID, Countdata, LableName } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    // console.log("Countdata", Countdata.PendingGrievanceStateNodal_RuralTotalCount)
    // console.log("Countdatass", Countdata?.ResolvedGrievanceStateNodal_RuralTotalCount)

    const [CheckCountData, setCheckCountData] = useState([Countdata])

    function zeroTest(element) {
        return element === 0;
    }

    var allZeros

    if (Countdata) {
        allZeros = CheckCountData.every(zeroTest);
    }


    const Municipaldata = {

        // labels: ['Received Grievance', 'Acknowledge Grievance', 'Resolved Grievance', 'Pending Grievance'],ResolvedGrievanceStateNodal_MCTotalCount
        labels: [LableName && LableName.EscalatedGrievanceReceived_MCTotalCount, LableName && LableName.AcknowledgeGrievanceStateNodal_MCTotalCount, LableName && LableName.ResolvedGrievanceStateNodal_MCTotalCount, LableName && LableName.EscalatedPendingGrievanceStateNodal_MCTotalCount],
        datasets: [
            {
                label: ['Count '],
                fill: true,
                backgroundColor: [
                    "#8A2387",
                    "#e94057",
                    "#223a75",
                    "#a94f16"
                ],
                // data: [WorkAssignedTotalCount.table[0].DeputyMunicipalCommissioner]
                data: [Countdata && Countdata.EscalatedGrievanceReceived_MCTotalCount, Countdata && Countdata.AcknowledgeGrievanceStateNodal_MCTotalCount, Countdata && Countdata.ResolvedGrievanceStateNodal_MCTotalCount, Countdata && Countdata.EscalatedPendingGrievanceStateNodal_MCTotalCount]

            },
        ],
    };

    const Ruraldata = {

        labels: [LableName && LableName.EscalatedGrievanceReceived_RuralTotalCount, LableName && LableName.AcknowledgeGrievanceStateNodal_RuralTotalCount, LableName && LableName.ResolvedGrievanceStateNodal_RuralTotalCount, LableName && LableName.EscalatedPendingGrievanceStateNodal_RuralTotalCount],
        datasets: [
            {
                label: ['Count '],
                fill: true,
                backgroundColor: [
                    "#a654df",
                    "#f75d94",
                    "#44a08d",
                    "#508aff"

                ],
                // data: [WorkAssignedTotalCount.table[0].DeputyMunicipalCommissioner]
                data: [Countdata && Countdata.EscalatedGrievanceReceived_RuralTotalCount, Countdata && Countdata.AcknowledgeGrievanceStateNodal_RuralTotalCount, Countdata && Countdata.ResolvedGrievanceStateNodal_RuralTotalCount, Countdata && Countdata.EscalatedPendingGrievanceStateNodal_RuralTotalCount]

            },
        ],
    };

    return (
        <>

            <div className="row px-1  pb-2 pt-3">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 ">
                        {t('DirectorDashboard.EscalatedGrievances')}
                        {/* Escalated Grievance */}
                    </h6>
                </div>
            </div>

            <div className=" px-3 bg-white rounded">

                <div className="row border-bottom pt-3">
                    <h6 className="text-black  h8 pt-0 pb-2 fw_text">
                    
                       {t('DirectorDashboard.FromMOHMunicipalCorporation')}
                    </h6>

                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="cursor_pointer card_design_purple shadow h-100 py-2"
                                    onClick={() => navigate(`/DirectorEscalatedMunicipalCorporationgrid?screenheading=Escalated Grievance&screenname=Received&screensubheading=Municipal Corporation&Flag=EscalatedReceived_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold mb-1 card_heading_purple ">
                                                    {t('DirectorDashboard.ReceivedGrievance')}
                                                    {/* Received Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">{Countdata.EscalatedGrievanceReceived_MCTotalCount ? Countdata.EscalatedGrievanceReceived_MCTotalCount : '0'}</div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                                                <img src="assets/img/Dashboard_img/received.png" className="icon_size" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="cursor_pointer card_design_pink shadow h-100 py-2"
                                    onClick={() => navigate(`/DirectorEscalatedMunicipalCorporationgrid?screenheading=Escalated Grievance&screenname=Acknowledge&screensubheading=Municipal Corporation&Flag=AcknowledgeStateNodal_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold card_heading_pink mb-1">
                                                    {t('DirectorDashboard.AcknowledgeGrievance')}
                                                    {/* Acknowledge Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">{Countdata.AcknowledgeGrievanceStateNodal_MCTotalCount ? Countdata.AcknowledgeGrievanceStateNodal_MCTotalCount : '0'}</div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-dollar-sign fa-2x text-gray-300"></i> */}
                                                <img src="assets/img/Dashboard_img/acknowledge.png" className="icon_size" alt="" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="card_design_skycolor cursor_pointer shadow h-100 py-2"
                                    onClick={() => navigate(`/DirectorEscalatedMunicipalCorporationgrid?screenheading=Escalated Grievance&screenname=Resolved&screensubheading=Municipal Corporation&Flag=ResolvedStateNodal_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold card_heading_skycolor mb-1">
                                                    {t('DirectorDashboard.ReslovedGrievance')}
                                                    {/* Resolved Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold   text-gray-800">{Countdata.ResolvedGrievanceStateNodal_MCTotalCount ? Countdata.ResolvedGrievanceStateNodal_MCTotalCount : '0'}</div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-comments fa-2x text-gray-300"></i> */}
                                                <img src="assets/img/Dashboard_img/resolved.png" className="icon_size" alt="" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="card_design_orange cursor_pointer shadow h-100 py-2"
                                    onClick={() => navigate(`/DirectorEscalatedMunicipalCorporationgrid?screenheading=Escalated Grievance&screenname=Pending&screensubheading=Municipal Corporation&Flag=PendingStateNodal_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold card_heading_orange mb-1">
                                                    {t('DirectorDashboard.PendingGrievance')}
                                                    {/* Pending Grievance */}
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold   text-gray-800">{Countdata.EscalatedPendingGrievanceStateNodal_MCTotalCount ? Countdata.EscalatedPendingGrievanceStateNodal_MCTotalCount : '0'}</div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-comments fa-2x text-gray-300"></i> */}
                                                <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="col-lg-6 text-center">

                        {
                            CheckCountData && allZeros != false ?
                                <div className='loadingcenter' style={{ height: '51vh', marginTop: '-3%' }}>
                                    <img
                                        src="assets/img/folder.png"
                                        style={{ height: "40px", marginLeft: "10px" }}
                                    /><label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                </div>

                                :
                                <>
                                    <Bar
                                        options={Municipal}
                                        data={Municipaldata}
                                    />
                                    <h6 className="text-black  h8 pt-4 mt-2 pb-2 fw_text">
                                        {/* {t('DirectorDashboard.ReceivedGrievance')} */}
                                        From (<span className="fw-bold">MOH</span>)  Municipal Corporation
                                    </h6>
                                </>


                        }
                    </div>

                </div>

                <div className="row pt-3">
                    <div className="col-12">
                        <h6 className="text-black  h8 pt-0 pb-2 fw_text">
                            {t('DirectorDashboard.FromCSRuralArea')}
                            {/* From (<span className="fw-bold">CS</span>) Rural Area */}
                        </h6>
                    </div>

                    <div className="col-lg-6">
                        <div className="row">

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="shadow h-100 py-2 cursor_pointer" style={{ background: '#a654df', border: '2px solid rgb(143 5 239 / 21%)' }}
                                    onClick={() => navigate(`/DirectorEscalatedRuralAreagrid?screenheading=Escalated Grievance&screenname=Received&screensubheading=Rural Area&Flag=EscalatedReceived_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.EscalatedGrievanceReceived_RuralTotalCount ? Countdata.EscalatedGrievanceReceived_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold  text-white pt-2 ">
                                                    {t('DirectorDashboard.ReceivedGrievance')}
                                                    {/* Received Grievance */}
                                                </div>
                                            </div>

                                            <div className="col-auto">
                                                <i className="fas fa-circle-check fa-2x text-gray-300 icon_bg_2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="  shadow h-100 py-2 cursor_pointer" style={{ background: '#ff5d94', border: '2px solid rgb(241 0 82 / 19%)' }}
                                    onClick={() => navigate(`/DirectorEscalatedRuralAreagrid?screenheading=Escalated Grievance&screenname=Acknowledge&screensubheading=Rural Area&Flag=AcknowledgeStateNodal_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >

                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.AcknowledgeGrievanceStateNodal_RuralTotalCount ? Countdata.AcknowledgeGrievanceStateNodal_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold text-white pt-2">
                                                    {t('DirectorDashboard.AcknowledgeGrievance')}
                                                    {/* Acknowledge Grievance */}
                                                </div>
                                            </div>

                                            <div className="col-auto">
                                                <i className="fas fa-thumbs-up fa-2x text-orange-300 icon_bg_2"></i>
                                                {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="shadow h-100 py-2 cursor_pointer" style={{ background: '#44A08D', border: '2px solid rgb(183 89 5 / 29%)' }}
                                    onClick={() => navigate(`/DirectorEscalatedRuralAreagrid?screenheading=Escalated Grievance&screenname=Resolved&screensubheading=Rural Area&Flag=ResolvedStateNodal_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >

                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.ResolvedGrievanceStateNodal_RuralTotalCount ? Countdata.ResolvedGrievanceStateNodal_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold text-white pt-2">
                                                    {t('DirectorDashboard.ReslovedGrievance')}
                                                    {/* Resolved Grievance */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-rotate-right fa-2x text-gray-300 icon_bg_2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="shadow h-100 py-2 cursor_pointer"
                                    style={{ background: 'rgb(80, 138, 255)', border: '2px solid #457ae3' }}
                                    onClick={() => navigate(`/DirectorEscalatedRuralAreagrid?screenheading=Escalated Grievance&screenname=Pending&screensubheading=Rural Area&Flag=PendingStateNodal_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >

                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.EscalatedPendingGrievanceStateNodal_RuralTotalCount ? Countdata.EscalatedPendingGrievanceStateNodal_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold text-white pt-2">
                                                    {t('DirectorDashboard.PendingGrievance')}
                                                    {/* Pending Grievance */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clock fa-2x text-orange-300 icon_bg_2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="col-lg-6 text-center">

                        {
                            CheckCountData && allZeros != false ?
                                <div className='loadingcenter' style={{ height: '51vh', marginTop: '-3%' }}>
                                    <img
                                        src="assets/img/folder.png"
                                        style={{ height: "40px", marginLeft: "10px" }}
                                    /><label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                </div>

                                :
                                <>
                                    <Bar
                                        options={Rural}
                                        data={Ruraldata}
                                    />
                                    <h6 className="text-black  h8 pt-4 mt-2 pb-2 fw_text">
                                        {/* {t('DirectorDashboard.ReceivedGrievance')} */}
                                        From (<span className="fw-bold">CS</span>) Rural Area
                                    </h6>
                                </>

                        }


                    </div>

                </div>
            </div>


        </>
    )
}