
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { GenderDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const GenderDDL = (props) => {
    const { GenderDDLID, setGenderDDLID, Flag, data, Language, GroupID, UserID } = props

    // NOTE: -------- GroupID => GrievanceArea=1 , Gender=2 & GrievanceType=3 -------

    const dispatch = useDispatch()
    const [GroupID1Data, setGroupID1Data] = useState('')
    const [GroupID2Data, setGroupID2Data] = useState('')
    const [GroupID3Data, setGroupID3Data] = useState('')

    useEffect(() => {
        const data = {
            GroupID: GroupID,
            Language: Language.Label,
            M_UserID: UserID ? UserID : '0',
            setGroupIDData: GroupID == 1 ? setGroupID1Data : GroupID == 2 ? setGroupID2Data : setGroupID3Data
        }
        dispatch(GenderDDLAPI({ data }))
    }, [Language.Label])

    const { DDLData } = useSelector(state => state.GenderDDLData)

    useEffect(() => {
        if (GroupID == 3) {
            handleGroupID3Data()
        } else if (GroupID == 2) {
            handleGroupID2Data()
        } else if (GroupID == 1) {
            handleGroupID1Data()
        }
    }, [DDLData])

    const handleGroupID1Data = () => {
        // GrievanceArea
        if (GroupID1Data && GroupID1Data.length > 0) {
            let list = GroupID1Data.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setGenderDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.m_IndicatorGrievanceAreaID : 0,
                Label: Flag == 'Update' ? data?.indicatorName : "Select...",
            })
        }
        else {
            setGenderDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    const handleGroupID2Data = () => {
        // Gender
        if (GroupID2Data && GroupID2Data.length > 0) {
            let list = GroupID2Data.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setGenderDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.valueID : 0,
                Label: Flag == 'Update' ? data?.valueParam : "Select...",
            })
        }
        else {
            setGenderDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    const handleGroupID3Data = () => {
        // GrievanceType
        if (GroupID3Data && GroupID3Data.length > 0) {
            let list = GroupID3Data.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setGenderDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.m_IndicatorGrievanceAreaID : 0,
                Label: Flag == 'Update' ? data?.indicatorName : "Select...",
            })
        }
        else {
            setGenderDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            {/* <label className="d-block" htmlFor="Gender">Gender</label> */}
            <Select
                id='GenderID'
                isClearable={true}
                isSearchable
                maxMenuHeight={150}
                value={{ value: GenderDDLID.ID, label: GenderDDLID.Label }}
                onChange={(e) => {
                    e ?
                        setGenderDDLID({ ...GenderDDLID, ID: e.value, Label: e.label })
                        :
                        setGenderDDLID({ ...GenderDDLID, ID: 0, Label: "Select..." })

                }}
                options={GenderDDLID.DDL}
            />
        </div>
    )
}