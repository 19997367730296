import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess, toastUnauthorized } from "../../AlertMessage/AlertMessage";

export const UserCreationMasterGetAPI = createAsyncThunk("UserCreationMasterGet", async ({ data }) => {
    const {
        M_RoleID,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Get_M_User_Select?M_RoleID=${M_RoleID}&M_IndicatorGrievanceAreaID=${M_IndicatorGrievanceAreaID ? M_IndicatorGrievanceAreaID : '0'}&M_DistrictID=${M_DistrictID ? M_DistrictID : '0'}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
    .then((res) => {
        if (res.status == 401) {
            toastUnauthorized()
        }
        if (res.status > 300 && res.status != 401) {
            toastError('Something went wrong')
        }
        return res.json()
    })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationMasterGetSlice = createSlice({
    name: "UserCreationMasterGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationMasterGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationMasterGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(UserCreationMasterGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const UserCreationMasterGetReducer = UserCreationMasterGetSlice.reducer

//Insert API

export const UserCreationMasterInsertAPI = createAsyncThunk("UserCreationMasterInsert", async ({ data }) => {
    const {
        M_RoleID,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_DesignationID,
        M_EmployeeID,
        UserName,
        Password,
        M_UserID,
        handleShowModal,
        handleIsPost,
        token,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
        formdata.append("M_RoleID", M_RoleID);
        formdata.append("M_IndicatorGrievanceAreaID", M_IndicatorGrievanceAreaID);
        formdata.append("M_DistrictID", M_DistrictID);
        formdata.append("M_DesignationID", M_DesignationID);
        formdata.append("M_EmployeeID", M_EmployeeID);
        formdata.append("UserName", UserName);
        formdata.append("Password", Password);
        formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Post_M_User_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowModal()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationMasterInsertSlice = createSlice({
    name: "UserCreationMasterInsert",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationMasterInsertAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationMasterInsertAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(UserCreationMasterInsertAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const UserCreationMasterInsertReducer = UserCreationMasterInsertSlice.reducer


//Update API

export const UserCreationMasterUpdateAPI = createAsyncThunk("UserCreationMasterUpdate", async ({ data }) => {
    const {
        M_TableUserID,
        M_RoleID,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_DesignationID,
        M_EmployeeID,
        UserName,
        Password,
        M_UserID,
        token,
        handleShowModal,
        handleIsPost
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
        formdata.append("M_TableUserID", M_TableUserID);
        formdata.append("M_RoleID", M_RoleID);
        formdata.append("M_IndicatorGrievanceAreaID", M_IndicatorGrievanceAreaID);
        formdata.append("M_DistrictID", M_DistrictID);
        formdata.append("M_DesignationID", M_DesignationID);
        formdata.append("M_EmployeeID", M_EmployeeID);
        formdata.append("UserName", UserName);
        formdata.append("Password", Password);
        formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/Patch_M_User_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowModal()
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationMasterUpdateSlice = createSlice({
    name: "UserCreationMasterUpdate",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationMasterUpdateAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationMasterUpdateAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(UserCreationMasterUpdateAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const UserCreationMasterUpdateReducer = UserCreationMasterUpdateSlice.reducer

//Delete API

export const UserCreationMasterDeleteAPI = createAsyncThunk("UserCreationMasterDelete", async ({ data }) => {
    const {
        M_UserID,
        token,
        handleShowDelete,
        handleIsPost
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("M_UserID", M_UserID);
    
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    return fetch(`${BaseUrl}/Master/M_User_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowDelete()
                handleIsPost()
                toastSuccess(result.message)
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationMasterDeleteSlice = createSlice({
    name: "UserCreationMasterDelete",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationMasterDeleteAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationMasterDeleteAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(UserCreationMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const UserCreationMasterDeleteReducer = UserCreationMasterDeleteSlice.reducer