import Select from 'react-select'
import { RoleDDL } from '../../../../../Components/CommonDDL/RoleDDl'
import { useState } from "react"
import { GenderDDL } from '../../../../../Components/CommonDDL/GenderDDL'
import { DistrictDDL } from '../../../../../Components/CommonDDL/DistrictDDL'
import { DesignationDDL } from '../../../../../Components/CommonDDL/DesignationDDL'
import { EmployeeDDL } from '../../../../../Components/CommonDDL/EmployeeDDl'
import { useDispatch } from 'react-redux'
import { useAuthState } from '../../../../../Helper/Context/context'
import { UserCreationMasterInsertAPI, UserCreationMasterUpdateAPI } from '../../../../../Helper/Redux/MasterSlice/UserCreationMasterSlice'

export const UserCreationMasterPopUp = (props) => {
    const { handleShowModal, handleIsPost, ModalData } = props

    const dispatch = useDispatch();
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [UserName, setUserName] = useState(ModalData.RowData ? ModalData.RowData.userName : '');
    const [Password, setPassword] = useState(ModalData.RowData ? ModalData.RowData.password : '');

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: 'English'
    })

    const [RoleDDLID, setRoleDDLID] = useState({
        DDL: [],
        ID: ModalData.Flag == 'Update' ? ModalData.RowData.m_RoleID : '0',
        Label: ModalData.Flag == 'Update' ? ModalData.RowData.roleName : 'Select...'
    })

    const [GenderDDLID, setGenderDDLID] = useState({
        DDL: [],
        ID: ModalData.Flag == 'Update' ? ModalData.RowData.m_IndicatorGrievanceAreaID : '0',
        Label: ModalData.Flag == 'Update' ? ModalData.RowData.indicatorName : 'Select'
    })

    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: ModalData.Flag == 'Update' ? ModalData.RowData.m_DistrictID : '0',
        Label: ModalData.Flag == 'Update' ? ModalData.RowData.distrcitName : 'Select'
    })

    const [DesignationDDLID, setDesignationDDLID] = useState({
        DDL: [],
        ID: ModalData.Flag == 'Update' ? ModalData.RowData.m_DesignationID : '0',
        Label: ModalData.Flag == 'Update' ? ModalData.RowData.designationName : 'Select'
    })

    const [EmployeeDDLID, setEmployeeDDLID] = useState({
        DDL: [],
        ID: ModalData.Flag == 'Update' ? ModalData.RowData.m_EmployeeID : '0',
        Label: ModalData.Flag == 'Update' ? ModalData.RowData.employeeName : 'Select'
    })

    // console.log('Flag',ModalData.Flag)

    const handleInsert = () => {
        const data = {
            M_RoleID: RoleDDLID.ID,
            M_IndicatorGrievanceAreaID: GenderDDLID.ID,
            M_DistrictID: DistrictDDLID.ID,
            M_DesignationID: DesignationDDLID.ID,
            M_EmployeeID: EmployeeDDLID.ID,
            UserName: UserName,
            Password: Password,
            M_UserID: UserID,
            token: token,
            handleShowModal: handleShowModal,
            handleIsPost: handleIsPost
        }
        dispatch(UserCreationMasterInsertAPI({ data }))
    }

    const handleUpdate = () => {
        const data = {
            M_TableUserID: ModalData.RowData.m_UserID,
            M_RoleID: RoleDDLID.ID,
            M_IndicatorGrievanceAreaID: GenderDDLID.ID,
            M_DistrictID: DistrictDDLID.ID,
            M_DesignationID: DesignationDDLID.ID,
            M_EmployeeID: EmployeeDDLID.ID,
            UserName: UserName,
            Password: Password,
            M_UserID: UserID,
            handleShowModal: handleShowModal,
            handleIsPost: handleIsPost,
            token: token,
        }
        dispatch(UserCreationMasterUpdateAPI({ data }))
    }

    const handleSubmit = () => {
        if (ModalData.Flag == 'Update') {
            handleUpdate()
        } else {
            handleInsert()
        }
    }

    return (
        <>
            <div className="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                style={{ display: "block", backgroundColor: "#0000008a" }} aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: '50%' }}>
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="heading fw-bold">User Creation</h5>
                            <button type="button" className="btn-close"
                                onClick={() => handleShowModal()}
                            >
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row p-2" style={{ height: '400px', overflowY: 'scroll' }}>

                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">Role : </label>
                                    <RoleDDL
                                        RoleDDLID={RoleDDLID}
                                        setRoleDDLID={setRoleDDLID}
                                        Flag={ModalData.Flag}
                                        data={ModalData.RowData}
                                    />
                                </div>

                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">Area : </label>
                                    <GenderDDL
                                        GenderDDLID={GenderDDLID}
                                        setGenderDDLID={setGenderDDLID}
                                        Language={Language}
                                        GroupID='1'
                                        Flag={ModalData.Flag}
                                        data={ModalData.RowData}
                                    />
                                </div>
                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">District : </label>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={GenderDDLID.ID}
                                        UserID={UserID}
                                        Flag={ModalData.Flag}
                                        data={ModalData.RowData}
                                    />
                                </div>
                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">Designation : </label>
                                    <DesignationDDL
                                        DesignationDDLID={DesignationDDLID}
                                        setDesignationDDLID={setDesignationDDLID}
                                        Flag={ModalData.Flag}
                                        data={ModalData.RowData}
                                    />
                                </div>
                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">Employee : </label>
                                    <EmployeeDDL
                                        EmployeeDDLID={EmployeeDDLID}
                                        setEmployeeDDLID={setEmployeeDDLID}
                                        Language={Language}
                                        DesignationDDLID={DesignationDDLID}
                                        Flag={ModalData.Flag}
                                        data={ModalData.RowData}
                                    />
                                </div>
                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">UserName : </label>
                                    <input type="text"
                                        placeholder="UserName.."
                                        className="rounded border border_div py-1"
                                        value={UserName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 col-md-6  rounded pt-3">

                                    <label for="branch" className="text-dark fw-bold">Password : </label>
                                    <input type="text"
                                        placeholder="Password.."
                                        className="rounded border border_div py-1"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="py-3 pt-4 px-0 float-end">
                                <button type="button" className="btn bg-success text-white px-4"
                                    disabled={RoleDDLID.ID == 0 || GenderDDLID.ID == 0 || DistrictDDLID.ID == 0 ||
                                        DesignationDDLID == 0 || EmployeeDDLID.ID == 0 || UserName == '' ||
                                        Password == ''}
                                    onClick={handleSubmit}
                                >{ModalData.Flag == 'Update' ? 'Update' : 'Add'}</button>

                                <button type="button" className="btn bg-danger text-white mx-3"
                                    onClick={() => handleShowModal()}
                                > Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}