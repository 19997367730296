
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { DistrictDDLAPI, GenderDDLAPI, TalukaDDLAPI, VillageDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const VillageDDL = (props) => {
    const { VillageDDLID, setVillageDDLID, Flag, data, Language, M_DistrictID, M_StateID, M_TalukaID, UserID } = props

    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_StateID: M_StateID,
            M_DistrictID: M_DistrictID,
            M_TalukaID: M_TalukaID,
            Language: Language.Label,
            M_UserID: UserID ? UserID : '0',
        }
        dispatch(VillageDDLAPI({ data }))
    }, [Language.Label, M_TalukaID])

    const { DDLData } = useSelector(state => state.VillageDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item) => ({
                value: item.valueID,
                label: item.valueParam,
            }))

            setVillageDDLID({
                DDL: list,
                ID: Flag == 'Update' ? data?.valueID : 0,
                Label: Flag == 'Update' ? data?.valueParam : "Select...",
            })
        }
        else {
            setVillageDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <Select
                id='VillageID'
                isClearable={false}
                isSearchable
                maxMenuHeight={150}
                value={{ value: VillageDDLID.ID, label: VillageDDLID.Label }}
                onChange={(e) => {
                    e ?
                        setVillageDDLID({ ...VillageDDLID, ID: e.value, Label: e.label })
                        :
                        setVillageDDLID({ ...VillageDDLID, ID: 0, Label: "Select..." })

                }}
                options={VillageDDLID.DDL}
            />
        </div>
    )
}