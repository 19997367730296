import { t } from "i18next"
import { useDispatch } from "react-redux";
import { useAuthState } from "../../../../../Helper/Context/context";
import { useState } from "react";


const DirectorViewFeedbackPopup = (props) => {

    const { handleFeedbackShowView, Title, ModalData, RowData } = props
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    console.log("fdbbdb", ModalData)
    return (
        <>
            <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
                <div className="modal-dialog modal-xl" role="document" style={{ width: '50vw' }} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">{Title}</h5>
                            <button onClick={handleFeedbackShowView} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        
                        <div className="col-10 mx-auto modal-body text-center py-4" >
                            <span> {ModalData.RowData.feedback ? ModalData.RowData.feedback : '-'} </span>
                        </div>

                    </div>
                </div>
            </div>
        </>);
}

export default DirectorViewFeedbackPopup;