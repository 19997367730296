import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom"
import { MunicipalCorporationActionReport, MunicipalCorporationOption, RuralActionReport, RuralOption } from "../../../../Helper/ChartDeta"
import { useAuthState } from "../../../../Helper/Context/context";
import { useEffect, useState } from "react";
import { t } from "i18next";

export const DirectorActionReport = (props) => {

    const { DDLFinantialYearID, DDLMonthID, Countdata, LableName, CheckCountData1 } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    // console.log("CheckCountData1",CheckCountData1 && CheckCountData1.ReceivedGrievanceStateNodal_MCTotalCount)
    // console.log("LableName",LableName)

    // const [CheckCountData, setCheckCountData] = useState([Countdata])

    const [CheckTrue, setCheckTrue] = useState(false)
    // const [CheckCountData1, setCheckCountData1] = useState([])
    // const [CheckLableData,setCheckLableData] = useState([])

    // function zeroTest(element) {
    //     return element === 0;
    // }

    // var allZeros

    // if (Countdata) {
    //     allZeros = CheckCountData.every(zeroTest);
    // }

    // const handleMunicipalCorporationActionReport = () => {
    //     if ((Countdata && Countdata.ReceivedGrievanceStateNodal_MCTotalCount == 0) && (Countdata && Countdata.ActionReportUploadStateNodal_MCTotalCount) && (Countdata && Countdata.PendingGrievanceStateNodal_MCTotalCount)) {
    //         setCheckTrue(true)
    //     }
    //     else {
    //         setCheckTrue(false)
    //     }

    // }
    // if ((Countdata && Countdata.ReceivedGrievanceStateNodal_RuralTotalCount == 0) || (Countdata && Countdata.ActionReportUploadStateNodal_RuralTotalCount) && (Countdata && Countdata.PendingGrievanceStateNodal_RuralTotalCount)) {
    //     setCheckTrue(true)
    // }
    // console.log("CheckTrue", CheckTrue);
    // console.log("CheckTrue", Countdata.ReceivedGrievanceStateNodal_MCTotalCount);

    // useEffect(() => {
    //     handleMunicipalCorporationActionReport()
    // }, [Countdata])

    const MunicipalCorporationActionReport = [
        ["Task", "Hours per Day"],
        // [LableName && LableName.ReceivedGrievanceStateNodal_MCTotalCount, Countdata && Countdata.ReceivedGrievanceStateNodal_MCTotalCount],
        [LableName && LableName.ActionReportUploadStateNodal_MCTotalCount, Countdata && Countdata.ActionReportUploadStateNodal_MCTotalCount],
        [LableName && LableName.PendingGrievanceStateNodal_MCTotalCount, Countdata && Countdata.PendingGrievanceStateNodal_MCTotalCount],
    ];

    const MunicipalCorporationOption = {
        // title: "My Daily Activities",
        is3D: true,
        colors: ['#a654df', '#f75d94',],
        chartArea: {
            left: 60, // Customize the left margin
            top: 60, // Customize the top margin
            width: "80%", // Customize chart width
            height: "80%", // Customize chart height
        },
    };

    const RuralActionReport = [
        ["Task", "Hours per Day"],
        // [LableName && LableName.ReceivedGrievanceStateNodal_RuralTotalCount, Countdata && Countdata.ReceivedGrievanceStateNodal_RuralTotalCount],
        [LableName && LableName.ActionReportUploadStateNodal_RuralTotalCount, Countdata && Countdata.ActionReportUploadStateNodal_RuralTotalCount],
        [LableName && LableName.PendingGrievanceStateNodal_RuralTotalCount, Countdata && Countdata.PendingGrievanceStateNodal_RuralTotalCount],
    ];
    const RuralOption = {
        // title: "My Daily Activities",
        is3D: true,
        colors: ['#f88720', '#f75d94'],
        chartArea: {
            left: 60, // Customize the left margin
            top: 60, // Customize the top margin
            width: "80%", // Customize chart width
            height: "80%", // Customize chart height
        },
    };

    return (
        <>
            <div className="row px-1 pb-2 pt-3">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 ">
                        {t('DirectorDashboard.ActionReportUploadedforEscalatedGrievance')}
                        {/* Action Report Uploaded for Escalated Grievance */}
                    </h6>
                </div>
            </div>

            <div className="px-3  bg-white rounded">

                <div className="row pt-3 bg-white border-bottom">
                    <div className="col-12 ">
                        <h6 className="text-black  h8 pt-0 pb-2 fw_text">
                            {t('DirectorDashboard.FromMOHMunicipalCorporation')}
                            {/* By (<span className="fw-bold">MOH</span>) Municipal Corporation */}

                        </h6>
                    </div>

                    <div className="col-6">
                        <div className="row ">

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className=" card_design_purple cursor-pointer   shadow h-100 py-2"
                                    onClick={() => navigate(`/DirectorActionMunicipalCorporationGrid?screenheading=Action Report Uploaded for Escalated Grievance&screenname=Received&screensubheading=Municipal Corporation&Flag=ReceivedGrievanceStateNodal_MC &DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.ReceivedGrievanceStateNodal_MCTotalCount ? Countdata.ReceivedGrievanceStateNodal_MCTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold card_heading_purple pt-2">
                                                    {t('DirectorDashboard.ReceivedGrievance')}
                                                    {/* Received Grievance */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-file-invoice fa-2x text-orange-300"></i> */}
                                                <img src="assets/img/Dashboard_img/report.png" className="icon_size" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className=" card_design_pink shadow h-100 py-2 cursor-pointer"
                                    onClick={() => navigate(`/DirectorActionMunicipalCorporationGrid?screenheading=Action Report Uploaded for Escalated Grievance&screenname=Resolved&screensubheading=Municipal Corporation&Flag=ActionReportUploadStateNodal_MC &DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.ActionReportUploadStateNodal_MCTotalCount ? Countdata.ActionReportUploadStateNodal_MCTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold card_heading_pink pt-2">
                                                    {t('DirectorDashboard.UploadedActionReport')}
                                                    {/* Action Report Upload */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-clock fa-2x text-gray-300"></i> */}
                                                <img src="assets/img/Dashboard_img/completed-task.png" className="icon_size" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="card_design_orange  shadow h-100 py-2 cursor-pointer"
                                    onClick={() => navigate(`/DirectorActionMunicipalCorporationGrid?screenheading=Action Report Uploaded for Escalated Grievance&screenname=Pending&screensubheading=Municipal Corporation&Flag=PendingGrievanceStateNodal_MC &DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&MunicipalCarporationID=1`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.PendingGrievanceStateNodal_MCTotalCount ? Countdata.PendingGrievanceStateNodal_MCTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold card_heading_orange pt-2">
                                                    {t('DirectorDashboard.PendingGrievance')}
                                                    {/* Pending Grievance */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {/* <i className="fas fa-download fa-2x text-orange-300"></i> */}

                                                <img src="assets/img/Dashboard_img/upload_prnding.png" className="icon_size" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        {
                            CheckTrue ?
                                <div className='loadingcenter' style={{ height: '51vh', marginTop: '-3%' }}>
                                    <img
                                        src="assets/img/folder.png"
                                        style={{ height: "40px", marginLeft: "10px" }}
                                    /><label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                </div>

                                :
                                <Chart
                                    chartType="PieChart"
                                    data={MunicipalCorporationActionReport}
                                    options={MunicipalCorporationOption}
                                    width={"100%"}
                                    height={"400px"}
                                />
                        }
                    </div>
                </div>

                <div className="row bg-white pt-3">
                    <div className="col-12">
                        <h6 className="text-black  h8 pt-0 pb-2 fw_text">
                            {t('DirectorDashboard.FromCSRuralArea')}
                            {/* By (<span className="fw-bold">CS</span>) Rural Area */}
                        </h6>
                    </div>

                    <div className="col-6">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="shadow h-100 py-2 cursor-pointer" style={{ background: '#a654df', border: '2px solid rgb(143 5 239 / 21%)' }}
                                    onClick={() => navigate(`/DirectorActionRuralAreaGrid?screenheading=Action Report Uploaded for Escalated Grievance&screenname=Received&screensubheading=Rural Area&Flag=ReceivedGrievanceStateNodal_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.ReceivedGrievanceStateNodal_RuralTotalCount ? Countdata.ReceivedGrievanceStateNodal_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold  text-white pt-2 ">
                                                    {t('DirectorDashboard.ReceivedGrievance')}
                                                    {/* Received Grievance */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-circle-check fa-2x text-orange-300 icon_bg_2"></i>
                                                {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="shadow h-100 py-2 cursor-pointer" style={{ background: '#fc8720', border: '2px solid rgb(183 89 5 / 29%)' }}
                                    onClick={() => navigate(`/DirectorActionRuralAreaGrid?screenheading=Action Report Uploaded for Escalated Grievance&screenname=Resolved&screensubheading=Rural Area&Flag=ActionReportUploadStateNodal_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.ActionReportUploadStateNodal_RuralTotalCount ? Countdata.ActionReportUploadStateNodal_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold text-white pt-2">
                                                    {t('DirectorDashboard.UploadedActionReport')}
                                                    {/* Action Report Upload */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i class="fa-solid fa-thumbs-up fa-2x text-gray-300 icon_bg_2"></i>
                                                {/* <i className="fas fa-circle-check fa-2x text-gray-300 icon_bg_2"></i> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="  shadow h-100 py-2 cursor-pointer" style={{ background: '#ff5d94', border: '2px solid rgb(241 0 82 / 19%)' }}
                                    onClick={() => navigate(`/DirectorActionRuralAreaGrid?screenheading=Action Report Uploaded for Escalated Grievance&screenname=Pending&screensubheading=Rural Area&Flag=PendingGrievanceStateNodal_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}&RuralAreaID=2`)}
                                >
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h3 mb-2 fw-bold text-white">{Countdata.PendingGrievanceStateNodal_RuralTotalCount ? Countdata.PendingGrievanceStateNodal_RuralTotalCount : '0'}</div>
                                                <div className="text-xs font-weight-bold text-white pt-2">
                                                    {t('DirectorDashboard.PendingGrievance')}
                                                    {/* Pending Grievance */}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clock fa-2x text-orange-300 icon_bg_2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-6">
                        {/* {
                            CheckTrue ?
                                <div className='loadingcenter' style={{ height: '51vh', marginTop: '-3%' }}>
                                    <img
                                        src="assets/img/folder.png"
                                        style={{ height: "40px", marginLeft: "10px" }}
                                    />
                                    <label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                </div>

                                : */}
                        <Chart
                            chartType="PieChart"
                            data={RuralActionReport}
                            options={RuralOption}
                            width={"100%"}
                            height={"400px"}
                        />
                        {/* } */}
                    </div>

                </div>
            </div>

        </>
    )
}