import Select from 'react-select'
import { useState } from "react"
import { DesignationDDL } from '../../../../../Components/CommonDDL/DesignationDDL'
import { EmployeeMasterInsertAPI, EmployeeMasterUpdateAPI } from '../../../../../Helper/Redux/MasterSlice/EmployeeMasterSlice'
import { useDispatch } from 'react-redux'
import { useAuthState } from '../../../../../Helper/Context/context'

export const EmployeeMasterPopUp = (props) => {
    const { handleShowModal, handleIsPost, ModalData } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [EmployeeName, setEmployeeName] = useState(ModalData.Flag == 'Update' ? ModalData.RowData.employeeName : '')

    const [DesignationDDLID, setDesignationDDLID] = useState({
        DDL: [],
        ID: ModalData.Flag == 'Update' ? ModalData.RowData.m_DesignationID : 0,
        Label: ModalData.Flag == 'Update' ? ModalData.RowData.designationName : 'Select...'
    })

    const handleInsert = () => {
        const data = {
            M_DesignationID: DesignationDDLID.ID,
            EmployeeName: EmployeeName,
            M_UserID: UserID,
            token: token,
            handleShowModal: handleShowModal,
            handleIsPost: handleIsPost
        }
        dispatch(EmployeeMasterInsertAPI({ data }))
    }

    const handleUpdate = () => {
        const data = {
            M_DesignationID: DesignationDDLID.ID,
            M_EmployeeID: ModalData.RowData.m_EmployeeID,
            EmployeeName: EmployeeName,
            M_UserID: UserID,
            token: token,
            handleShowModal: handleShowModal,
            handleIsPost: handleIsPost
        }
        dispatch(EmployeeMasterUpdateAPI({ data }))
    }

    const handleSubmit = () => {
        if (ModalData.Flag == 'Update') {
            handleUpdate()
        } else {
            handleInsert()
        }
    }

    return (
        <>
            <div className="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                style={{ display: "block", backgroundColor: "#0000008a" }} aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: '50%' }}>
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="heading fw-bold">Employee Master</h5>
                            <button type="button" className="btn-close"
                                onClick={() => handleShowModal()}
                            >
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row p-2">

                                <div className="col-12 col-md-6  rounded ">

                                    <label for="branch" className="text-dark fw-bold pt-3">Designation : </label>
                                    <DesignationDDL
                                        DesignationDDLID={DesignationDDLID}
                                        setDesignationDDLID={setDesignationDDLID}
                                        Flag='Update'
                                        data={ModalData.RowData}
                                    />
                                </div>
                                <div className=" col-lg-6 pt-3">
                                    <label for="Status" className="text-dark fw-bold" >Employee Name</label>
                                    <input
                                        type="text"
                                        className="form-control inputstyle"
                                        placeholder=""
                                        aria-label="EmployeeName"
                                        aria-describedby="addon-wrapping"
                                        value={EmployeeName}
                                        onChange={(e) => setEmployeeName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="py-3 pt-4 px-0 float-end">
                                <button type="button" className="btn bg-success text-white px-4"
                                    disabled={EmployeeName == '' || DesignationDDLID == 0}
                                    onClick={handleSubmit}
                                >{ModalData.Flag == 'Update' ? 'Update' : 'Add'}</button>

                                <button type="button" className="btn bg-danger text-white mx-3"
                                    onClick={() => handleShowModal()}
                                > Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}