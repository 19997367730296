import { t } from "i18next"
import { useNavigate } from "react-router"

export const MainDashboardContent = ({DDLFinantialYearID,DDLMonthID,Countdata}) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="row pt-3">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                        {t('DirectorDashboard.ReceivedGrievance')}
                        {/* Received Grievance */}
                    </h6>
                </div>
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_purple shadow h-100 py-2" 
                    onClick={() => navigate(`/TotalReceivedGrienavces?screenname=Total Received Grievance&Flag=Received&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold mb-1 card_heading_purple ">
                                        {t('DirectorDashboard.TotalReceivedGrievance')}
                                        {/* Total Received Grievance */}
                                    </div>
                                    <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">{Countdata.ReceivedGrievanceTotalCount ? Countdata.ReceivedGrievanceTotalCount :'0'}</div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                                    <img src="assets/img/Dashboard_img/received.png" className="icon_size" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_pink shadow h-100 py-2" 
                       onClick={() => navigate(`/TotalReceivedGrienavces?screenname=Acknowledge Grievance&Flag=ACK&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold card_heading_pink mb-1">
                                        {t('DirectorDashboard.AcknowledgeGrievance')}
                                        {/* Acknowledged Grievance */}
                                    </div>
                                    <div className="h3 mb-0 pt-2 font-weight-bold text-gray-800">{Countdata.AcknowledgeGrievanceTotalCount ? Countdata.AcknowledgeGrievanceTotalCount :'0'}</div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-dollar-sign fa-2x text-gray-300"></i> */}
                                    <img src="assets/img/Dashboard_img/acknowledge.png" className="icon_size" alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_orange  shadow h-100 py-2" 
                      onClick={() => navigate(`/TotalReceivedGrienavces?screenname=Acknowledge Pending&Flag=Pending&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold card_heading_orange mb-1">
                                        {t('DirectorDashboard.AcknowledgePending')}
                                        {/* Acknowledge Pending */}
                                    </div>
                                    <div className="h3 mb-0 pt-2 font-weight-bold   text-gray-800">{Countdata.AcknowledgePendingTotalCount ? Countdata.AcknowledgePendingTotalCount :'0'}</div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-comments fa-2x text-gray-300"></i> */}
                                    <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr_line" />
            <div className="row ">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                    {t('DirectorDashboard.SiteVerification')}
                    </h6>
                </div>
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="shadow h-100 py-2 cursor_pointer" style={{ background: '#a654df'}}
                      onClick={() => navigate(`/SiteVerification?screenname=Acknowledged Grievance&Flag=ReceivedVisitACK&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.SiteVisitAcknowledgeGrievanceTotalCount ? Countdata.SiteVisitAcknowledgeGrievanceTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold  text-white pt-2 ">
                                        {t('DirectorDashboard.AcknowledgeGrievance')}
                                        {/* Acknowledged Grievance */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-thumbs-up fa-2x text-orange-300 icon_bg_2"></i>
                                    {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="shadow h-100 py-2 cursor_pointer" style={{ background: '#fc8720'}}
                   onClick={() => navigate(`/SiteVerification?screenname=Completed Site Visit&Flag=VisitCompleted&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.SiteVisitCompletedTotalCount ? Countdata.SiteVisitCompletedTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold text-white pt-2">
                                        {t('DirectorDashboard.CompletedSiteVisit')}
                                        {/* Completed Site Visit */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-circle-check fa-2x text-gray-300 icon_bg_2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="  shadow h-100 py-2 cursor_pointer" style={{ background: '#ff5d94'}}
                       onClick={() => navigate(`/SiteVerification?screenname=Pending Site Visit&Flag=VisitPending&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.SiteVisitPendingTotalCount ? Countdata.SiteVisitPendingTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold text-white pt-2">
                                        {t('DirectorDashboard.PendingSiteVisit')}
                                        {/* Pending Site Visit  */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-clock fa-2x text-orange-300 icon_bg_2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr_line" />
            <div className="row ">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                        {/* Action Report Upload */}
                        {t('DirectorDashboard.ReslovedGrievance')}
                        {/* Resloved Grievance */}
                    </h6>
                </div>
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className=" card_design_purple  cursor_pointer shadow h-100 py-2" 
                     onClick={() => navigate(`/ActionReportUpload?screenname=Completed Site Visit&Flag=VisitCompleted&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.ActionReportSiteVisitCompletedTotalCount ? Countdata.ActionReportSiteVisitCompletedTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold card_heading_purple pt-2">
                                    {t('DirectorDashboard.CompletedSiteVisit')}
                                        {/* Completed Site Visit */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-file-invoice fa-2x text-orange-300"></i> */}
                                    <img src="assets/img/Dashboard_img/report.png" className="icon_size" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_pink shadow h-100 py-2" 
                     onClick={() => navigate(`/ActionReportUpload?screenname=Resolved Grievance&Flag=ActionReport&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.UploadActionReportTotalCount ? Countdata.UploadActionReportTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold card_heading_pink pt-2">
                                        {t('DirectorDashboard.ReslovedGrievance')}
                                        {/* Upload Action Report */}
                                        {/* Resloved Grievance */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-clock fa-2x text-gray-300"></i> */}
                                    <img src="assets/img/Dashboard_img/completed-task.png" className="icon_size" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_orange  shadow h-100 py-2" 
                     onClick={() => navigate(`/ActionReportUpload?screenname=Pending  Grievance&Flag=ReportPending&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.UplodReportPendingTotalCount ? Countdata.UplodReportPendingTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold card_heading_orange pt-2">
                                        {t('DirectorDashboard.PendingGrievance')}
                                        {/* Uploded Pending */}
                                        {/* Pending  Grievance */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-download fa-2x text-orange-300"></i> */}

                                    <img src="assets/img/Dashboard_img/upload_prnding.png" className="icon_size" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr_line" />
            <div className="row ">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                        {t('DirectorDashboard.FeedbackGivenbyRegisterCitizen')}
                        {/* Feedback Given by Citizen */}
                    </h6>
                </div>
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer shadow h-100 py-2" style={{ background: '#a654df'}}
                      onClick={() => navigate(`/FeedbackGivenbyCitizen?screenname=Resolved Grievance&Flag=CitizenFeedbackActionReport&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.CitizenFeedbackActionReportTotalCount ? Countdata.CitizenFeedbackActionReportTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold  text-white pt-2 ">
                                    {t('DirectorDashboard.ReslovedGrievance')}
                                        {/* Resloved Grievance */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-thumbs-up fa-2x text-orange-300 icon_bg_2"></i>
                                    {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer shadow h-100 py-2" style={{ background: '#fc8720'}}
                      onClick={() => navigate(`/FeedbackGivenbyCitizen?screenname=Feedback Given by Citizen&Flag=FeedbackByCitizen&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.FeedbackbyCitizenTotalCount ? Countdata.FeedbackbyCitizenTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold text-white pt-2">
                                        {t('DirectorDashboard.FeedbackGivenbyCitizen')}
                                        {/* Feedback Given by Citizen */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i class="fa-solid fa-star-half-stroke fa-2x text-gray-300 icon_bg_2"></i>
                                    {/* <i className="fas fa-circle-check fa-2x text-gray-300 icon_bg_2"></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className=" cursor_pointer shadow h-100 py-2" style={{ background: '#ff5d94'}}
                       onClick={() => navigate(`/FeedbackGivenbyCitizen?screenname=Feedback Pending&Flag=FeedbackPending&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.FeedbackPendingTotalCount ? Countdata.FeedbackPendingTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold text-white pt-2">
                                        {t('DirectorDashboard.FeedbackPending')}
                                        {/* Feedback Pending */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-clock fa-2x text-orange-300 icon_bg_2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr_line" />
            <div className="row ">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                        {/* Escalated Grievances */}
                        {t('DirectorDashboard.EscalatedGrievancesToStateNodalOfficer')}
                        {/* Escalated Grievances To State Nodal  Officer  */}
                    </h6>
                </div>
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className=" cursor_pointer card_design_purple   shadow h-100 py-2" 
                      onClick={() => navigate(`/EscalatedGrievances?screenname=Escalated Grievances&Flag=EscalatedReceivedByStateNodal&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.EscalatedReceivedByStateNodalTotalCount ? Countdata.EscalatedReceivedByStateNodalTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold card_heading_purple pt-2">
                                        {t('DirectorDashboard.EscalatedGrievances')}
                                        {/* Escalated Grievances */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-file-invoice fa-2x text-orange-300"></i> */}
                                    <img src="assets/img/Dashboard_img/upload_action.png" className="icon_size" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_pink shadow h-100 py-2" 
                    onClick={() => navigate(`/EscalatedGrievances?screenname=Resolved Grivances&Flag=ResolvedByStateNodal&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.ResolvedByStateNodalTotalCount ? Countdata.ResolvedByStateNodalTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold card_heading_pink pt-2">
                                        {t('DirectorDashboard.ReslovedGrievance')}
                                        {/* Received Grivances */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-clock fa-2x text-gray-300"></i> */}

                                    <img src="assets/img/Dashboard_img/creative-team.png" className="icon_size" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer card_design_orange  shadow h-100 py-2" 
                    onClick={() => navigate(`/EscalatedGrievances?screenname=Pending Grievances&Flag=PendingByStateNodal&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.PendingByStateNodalTotalCount ? Countdata.PendingByStateNodalTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold card_heading_orange pt-2">
                                        {t('DirectorDashboard.PendingGrievance')}
                                        {/* Pending Grievances */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <i className="fas fa-download fa-2x text-orange-300"></i> */}
                                    <img src="assets/img/Dashboard_img/pending1.png" className="icon_size" alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr_line" />
            <div className="row ">
                <div className="col-12">
                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                        {t('DirectorDashboard.EscalatedResolvedGrievances')}
                    {/* Escalated Resolved Grievances */}
                    </h6>
                </div>
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer shadow h-100 py-2" style={{ background: '#a654df'}}
                      onClick={() => navigate(`/EscalatedGrievanceActionReportUpload?screenname=Received Grievances&Flag=ResolvedByStateNodal&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.EscalatedReceivedGrievancesTotalCount ? Countdata.EscalatedReceivedGrievancesTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold  text-white pt-2 ">
                                        {t('DirectorDashboard.ReceivedGrievanceByStateNodalOfficer')}
                                        {/* Received Grievance */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-circle-check fa-2x text-orange-300 icon_bg_2"></i>
                                    {/* <img src="assets/img/Dashboard_img/more.png" className="icon_size" alt="" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="cursor_pointer shadow h-100 py-2" style={{ background: '#fc8720'}}
                      onClick={() => navigate(`/EscalatedGrievanceActionReportUpload?screenname=Resolved Grievances&Flag=EscalatedResolvedGrievances&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.EscalatedResolvedGrievancesTotalCount ? Countdata.EscalatedResolvedGrievancesTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold text-white pt-2">
                                        {t('DirectorDashboard.ReslovedGrievance')}
                                        {/* Resolved Grievances */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i class="fa-solid fa-square-check fa-2x text-gray-300 icon_bg_2"></i>
                                    {/* <i className="fas fa-circle-check fa-2x text-gray-300 icon_bg_2"></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className=" cursor_pointer shadow h-100 py-2" style={{ background: '#ff5d94'}}
                       onClick={() => navigate(`/EscalatedGrievanceActionReportUpload?screenname=Pending Grievances&Flag=EscalatedPendingGrievances&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h3 mb-2 fw-bold text-white">{Countdata.EscalatedPendingGrievancesTotalCount ? Countdata.EscalatedPendingGrievancesTotalCount :'0'}</div>
                                    <div className="text-xs font-weight-bold text-white pt-2">
                                        {t('DirectorDashboard.PendingGrievance')}
                                        {/* Pending Grievances */}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-clock fa-2x text-orange-300 icon_bg_2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            {/* <!-- Content Row --> */}
        </>
    )
}