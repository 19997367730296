import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Header } from "../../../Header/Header"
import { SideBar } from "../../../SideBar/SideBar"
import { Footer } from "../../../../Footer/Footer"


export const ReceivedGrievance = () => {
    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: 'Marathi'
    })
    return (
        <>
            <Header Language={Language}
                setLanguage={setLanguage} />
            {/* <SideBar active="MainDashboard" /> */}
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="MainDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >
                    <div className="row ">

                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="text-black fw-bold ps-2 pt-1">
                                {/* {t('DirectorDashboard.Dashboard')} */}
                            </span>
                            {/* <div class="btn-group  " role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
                                <label class="btn btn-outline-danger parrot_green_border text-black RADIUS_LEFT" for="btnradio1"
                                    onClick={() => navigate('/DirectorDashboard')}
                                >
                                    Add Director
                                </label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked />
                                <label class="btn btn-outline-danger parrot_green" for="btnradio2"
                                    onClick={() => navigate('/MainDashboard')}>
                                    MOH | CS
                                </label>
                            </div> */}
                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                        {screenname}
                                    </h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">Sr. No.</th>
                                                    <th className=" ps-2">District Name</th>
                                                    <th className=" ps-2">Muncipal Corporation</th>
                                                    <th className=" ps-2">Grievance Type</th>
                                                    <th className=" ps-2 text-center">Grievance Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="w-7">-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td className="text-center w-20">
                                                        <i class="fa-solid fa-eye"></i>
                                                        {/* <span className="cursor-pointer"
                                            onClick={() => handleShowModal('Update')}
                                        >
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </span>
                                        <span className="cursor-pointer"
                                            onClick={() => handleShowDelete()}
                                        >
                                            <i class="fa-solid fa-trash mx-4"></i>
                                        </span> */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}