import { t } from "i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { DirectorReceivedGrievance } from "./DirectorReceivedGrievance"
import { DirectorResolvedGrievance } from "./DirectorResolvedGrievance"
import { DirectorEscalatedGrievance } from "./DirectorEscalatedGrievance"
import { DirectorActionReport } from "./DirectorActionReport"
import { DirectorFeedback } from "./DirectorFeedback"

export const DirectorDashboardContent = (props) => {
    const { DDLFinantialYearID, DDLMonthID, Countdata,LableName,CheckCountData1 } = props
    const Navigate = useNavigate()
    return (

        <>
            <DirectorReceivedGrievance
                DDLFinantialYearID={DDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                Countdata={Countdata}
                LableName={LableName}
                CheckCountData1={CheckCountData1}
            />

            <DirectorResolvedGrievance
                DDLFinantialYearID={DDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                Countdata={Countdata}
                LableName={LableName}
                CheckCountData1={CheckCountData1}
            />

            <DirectorEscalatedGrievance
                DDLFinantialYearID={DDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                Countdata={Countdata}
                LableName={LableName}
                CheckCountData1={CheckCountData1}
            />

            <DirectorActionReport
                DDLFinantialYearID={DDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                Countdata={Countdata}
                LableName={LableName}
                CheckCountData1={CheckCountData1}
            />

            <DirectorFeedback
                DDLFinantialYearID={DDLFinantialYearID}
                DDLMonthID={DDLMonthID}
                Countdata={Countdata}
                LableName={LableName}
                CheckCountData1={CheckCountData1} />

            {/* </div> */}
            {/* <!-- Content Row --> */}
        </>
    )
}