
var url = window.location.origin

export const BaseUrl =
    url === "http://amchimulgi-maha.in" ? "http://api.amchimulgi-maha.in" :
        url === "http://amchimulgimaha.in" ? "http://api.amchimulgimaha.in" :
            url === "https://amchimulgimaha.in" ? "https://api.amchimulgimaha.in" :
                url === "http://demo.amchimulgimaha.in" ? "http://apidemo.amchimulgimaha.in" :
                    url === "https://amchimulgimaha.cdat.work" ? "https://apiamchimulgimaha.cdat.work" :
                        "https://apiamchimulgimaha.cdat.work"
// "http://amchimulagiapi.cdatinfotech.com"
// "http://apidemo.amchimulgimaha.in"
// "http://api.amchimulgimaha.in"