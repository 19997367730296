

// *****************Received Grievance***********

// export const Pieoptions = {
//     layout: {
//         padding: {

//         }
//     },
//     legend: {
//         display: false
//     },
//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black'
//         },
//         outlabels: {
//             color: "black",
//             stretch: 12,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16
//             }
//         },
//     },
// }



// export const Receiveddata = {
//     labels: [ "Municiple Corporation", "Rural Area", "Received"],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [ 20, 30, 50],
//             backgroundColor: [
//                 "#F5C091",
//                 "#F5A4C0",
//                 "#D7B4EF"

//             ],
//             borderColor: [

//             ],
//             borderWidth: 1,
//         },
//     ],
// };


// --**************start Received Grievance***********------

export const data = [
    ["Task", "Hours per Day"],
    [" Total Received Grievance", 10],
    ["Municipal Corporation", 6],
    ["Rural  Area", 11],

];

export const options = {
    // title: "My Daily Activities",
    is3D: true,
    legend: 'none'
};


// --**************Resolved Grievance Received Grievance***********------

export const ResolvedGrievanceMunicipalCorporation = [
    ["Task", "Hours per Day"],
    ["Received Grievance", 10],
    ["Resolved Grievance", 6],
    ["Pending Grievance", 11],

];

export const ResolvedGrievanceMunicipalCorporationoptions = {
    // title: "My Daily Activities",
    is3D: true,
    legend: 'none'
};


// *------------------------------

export const ResolvedGrievance = [
    ["Task", "Hours per Day"],
    ["Received Grievance", 10],
    ["Resolved Grievance", 6],
    ["Pending Grievance", 11],

];

export const ResolvedGrievanceoptions = {
    // title: "My Daily Activities",
    is3D: true,
    legend: 'none'
};



// *****************Resolved Grievance***********


// export const MunicipalCorporation = {
//     layout: {
//         padding: {
//             top: 10,
//             bottom: 0
//         }
//     },
//     legend: {
//         display: false
//     },
//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black'
//         },
//         outlabels: {
//             color: "black",
//             stretch: 12,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16
//             }
//         },
//     },
// }



// export const MunicipalCorporationdata = {
//     labels: [ "Pending", "Resolved", "Received"],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [ 20, 30, 50],
//             backgroundColor: [
//                 "#F5C091",
//                 "#F5A4C0",
//                 "#D7B4EF"

//             ],
//             borderColor: [
//                 // '#28c8c1',
//                 // '#172b4d',
//                 // '#993366',
//                 // '#593e89',
//                 // '#94408e',
//                 // '#c74382',
//                 // '#fa5d00',
//                 // '#529ad2',
//                 // '#ffc137',
//             ],
//             borderWidth: 1,
//         },
//     ],
// };


// export const RuralArea = {
//     layout: {
//         padding: {
//             top: 10,
//             bottom: 0
//         }
//     },
//     legend: {
//         display: false
//     },
//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black'
//         },
//         outlabels: {
//             color: "black",
//             // text: "%l\n%v (%p)",
//             stretch: 12,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16
//             }
//         },
//     },
// }



// export const RuralAreadata = {
//     labels: [   "Resolved", "Pending", "Received"],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [ 20, 50, 30],
//             backgroundColor: [
//                 "#F5C091",
//                 "#F5A4C0",
//                 "#D7B4EF"   

//             ],
//             borderColor: [
//                 // '#28c8c1',
//                 // '#172b4d',
//                 // '#993366',
//                 // '#593e89',
//                 // '#94408e',
//                 // '#c74382',
//                 // '#fa5d00',
//                 // '#529ad2',
//                 // '#ffc137',
//             ],
//             borderWidth: 1,
//         },
//     ],
// };





// ************************bar graph start*************************

export const Municipal = {


    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {
      
        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            barThickness: 35,
            ticks: {
                autoSkip: false,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
                
            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            ticks: {
                beginAtZero: true,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
            }
        }]
    }
}

export const CommonData = {

    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {
      
        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            barThickness: 35,
            ticks: {
                autoSkip: false,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
                maxRotation: 90,
                minRotation: 90,
            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            ticks: {
                beginAtZero: true,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
            }
        }]
    }
}


export const Rural = {


    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            barThickness: 35,
            ticks: {
                autoSkip: false,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            ticks: {
                beginAtZero: true,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
            }
        }]
    }
}



// --**************start Action Report Uploaded for Escalated Grievance***********------


export const MunicipalCorporationActionReport = [
    ["Task", "Hours per Day"],
    ["Received Grievance", 10],
    ["Action Report Upload", 6],
    ["Pending Grievance", 11],

];

export const MunicipalCorporationOption = {
    // title: "My Daily Activities",
    is3D: true,
};



// *------------------------


export const RuralActionReport = [
    ["Task", "Hours per Day"],
    ["Received Grievance", 10],
    ["Action Report Upload", 6],
    ["Pending Grievance", 11],

];

export const RuralOption = {
    // title: "My Daily Activities",
    is3D: true,
};