import { useNavigate, useSearchParams } from "react-router-dom"
import { RegistrationHeader } from "../RegistrationHeader/RegistrationHeader";
import { Footer } from "../Footer/Footer";
import { GrievanceAreaDetails } from "./GrievanceAreaDetails/GrievanceAreaDetails";
import { HospitalDetails } from "./HospitalDetails/HospitalDetails";
import { GrievanceDetails } from "./GrievanceDetails/GrievanceDetails";
import { useState } from "react";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { GrievanceRegistrationPostAPI } from "../../Helper/Redux/Registration/RegistrationSlice";
import { Header } from "../Private/Header/Header";

export const WithoutRegistration = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [searchparams] = useSearchParams()
    const CitizenName = searchparams.get('CitizenName')
    const UserName = searchparams.get('UserName')
    const [IsClear, setIsClear] = useState(false)

    const [GrievanceField, setGrievanceField] = useState({
        CenterName: '',
        AreaName: '',
        AreaAdress: '',
        PatientAddress: '',
        PatientName: '',
        ReferredDoctorName: '',
        DoctorAddress: '',
        DoctorName: '',
        HospitalAddress: '',
        HospitalName: '',
        GrievanceDetails: '',
        VanNumber: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: 'English'
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: '0',
        Label: 'Select...'
    })
    const [GenderDDLID, setGenderDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [TalukaDDLID, setTalukaDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [VillageDDLID, setVillageDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setGrievanceField(item => ({
            ...item, [name]: value
        }))
    }

    const handleClear = () => {
        setIsClear(!IsClear)
        setGrievanceField({
            CenterName: '',
            AreaName: '',
            AreaAdress: '',
            PatientAddress: '',
            PatientName: '',
            ReferredDoctorName: '',
            DoctorAddress: '',
            DoctorName: '',
            HospitalAddress: '',
            HospitalName: '',
            GrievanceDetails: '',
            VanNumber: '',
        })
        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGenderDDLID({
            ...GenderDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })
        setTalukaDDLID({
            ...TalukaDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setVillageDDLID({
            ...VillageDDLID,
            ID: 0,
            Label: 'Select...'
        })
    }


    const handleRegister = () => {
        const data = {
            M_IndicatorGrievanceAreaID: GenderDDLID.ID,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: TalukaDDLID.ID,
            M_VillageID: VillageDDLID.ID,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_UserID: '0',
            GrievanceField: GrievanceField,
            handleClear: handleClear
        }
        dispatch(GrievanceRegistrationPostAPI({ data }))
    }


    return (
        <>
            <div className="conatiner-fluid  px-2 px-lg-4 py-2" onLoad={() => window.scrollTo(0, 0)}>

                <RegistrationHeader
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="row" style={{ paddingTop: '80px' }}>

                    <div className="col-12 col-lg-6 pt-5 px-3 px-lg-3">
                        <h5 className="text-black fw-bold">
                            {t('GrievanceRegistration.GrievanceRegistration')}
                        </h5>
                    </div>

                </div>

                <GrievanceAreaDetails
                    Language={Language}
                    DistrictDDLID={DistrictDDLID}
                    setDistrictDDLID={setDistrictDDLID}
                    GenderDDLID={GenderDDLID}
                    setGenderDDLID={setGenderDDLID}
                    TalukaDDLID={TalukaDDLID}
                    setTalukaDDLID={setTalukaDDLID}
                    VillageDDLID={VillageDDLID}
                    setVillageDDLID={setVillageDDLID}
                    GrievanceField={GrievanceField}
                    onChange={onChange}
                    handleClear={handleClear}
                />

                <HospitalDetails
                    GrievanceField={GrievanceField}
                    onChange={onChange}
                    handleClear={handleClear}
                />

                <GrievanceDetails
                    Language={Language}
                    GrievanceField={GrievanceField}
                    GrievanceTypeID={GrievanceTypeID}
                    setGrievanceTypeID={setGrievanceTypeID}
                    onChange={onChange}
                    handleClear={handleClear}
                />

                <div className="row">
                    <div className="col-12 pt-1 text-center">
                        <button
                            type="button"
                            className="btn bg-success text-white"
                            disabled={GenderDDLID.ID == '0' || DistrictDDLID.ID == '0' || GrievanceField.AreaName == '' ||
                                GrievanceField.AreaAdress == '' || GrievanceTypeID.ID == '0' || GrievanceField.GrievanceDetails == ''}
                            onClick={handleRegister}
                        >
                            {t('GrievanceRegistration.Register')}
                        </button>
                        <button type="button"
                            className="btn bg-danger text-white mx-2"
                            onClick={() => navigate('/')}
                        >
                            {t('GrievanceRegistration.Back')}
                        </button>
                    </div>
                </div>

                <Footer />

            </div>

        </>
    )
}