import { t } from "i18next"
import { useState } from "react"
import getBase64 from "../../../../Helper/FileToBase64"
import { GrievanceRegistrationWiseReportActionPatchAPI, SiteVerificationUploadImagePatchAPI } from "../../../../Helper/Redux/DashboardSlice/ReceivedGrievanceSlice"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../Helper/Context/context"

const UploadImage = (props) => {

    const { handleUploadFilePhoto, Title, ModalData, TableData, handleIsPost } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [DocumentUrl, setDocumentUrl] = useState('')
    const [UploadReportError, setUploadReportError] = useState(false)
    const [IsUploadReport, setIsUploadReport] = useState(false)
    const [UploadReportBase64, setUploadReportBase64] = useState(TableData ? TableData.UploadReportProof : '')
    const [NoteErrorMsg, setNoteErrorMsg] = useState(false)

    // const getUrltoBase64 = (data) => {
    //     getBase64FromUrlUploadReportProof(data.UploadReportProof)

    // }
    const handleShowDocument = (url) => {
        var extension = url.split('.').pop();
        // console.log(url)
        if (extension == '.jpg') {
            window.open(url)
        } else {
            setDocumentUrl(url)
            // setDocumentShow(true)
        }

    }
    const RequiredSize = 3145728
    const UploadReport = (target) => {
        // console.log(target.files[i].url)
        if (target) {
            if (target.files[0].size > RequiredSize) {
                setUploadReportError(true)
            }
            else {
                setUploadReportError(false)
                setIsUploadReport(true)
                getBase64(target.files[0])
                    .then(result => {
                        setUploadReportBase64(result)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
        else {
            setUploadReportError(false)
        }
    }
    const handleActionReport = () => {
        const data = {
            T_GrievanceRegistrationID: ModalData.RowData.t_GrievanceRegistrationID,
            VisitPhoto: UploadReportBase64,
            VisitRemark: '',
            token: token,
            M_UserID: UserID,
            handleShowConfirmation: handleUploadFilePhoto,
            handleIsPost: handleIsPost

        }
        dispatch(SiteVerificationUploadImagePatchAPI({ data }))
    }

    const { isPostLoading } = useSelector(state => state.SiteVerificationUploadImagePatchData)

    return (
        <>
            <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
                <div className="modal-dialog modal-xl" role="document"  >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">{Title}</h5>
                            <button onClick={handleUploadFilePhoto} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mx-4">
                            <div className="row" style={{ height: '50vh', overflow: "auto" }}>

                                {/* <div className="col-6"> */}
                                <div className="d-flex col-12 col-md-10 col-lg-12 ddlNationalw CountryWdual ">
                                    <div className="form-group col-10 " >
                                        <span className="all_lable_text ">Upload Image</span>
                                        <input
                                            type="file"                                          
                                            id="UploadReport"
                                            name="UploadReport"
                                            className="form-control mt-2"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => UploadReport(e.target)}
                                        />

                                    </div>                                 
                                    {
                                        UploadReportError && <text className="RegxValidation">Please upload less than 3 MB file</text>
                                    }
                                </div>
                               
                                
                            </div>
                            <div class="modal-footer">
                                <div className="row">
                                    <div className="col-12  d-flex justify-content-center">
                                        <div className="form-group w-80 d-flex justify-content-center">
                                            <button
                                                type='button'
                                                disabled={UploadReportBase64 == ''}
                                                onClick={() => handleActionReport()}
                                                className="btn bg-success text-white border-2 mx-2 px-4" data-ktwizard-type="action-next">
                                                Submit
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default UploadImage;