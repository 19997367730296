

const DeletePopUp = (props) => {

    const { handleShowDelete, handleDelete } = props

    return (<>
        <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">Delete</h5>
                        <button onClick={handleShowDelete} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-4">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center my-4">
                                <h6 className="text-black"> Are you sure! Do you want to Delete ?</h6>
                            </div>
                            <div className="col-12  d-flex justify-content-center">
                                <div className="form-group w-80 d-flex justify-content-center">
                                    <button
                                        type='button'
                                        onClick={() => handleDelete()}
                                        className="btn bg-success text-white border-2 mx-2 px-4" data-ktwizard-type="action-next">
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleShowDelete}
                                        className="btn btn-danger border-2" data-ktwizard-type="action-next">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default DeletePopUp;