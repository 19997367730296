import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Header } from "../../../Header/Header"
import { SideBar } from "../../../SideBar/SideBar"
import { Footer } from "../../../../Footer/Footer"
import ViewPopup from "../../../CommonPopUp/ViewPopup"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context/context"
import { DistrictDDL } from "../../../../../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../../../../../Components/CommonDDL/GenderDDL"
import { ReceivedResolvedGrievanceExportDataGetAPI, ReceivedResolvedGrievanceGetAPI } from "../../../../../Helper/Redux/DirectorDashboardSlice/ReceivedResolvedGrievanceSlice"
import { Pegination } from "../../../../../Components/Pagination/Pagination"
import { Loading } from "../../../../../Helper/Loader/LogoLoader"
import moment from "moment"
import { t } from "i18next"
import { DirectorReceivedGrievanceGridExportData } from "./DirectorReceivedGrievanceGridExportData"

export const DirectorReceivedGrievanceGrid = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [ShowConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)

    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    const DDLFinantialYearID = searchparams.get('DDLFinantialYearID')
    const DDLMonthID = searchparams.get('DDLMonthID')
    const screenheading = searchparams.get('screenheading')
    const screensubheading = searchparams.get('screensubheading')


    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    // Pagination
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceAreaID, setGrievanceAreaID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })


    const handleShowView = (rowData, t_GrievanceRegistrationID) => {

        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID,

        })
    }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setIsClear(!IsClear)

        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceAreaID({
            ...GrievanceAreaID,
            ID: 0,
            Label: 'Select...'
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {

            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: '',
            // M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_IndicatorGrievanceAreaID: 0,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceAreaID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
            handleGetExport: handleDirectorReceivedGrievanceExport
        }
        { Language.Label != null && dispatch(ReceivedResolvedGrievanceGetAPI({ data })) }
    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceAreaID.ID])

    const { TableData, isLoading } = useSelector(state => state.ReceivedResolvedGrievanceGetData)

    const handleDirectorReceivedGrievanceExport = () => {
        const data = {

            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: '',
            // M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_IndicatorGrievanceAreaID: 0,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceAreaID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: '99999',
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedResolvedGrievanceExportDataGetAPI({ data })) }
    }
    // , [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceAreaID.ID])

    const { ExportTableData, isExportLoading } = useSelector(state => state.ReceivedResolvedGrievanceExportDataGet)


    return (
        <>
            {isLoading && <Loading />}
            <Header Language={Language}
                setLanguage={setLanguage} />

            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="DirectorDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >

                    <div className="row px-2 pt-3">
                        <div className="col-12">
                            <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                {screenheading && screenheading == 'Received Grievance' ?
                                    t('DirectorDashboard.ReceivedGrievance') : ''}
                            </h6>
                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-10">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                        {/* {screensubheading} -  */}
                                        <span className="text-primary">
                                            {screenname == 'Total Received Grievance' ? t('DirectorDashboard.TotalReceivedGrievance') : screenname == 'Municipal Corporation' ? t('DirectorDashboard.MunicipalCorporation') : t('DirectorDashboard.RuralArea')}

                                        </span>
                                    </h6>
                                </div>
                                <div className="col-2">
                                    {
                                        isExportLoading ?
                                            <button type="button" className="btn btn-primary float-end mr-1 ml-1" disabled>
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />Loading</button>
                                            :
                                            TableData && TableData.length > 0 &&
                                            <DirectorReceivedGrievanceGridExportData
                                                ExcelData={ExportTableData}
                                                name={screenname}
                                                screenname={screenname}
                                                handleShowView={handleShowView}
                                                ModalData={ModalData}
                                            />
                                    }
                                </div>
                            </div>

                            <div className="row py-3 py-lg-4">

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('GrievanceRegistration.GrievanceArea')}
                                        {/* Grievance Area */}
                                    </span>
                                    <GenderDDL
                                        GenderDDLID={GrievanceAreaID}
                                        setGenderDDLID={setGrievanceAreaID}
                                        Language={Language}
                                        GroupID='1'
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.DistrictName')}
                                    </span>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={GrievanceAreaID.ID}
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-3 pt-2 mt-3  ">
                                    <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                        onClick={handleSearch}
                                    >
                                        Search</button>

                                    <button type="button" className="btn btn-danger border-2 button-size"
                                        onClick={handleClear}
                                    >Clear</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">{t('GridHeading.Sr_No')}</th>
                                                    <th className="">{t('GridHeading.ComplaintID')}</th>
                                                    <th className="">{t('DirectorDashboard.ReceivedDate')}</th>


                                                    {
                                                        screenname == 'Rural Area' ?
                                                            <>
                                                                <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                                <th className=" ps-2">{t('DirectorDashboard.TalukaName')}</th>
                                                                {/* <th className=" ps-2">Village Name</th> */}
                                                            </>
                                                            :
                                                            <>

                                                                <th className="ps-2 w-9">{t('DirectorDashboard.MunicipalCorporation')}</th>
                                                                <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                            </>
                                                    }


                                                    <th className=" ps-2">{t('DirectorDashboard.GrievanceType')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.GrievanceDetails')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.OfficerName')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length > 0 ?
                                                        TableData.map((i, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td className="w-7">{i?.rowNum}</td>
                                                                    <td className="w-7">{i.t_GrievanceRegistrationID ? i.t_GrievanceRegistrationID : '-'}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{i.transactionDate ? moment(i.transactionDate).format('DD-MM-YYYY') : '-'}</td>

                                                                    {
                                                                        screenname == 'Rural Area' ?
                                                                            <>
                                                                                <td>{i.districtName ? i.districtName : '-'}</td>
                                                                                <td>{i.talukaName ? i.talukaName : '-'}</td>
                                                                                {/* <td>{i.villageName ? i.villageName : '-'}</td> */}

                                                                            </>
                                                                            :
                                                                            <>

                                                                                <td>{i.districtName ? i.districtName : '-'}</td>
                                                                                <td>{'-'}</td>
                                                                            </>
                                                                    }

                                                                    <td>{i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</td>


                                                                    <td className="text-center w-20">
                                                                        <span className="view_button"
                                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                            title="View Grievance Details"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}>
                                                                            <i class="fa-solid fa-eye"></i>
                                                                        </span>

                                                                    </td>
                                                                    <td>{i.officerName ? i.officerName : '-'}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        : <>Record Not Found</>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {TableData && TableData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TableData[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>

            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}

                />
            }
            {/* <Footer /> */}
        </>
    )
}