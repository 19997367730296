import { useNavigate } from "react-router-dom"
import { useAuthState } from "../../../../Helper/Context/context";
import { t } from "i18next";

export const DirectorFeedback = (props) => {

    const { DDLFinantialYearID, DDLMonthID, Countdata, LableName } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails


    return (
        <>
            <div className="bg-white px-3 rounded">
                <div className="row bg-white rounded  my-3">
                    <div className="col-12">
                        <h6 className="text-black fw-bold h7 pt-3 pb-2">
                            {t('DirectorDashboard.FeedbackbyRegisteredCitizen')}
                            {/* Feedback by Registered Citizen */}
                        </h6>
                    </div>

                    <div className="col-xl-4 col-md-6 mb-4 px-3 cursor-pointer">
                        <div className=" card_design_purple   shadow h-100 py-2"
                            onClick={() => navigate(`/DirectorCitizenFeedbackGrid?screenheading=Feedback by Registered Citizen&screenname=Resolved&screensubheading=Municipal Corporation&Flag=AllResolvedGrievance&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}

                        >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.AllResolvedGrievanceTotalCount ? Countdata.AllResolvedGrievanceTotalCount : '0'}</div>
                                        <div className="text-xs font-weight-bold card_heading_purple pt-2">
                                            {t('DirectorDashboard.ReslovedGrievance')}
                                            {/* Resolved Grievance */}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {/* <i className="fas fa-file-invoice fa-2x text-orange-300"></i> */}
                                        <img src="assets/img/Dashboard_img/upload_action.png" className="icon_size" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-6 mb-4">
                        <div className=" card_design_pink shadow h-100 py-2 cursor-pointer"
                            onClick={() => navigate(`/DirectorCitizenFeedbackGrid?screenheading=Feedback by Registered Citizen&screenname=Feedback&screensubheading=Municipal Corporation&Flag=FeedbackGivenByCitizen&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}
                        >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.FeedbackGivenByCitizenTotalCount ? Countdata.FeedbackGivenByCitizenTotalCount : '0'}</div>
                                        <div className="text-xs font-weight-bold card_heading_pink pt-2">
                                            {t('DirectorDashboard.FeedbackGivenbyCitizen')}
                                            {/* Feedback Given by Citizen */}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {/* <i className="fas fa-clock fa-2x text-gray-300"></i> */}

                                        <img src="assets/img/Dashboard_img/creative-team.png" className="icon_size" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-6 mb-4">
                        <div className="card_design_orange  shadow h-100 py-2  cursor-pointer"
                            onClick={() => navigate(`/DirectorCitizenFeedbackGrid?screenheading=Feedback by Registered Citizen&screenname=Feedback Pending&screensubheading=Municipal Corporation&Flag=FeedbackGivenByCitizenPending&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}
                        >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h3 mb-2 fw-bold text-dark text-gray-800">{Countdata.FeedbackGivenByCitizenPendingTotalCount ? Countdata.FeedbackGivenByCitizenPendingTotalCount : '0'}</div>
                                        <div className="text-xs font-weight-bold card_heading_orange pt-2">
                                            {t('DirectorDashboard.FeedbackPending')}
                                            {/* Feedback Pending */}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {/* <i className="fas fa-download fa-2x text-orange-300"></i> */}
                                        <img src="assets/img/Dashboard_img/pending1.png" className="icon_size" alt="" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}