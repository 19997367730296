import { t } from "i18next";
import { useNavigate } from "react-router-dom"
import { loginUser } from "../../../Helper/Context/action";
import { useState } from "react";
import { useAuthDispatch, useAuthState } from "../../../Helper/Context/context";

export default function SignUpPopup(props) {
    const { setSignUpShowPopup, CitizenField } = props

    const dispatch = useAuthDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [userName, setuserName] = useState(CitizenField.UserName)
    const [password, setpassword] = useState(CitizenField.Password)
    const [loading, setloading] = useState(false)
    const [Validationerror, setValidationerror] = useState(false)
    const [Loginmessage, setLoginmessage] = useState('')

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        setLoginmessage('')
        e.preventDefault()
        // if (userName !== '' && password !== '') {
            try {
                let response = await loginUser(dispatch, { userName, password, setloading, handleRollIdWiseDashboard, handleErrorIdWiseDashboard, setLoginmessage });
                if (!response) return;
                // navigate('/dashboard');

            } catch (error) {
                console.log(error);
            }
            setValidationerror(false)
        // } else {
        //     setValidationerror(true)
            // if (userName == '') {
            //     document.getElementById('Username').classList.add('validation-error')
            // } else {
            //     document.getElementById('Username').classList.remove('validation-error')
            // }

            // if (password == '') {
            //     document.getElementById('Password').classList.add('validation-error')
            // } else {
            //     document.getElementById('Password').classList.remove('validation-error')
            // }
        // }

    }
    const handleRollIdWiseDashboard = (data) => {
        // console.log(data.m_RoleID)
        if (data.m_RoleID == 2 || data.m_RoleID == 3 || data.m_RoleID == 4) {
            navigate(`/DirectorDashboard`)
        } else if (data.m_RoleID == 5 || data.m_RoleID == 6) {
            navigate(`/MainDashboard`)
        }
        else if (data.m_RoleID == 7) {
            navigate(`/RegistrationDashboard?UserName=NewUser`)
            // navigate(`/DirectorDashboard`)
        } else if (data.m_RoleID == 1) {
            navigate(`/DesignationMaster`)
        }
    }
    const handleErrorIdWiseDashboard = (data) => {
        setLoginmessage(data)
    }
    return (
        <>
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className="mt-2 ml-2 ml-md-5">
                                <h4 className='  text-center login_text '>LOGIN</h4>
                            </div>
                            <div className='mt-1'>
                                {/* <ExportOfficeLetterSecondPopUpCOMM /> */}
                            </div>

                            <button type="button" className="btn-close mr-3 mt-2 " data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    setSignUpShowPopup(false)
                                }}
                            >
                            </button>
                        </div>
                        <div className="col-12 col-lg-10 mx-auto modal-body py-4 px-4" >
                            <div className=" justify-content-center text-center" >

                                <form>
                                    <div class="input-group mb-4">
                                        <span class="input-group-text" id="addon-wrapping"><i class="fa-solid fa-user"></i></span>
                                        {/* <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping" /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('CitizenRegistration.UserName')}
                                            name='UserName'
                                            value={userName}
                                        // onChange={onChange}
                                        />
                                    </div>

                                    <div class="input-group mb-2">
                                        <span class="input-group-text" id="addon-wrapping"><i class="fa-solid fa-lock"></i></span>
                                        {/* <input type="text" class="form-control" placeholder="Password" aria-label="Username" aria-describedby="addon-wrapping" /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('CitizenRegistration.Password')}
                                            name='Password'
                                            value={password}
                                        // onChange={onChange}
                                        />
                                    </div>

                                    <span className="text-danger" style={{fontSize:'13px'}}>
                                    Please copy & save username & password for future reference
                                    </span>

                                    <button type="button"
                                        class="btn bg-success mt-3 text-white col-12 mt-1"
                                        // onClick={() => navigate('/RegistrationDashboard?UserName=NewUser')}
                                        onClick={handleSubmit}
                                    >
                                        Copy & Login</button>

                                </form>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn bg-danger text-white" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}