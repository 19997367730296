import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess, toastUnauthorized } from "../../AlertMessage/AlertMessage";

export const GrievanceStatusGetAPI = createAsyncThunk("GrievanceStatusGet", async ({ data }) => {
    const {
        GrievanceDate,
        M_IndicatorGrievanceTypeID,
        M_StatusID,
        Flag,
        Language,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Dashboard/Get_DB_UserWiseGrievanceStatus_Select?GrievanceDate=${GrievanceDate}&M_IndicatorGrievanceTypeID=${M_IndicatorGrievanceTypeID}&M_StatusID=${M_StatusID}&Flag=${Flag}&Language=${Language}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const GrievanceStatusGetSlice = createSlice({
    name: "GrievanceStatusGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GrievanceStatusGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GrievanceStatusGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(GrievanceStatusGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const GrievanceStatusGetReducer = GrievanceStatusGetSlice.reducer

// ----------------------------Feedback Patch API------------------

export const GrievanceStatusFeedbackPatchAPI = createAsyncThunk("GrievanceStatusFeedbackPatch", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        Feedback,
        Flag,
        M_UserID,
        token,
        handleFeedbackConfirmation,
        handleIsPost,
        screenname,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
    formdata.append("Feedback", Feedback);
    formdata.append("M_UserID", M_UserID);
    
    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };
    
    fetch(`${BaseUrl}/Dashboard/Patch_DB_UserWiseGrievanceStatusFeedback_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleFeedbackConfirmation()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })

})

const GrievanceStatusFeedbackPatchSlice = createSlice({
    name: "GrievanceStatusFeedbackPatch",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GrievanceStatusFeedbackPatchAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(GrievanceStatusFeedbackPatchAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(GrievanceStatusFeedbackPatchAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const GrievanceStatusFeedbackPatchReducer = GrievanceStatusFeedbackPatchSlice.reducer


// ---------Track GET API --------------------

export const TrackDetailsGetAPI = createAsyncThunk("TrackDetailsGet", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Dashboard/Get_DB_UserWiseGrievanceTrack_Select?T_GrievanceRegistrationID=${T_GrievanceRegistrationID}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const TrackDetailsGetSlice = createSlice({
    name: "TrackDetailsGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TrackDetailsGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TrackDetailsGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(TrackDetailsGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const TrackDetailsGetReducer = TrackDetailsGetSlice.reducer

