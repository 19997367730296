import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastError, toastUnauthorized,toastSuccess } from "../../AlertMessage/AlertMessage";
import { BaseUrl } from "../../BaseURL";

export const DirectorDashboardMainCountApi = createAsyncThunk("DirectorDashboardMainCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        Flag, 
        M_UserID,
        token,
        handleGetCount,      
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Dashboard/Get_DB_WEB_StateNodalMainCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetCount(result.data)
                return result.data
            } else {
                toastError(result.message)
                return result
            }
        })
})

const DirectorDashboardMainCountDataSlice = createSlice({
    name: "DirectorDashboardMainCountData",
    initialState: {
        isLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DirectorDashboardMainCountApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DirectorDashboardMainCountApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.Data = action.payload;
        });
        builder.addCase(DirectorDashboardMainCountApi.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.Data = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DirectorDashboardMainCountGetDataReducer = DirectorDashboardMainCountDataSlice.reducer

export const ChartCountAPI = createAsyncThunk("ChartCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        Flag, 
        M_UserID,
        token,
        handleGetCount,      
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Dashboard/Get_DB_GrievanceTypeWiseMainCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetCount(result.data)
                return result.data
            } else {
                toastError(result.message)
                return result
            }
        })
})

const ChartCountDataSlice = createSlice({
    name: "ChartCountData",
    initialState: {
        isLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChartCountAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ChartCountAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.Data = action.payload;
        });
        builder.addCase(ChartCountAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.Data = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ChartCountCountGetDataReducer = ChartCountDataSlice.reducer